import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const speeds = new Note({
  note: `
  VFE F1 230
  F2 210
  F3 210
  F4 190
  F5 170
VLO Gear ext 250
  Gear ret 220
TURB 265 < 10000 > 285/.75
Wiper 250
Tire 195

Hold speeds 200 < 6000ft > 230 < 14000ft > 265
  `,
  title: "Speeds",
  userInfo,
});
