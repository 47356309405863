import { Flex, Text, useColorMode, useTheme } from "@chakra-ui/react";
import { produce } from "immer";
import { cloneDeep } from "lodash";
import React from "react";
import { NonFormikTextarea } from "../../formComponents/NonFormikTextarea";
import { TrashIconButton } from "../../IconButtons";
import { IMemoryItemRow } from "../../../typesv2";
import { MemoryItem } from "../../../classes";
import { RowStyleOptionButton } from "./RowStyleOptionButton";

interface MemoryItemRowProps {
  rowNumber: number;
  row: IMemoryItemRow;
  editingMemoryItem: MemoryItem | null;
  setEditingMemoryItem: React.Dispatch<React.SetStateAction<MemoryItem | null>>;
}

export const AddEditMemoryItemRow: React.FC<MemoryItemRowProps> = ({
  row,
  rowNumber,
  setEditingMemoryItem,
  editingMemoryItem,
}) => {
  const {
    rowStyles: { fontSize, fontWeight, textAlign },
  } = row;
  const theme = useTheme();
  const { colorMode } = useColorMode();

  if (!editingMemoryItem) {
    return null;
  }

  const handleStylePress = (key: string, value: any) => {
    const nextState = produce(editingMemoryItem, (draft) => {
      draft.data[rowNumber - 1].rowStyles = {
        ...draft.data[rowNumber - 1].rowStyles,
        [key]: value,
      };
    });

    setEditingMemoryItem(nextState);
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    column: number
  ) => {
    const nextState = produce(editingMemoryItem, (draft) => {
      draft.data[rowNumber - 1].data[column] = e.target.value;
    });
    setEditingMemoryItem(nextState);
  };

  const handleDeleteRow = () => {
    const nextState = produce(editingMemoryItem, (draft) => {
      draft.data.splice(rowNumber - 1, 1);
    });

    setEditingMemoryItem(cloneDeep(nextState));
  };

  const deleteColumn2 = () => {
    // setRowState({ ...rowState, columns: 1 });
    //todo: logic to delete second column
    const nextState = produce(editingMemoryItem, (draft) => {
      draft.data[rowNumber - 1].data = draft.data[rowNumber - 1].data.slice(
        0,
        1
      );
    });
    setEditingMemoryItem(nextState);
  };

  const addColumn2 = () => {
    const nextState = produce(editingMemoryItem, (draft) => {
      draft.data[rowNumber - 1].data = [draft.data[rowNumber - 1].data[0], ""];

      // draft.data[0].data = draft.data[];
    });
    setEditingMemoryItem(nextState);
  };

  return (
    <Flex
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="5px"
      width="100%"
      paddingLeft="5px"
      paddingBottom="20px"
      marginTop="10px"
      position="relative"
      direction="column"
      borderColor={
        colorMode === "dark"
          ? theme.appColors.dark.border
          : theme.appColors.light.border
      }
    >
      <Flex width="100%" padding={1} direction="column">
        <Flex alignItems="center" mb={2}>
          <Text flex="1" fontSize="16px" fontWeight="bold" my="7px">
            {`Row Style Options - Row ${rowNumber}`}
          </Text>
          <TrashIconButton onClick={handleDeleteRow} />
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Columns:
          </Text>

          <RowStyleOptionButton
            text="1"
            check={row.data.length === 1}
            setCheck={deleteColumn2}
          />
          <RowStyleOptionButton
            text="2"
            check={row.data.length === 2}
            setCheck={addColumn2}
          />
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Alignment:
          </Text>

          <RowStyleOptionButton
            text="Left"
            check={textAlign === "left"}
            setCheck={() => handleStylePress("textAlign", "left")}
          />
          <RowStyleOptionButton
            text="Center"
            check={textAlign === "center"}
            setCheck={() => handleStylePress("textAlign", "center")}
          />
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Font weight:
          </Text>

          <RowStyleOptionButton
            text="Normal"
            check={fontWeight === "normal"}
            setCheck={() => handleStylePress("fontWeight", "normal")}
          />
          <RowStyleOptionButton
            text="Bold"
            check={fontWeight === "bold"}
            setCheck={() => handleStylePress("fontWeight", "bold")}
            fontWeight="bold"
          />
        </Flex>
        <Flex alignItems="center">
          <Text width="120px" fontSize="12px">
            Font Size:
          </Text>

          <RowStyleOptionButton
            text="Small"
            check={fontSize === "small"}
            setCheck={() => handleStylePress("fontSize", "small")}
          />
          <RowStyleOptionButton
            text="Medium"
            check={fontSize === "medium"}
            setCheck={() => handleStylePress("fontSize", "medium")}
            fontSize="medium"
          />
          <RowStyleOptionButton
            text="Large"
            check={fontSize === "large"}
            setCheck={() => handleStylePress("fontSize", "large")}
            fontSize="large"
          />
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Text w="100px" fontSize="12px">
          Column 1:
        </Text>
        <NonFormikTextarea
          size="lg"
          // height= '50px'
          flex="1"
          marginLeft="20px"
          value={row.data[0] || ""}
          //LEFT OFF HERE passing value which is an event. check how nonFormikTextarea handles and maybe set event.target.value
          onChange={(e) => handleTextChange(e, 0)}
        />
      </Flex>
      {row.data.length > 1 && (
        <Flex alignItems="center">
          <Text w="100px" fontSize="12px">
            Column 2:
          </Text>
          <NonFormikTextarea
            size="lg"
            value={row.data[1] || ""}
            // height= '50px'
            flex="1"
            marginLeft="20px"
            onChange={(e) => handleTextChange(e, 1)}
          />
        </Flex>
      )}
    </Flex>
  );
};
