import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let hydPumps = new Check({
  title: "Hyd pumps",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let apu = new Check({
  title: "APU",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let flap = new Check({
  title: "Flap",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let antiIce = new Check({
  title: "APU",
  FFOD: false,
  action: "ON",
  fullRow: false,
});
let noseSteering = new Check({
  title: "Nose Steering",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let eicas = new Check({
  title: "Eicas and Info",
  FFOD: false,
  action: "brief",

  fullRow: false,
});
export const afterStartFlow = new Checklist({
  title: "After Start Flow",
  userInfo,
  checkIds: [
    hydPumps.id,
    apu.id,
    flap.id,
    antiIce.id,
    noseSteering.id,
    eicas.id,
  ],
  checks: {
    [hydPumps.id]: hydPumps,
    [apu.id]: apu,
    [flap.id]: flap,
    [antiIce.id]: antiIce,
    [noseSteering.id]: noseSteering,
    [eicas.id]: eicas,
  },
});
