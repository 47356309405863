import { Flex, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "../providers/AppStateProvider";
import { useData } from "../providers/DataProvider";
import { buildBreadcrumbsFromSideNavPath } from "../utils";
interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const {
    sideNavPath,
    setSideNavPath,
    setGlobalEditMode,
    setPreviewMode,
    openSideNav,
  } = useAppState();
  const { rootFolder } = useData();
  const theme = useTheme();
  const history = useHistory();
  // const { colorMode } = useColorMode();
  let breadcrumbs: any[] = [];
  if (rootFolder) {
    breadcrumbs = buildBreadcrumbsFromSideNavPath(rootFolder, sideNavPath);
  }

  const handleClick = (index: number) => {
    setGlobalEditMode(false);
    openSideNav();
    setPreviewMode(false);
    if (index !== breadcrumbs.length - 1) {
      setSideNavPath(sideNavPath.splice(0, index));
    }
    if (index <= 1) {
      history.push("/");
    }
  };

  return (
    <Flex w="100%" cursor="pointer" direction={["column", "row"]}>
      {breadcrumbs.map((breadcrumb, i) => {
        return (
          <Flex key={i + breadcrumb} color={theme.appColors.secondary.color}>
            <Text
              isTruncated
              overflow="hidden"
              maxW="100px"
              ml={[i * 4, 0]}
              // isTruncated
              // maxW={["100px", "80px", "500px"]}
              _hover={{ textDecoration: "underline", fontSize: "lg" }}
              onClick={() => handleClick(i)}
            >
              {breadcrumb}
            </Text>
            <Text mx={[0, 1, 1, 2]}>/</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
