import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { CRUDAPI } from "../../../api/crud";
import { useAppState } from "../../../providers/AppStateProvider";
import { useData } from "../../../providers/DataProvider";
import { MemoryItemRow, RowStylesMap } from "../../../types";
import { Folder } from "../../../classes";
import { MyTextField } from "../../formComponents/MyTextField";
import { SaveTo } from "../../formComponents/SaveTo";
import { validationSchemas } from "../../formComponents/validation";
import {
  AddIconButton,
  CancelIconButton,
  PreviewIconButton,
  TrashIconButton,
} from "../../IconButtons";
import { SubmitIcon } from "../../SubmitIcon";
import { AddEditMemoryItemRow } from "./AddEditMemoryItemRow";
import { AddMemoryItemRow } from "./AddMemoryItemRow";

interface AddEditMemoryItemProps {}

const defaultRowStyles: RowStylesMap = {
  fontWeight: "normal",
  fontSize: "small",
  textAlign: "left",
};

export const defaultRow: MemoryItemRow = {
  data: [""],
  rowStyles: defaultRowStyles,
};

export const AddEditMemoryItem: React.FC<AddEditMemoryItemProps> = () => {
  const {
    editingMemoryItem,
    setEditingMemoryItem,
    setActiveMemoryItem,
    sideNavPath,
    setPreviewMode,
    setGlobalEditMode,
    savePath,
    setSelectedSavePathId,
    setSavePath,
    selectedSavePathId,
  } = useAppState();
  const { rootFolder, saveRootFolderToFirebase } = useData();
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!editingMemoryItem) {
    return null;
  }

  const handleAddRow = () => {
    onOpen();
  };

  const handleDelete = () => {
    let nextState = CRUDAPI.folderCRUD.deleteChild(
      editingMemoryItem.id,
      rootFolder!,
      sideNavPath
    );

    saveRootFolderToFirebase(nextState);

    setEditingMemoryItem(null);
    setActiveMemoryItem(null);
  };

  const handlePreviewMode = () => {
    setPreviewMode(true);
  };

  return (
    <Flex direction="column" width="100%">
      <Flex>
        <Flex direction="column">
          <Formik
            initialValues={{
              title: editingMemoryItem.title,
            }}
            validationSchema={validationSchemas.editFolder}
            onSubmit={async (values) => {
              let editedItem = { ...editingMemoryItem, ...values };

              setEditingMemoryItem(editedItem);

              let nextState = {} as Folder;

              if (selectedSavePathId !== "") {
                nextState = CRUDAPI.folderCRUD.editParentFolder(
                  editedItem,
                  rootFolder,
                  savePath
                );
              } else {
                nextState = CRUDAPI.folderCRUD.editParentFolder(
                  editedItem,
                  rootFolder,
                  sideNavPath
                );
              }

              saveRootFolderToFirebase(nextState);
              setSavePath([]);
              setSelectedSavePathId("");
              setActiveMemoryItem(editedItem);
              setGlobalEditMode(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Flex
                  // alignItems="center"
                  position="fixed"
                  opacity={1}
                  zIndex={30}
                  p={2}
                  borderRadius="md"
                  backgroundColor={
                    colorMode === "dark"
                      ? theme.appColors.dark.background
                      : theme.appColors.light.background
                  }
                >
                  <Box mr={8}>
                    <TrashIconButton onClick={() => handleDelete()} />
                  </Box>
                  <Box mr={2}>
                    <CancelIconButton
                      onClick={() => setGlobalEditMode(false)}
                    />
                  </Box>
                  <Box mr={8}>
                    <SubmitIcon />
                  </Box>
                  <Box mr={2}>
                    <PreviewIconButton onClick={handlePreviewMode} />
                  </Box>
                  <Box>
                    <AddIconButton tooltip="Add Row" onClick={handleAddRow} />
                  </Box>
                </Flex>
                <Flex mb={4} mt="60px" direction="column">
                  <MyTextField
                    name="title"
                    style={{ flex: 1 }}
                    placeholder={`Memory Item title`}
                    label={`Memory Item title`}
                  />
                  <SaveTo />
                </Flex>
              </Form>
            )}
          </Formik>

          {editingMemoryItem.data.length === 0 && (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text mb={2}>Start editing this memory item!</Text>
              <Text>
                Click on the + button above to add a memory item table row
              </Text>
            </Flex>
          )}

          {editingMemoryItem.data.map((row, i) => (
            <AddEditMemoryItemRow
              key={i}
              rowNumber={i + 1}
              setEditingMemoryItem={setEditingMemoryItem}
              row={row}
              editingMemoryItem={editingMemoryItem}
            />
          ))}
        </Flex>
      </Flex>
      <AddRowModal onClose={onClose} isOpen={isOpen} />
    </Flex>
  );
};

interface AddModalProps {
  onClose: () => void;
  isOpen: boolean;
}
const AddRowModal: React.FC<AddModalProps> = ({ onClose, isOpen }) => {
  // const { editingMemoryItem, setEditingMemoryItem } = useAppState();
  return (
    <>
      <Modal
        onClose={onClose}
        size="lg"
        isOpen={isOpen}
        // portalProps={{ appendToParentPortal: false }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Memory Item Row</ModalHeader>
          <ModalCloseButton />
          <AddMemoryItemRow onClose={onClose} />
          {/* <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};
