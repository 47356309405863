import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const icing = new Note({
  note: `
    - Ice shedding on ground: 3 degrees, 60%, every 30 min
    - If cold soaked in -20 degrees for >8hrs and operating on wet or contam rwy. Cycle reversers twice.
    - Add 5kts to VREF, Vac, Vga in IC.
    - Cowl AI ON after landing in IC.
    - Temp comp MDA, FAF crossing. No need to temp comp a DH (Based on radar alt)
    - HOTDR: ACARS TXN  OTHER  HOT/YYZ
    - HOTDR – LWE: 1 value. Limiting
    - HOTDR – METAR: 2 values. Upper is limiting.
    - No T/O in FZRA/DZ, +FZRA/DZ, GR, +PL
    - Pilots may override the HOTDR when their assessment of precip is significantly different than HOTDR. Ie: reporting SN, but nothing observed or vice-versa.
    
    Cold weather procedures:
    - get WAT
    - determine max crosswind
    - get HOTDR
    
            `,
  title: "Icing",
  userInfo,
});
