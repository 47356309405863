import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let gens = new Check({
  title: "Gen switches flush",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let lights = new Check({
  title: "Nav, logo lights",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});
let cargoVent = new Check({
  title: "Cargo Vent",
  FFOD: false,
  action: "Off",
  info: "If you have live, check load sheet for setting",
  fullRow: false,
});
let emer = new Check({
  title: "Emergency lights",
  FFOD: false,
  action: "ARM",

  fullRow: false,
});
let ctp = new Check({
  title: "CTP",
  FFOD: false,
  action: "check",
  info: "source, cage, hdg to MAG, auto nav tune",
  fullRow: false,
});
let source = new Check({
  title: "Source to pf",
  FFOD: false,
  action: "as req'd",
  fullRow: false,
});
let alt = new Check({
  title: "Alt to 000ft",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let du = new Check({
  title: "DU symbols",
  FFOD: false,
  action: "set",
  fullRow: false,
  info: "Nearest Airports, Missed Appr, RNG:alt sel,  eta, speed, alt",
});

let inst = new Check({
  title: "Instument crosscheck",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: `30.00 inches, 1700ft... 057 degrees
  
  RVSM requirement: altitudes within 75ft between CA, FO altimeters and field elevation.
  Standby compass is FFOD only.
  `,
});
let eicas = new Check({
  title: "Eicas and Info",
  FFOD: false,
  action: "acomplish",
  fullRow: false,
});
let autobrakes = new Check({
  title: "Auto-brakes",
  FFOD: false,
  action: "RTO",
  fullRow: false,
});
let rudder = new Check({
  title: "Rudder test",
  FFOD: true,
  action: "acomplish",
  fullRow: false,
});

let sideStick = new Check({
  title: "Side stick test",
  FFOD: true,
  action: "acomplish",
  fullRow: false,
});

export const scan = new Checklist({
  title: "Preflight scan",
  userInfo,
  checkIds: [
    gens.id,
    lights.id,
    emer.id,
    cargoVent.id,
    ctp.id,
    source.id,
    alt.id,
    du.id,
    inst.id,
    eicas.id,
    autobrakes.id,
    rudder.id,
    sideStick.id,
  ],
  checks: {
    [gens.id]: gens,
    [lights.id]: lights,
    [emer.id]: emer,
    [cargoVent.id]: cargoVent,
    [ctp.id]: ctp,
    [source.id]: source,
    [alt.id]: alt,
    [du.id]: du,
    [inst.id]: inst,
    [eicas.id]: eicas,
    [autobrakes.id]: autobrakes,
    [rudder.id]: rudder,
    [sideStick.id]: sideStick,
  },
});
