import {
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import { Field, FieldAttributes, useField } from "formik";
import React from "react";

type MyTextareaFieldProps = {
  label: string;
  isDisabled?: boolean;
  height?: string;
} & FieldAttributes<{}>;
export const MyTextareaField: React.FC<MyTextareaFieldProps> = ({
  height,
  label,
  isDisabled = false,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);

  return (
    <Flex alignItems="center">
      <FormControl
        isDisabled={isDisabled}
        isInvalid={!!meta.error && meta.touched}
      >
        <Field as={Textarea} {...field} h={height} placeholder={label} />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
