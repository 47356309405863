import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Link,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { produce } from "immer";
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { ACProjectFolder } from "../data/AirCanadaA220/root";
// import { rootFolder } from "../typesv2";
import { useAppState } from "../providers/AppStateProvider";
import { useAuth } from "../providers/AuthProvider";
import { useData } from "../providers/DataProvider";
// import { useData } from "../providers/DataProvider";
import { useMediaState } from "../providers/MediaProvider";
import { AppButton } from "./basicComponents/AppButton";
import { Breadcrumbs } from "./Breadcrumbs";
// import { AccountIconButton } from "./IconButtons/AccountIconButton";
import {
  AccountIconButton,
  LogoutIconButton,
  MenuIconButton,
  MoonIconButton,
  SunIconButton,
} from "./IconButtons";
import { LoadingSpinner } from "./LoadingSpinner";
import { Sidebar } from "./Sidebar";

interface NavBarProps {}

export const NavBar: React.FC<NavBarProps> = () => {
  const { setSideNavPath, isSideNavOpen, toggleSideNav } = useAppState();
  const { saveRootFolderToFirebase, rootFolder } = useData();

  const theme = useTheme();
  const { colorMode } = useColorMode();
  // const [showMenuButton] = useMediaQuery("(min-width: 48em)");
  const { largerThanIPadPro, largerThanPhone } = useMediaState();

  const { loadingAuth, logout, userInfo } = useAuth();
  const history = useHistory();

  //when screen resizes menu stays open. Hopefully will never actually see this problem because users don't resize their screens.
  if (isSideNavOpen && largerThanPhone) {
    toggleSideNav();
  }

  let body = null;

  const handleRedirect = (route: string) => {
    history.push(route);
  };

  const handleAddMockData = () => {
    // saveRootFolderToFirebase(rootFolder);

    let cleanData: any = JSON.parse(JSON.stringify(ACProjectFolder));

    const nextState = produce(rootFolder, (draftState) => {
      draftState.contents[cleanData.id] = cleanData;
      if (!draftState.contentIds.includes(cleanData.id)) {
        draftState.contentIds.push(cleanData.id);
      }
    });

    saveRootFolderToFirebase(nextState);
  };

  const handleOpenMenu = () => {
    // setOpenMenu(!openMenu);
    toggleSideNav();
  };

  const handleLogout = () => {
    history.push("/");
    setSideNavPath([]);
    logout();
  };
  // data is loading
  if (loadingAuth) {
    body = (
      <Flex alignItems="center">
        <LoadingSpinner loading={loadingAuth} />
      </Flex>
    );
  } else if (!userInfo) {
    body = (
      <Flex alignItems="center">
        <AppButton size="xs" as={RouterLink} to="/login" mr={2} text="Login" />

        <AppButton
          size="xs"
          as={RouterLink}
          to="/register"
          mr={2}
          text="Register"
        />

        <Box ml={2}>
          <ThemeButton />
        </Box>
      </Flex>
    );
    // user is logged in
  } else {
    body = (
      <Flex align="center">
        {largerThanIPadPro ? (
          <Flex color={theme.appColors.secondary.color}>
            <Link as={RouterLink} to="/account" mx={2}>
              {userInfo.email}
            </Link>
          </Flex>
        ) : null}

        <Box ml={2}>
          <ThemeButton />
        </Box>

        <Box ml={2}>
          <AccountIconButton onClick={() => handleRedirect("/account")} />
        </Box>
        <Box ml={2}>
          <LogoutIconButton onClick={handleLogout} />
        </Box>
      </Flex>
    );
  }
  return (
    <Flex
      direction="column"
      zIndex={100}
      position="sticky"
      top={0}
      // p={2}

      borderBottom="1px"
      borderColor={colorMode === "dark" ? "gray.400" : "gray.800"}
      bgColor={theme.appColors.secondary.background}
      boxShadow="xl"
    >
      <Flex flex={1}>
        <Flex direction={["column", "column", "row"]} w="100%" p={2}>
          <Flex alignItems="center" w="100%">
            {largerThanPhone
              ? null
              : userInfo && (
                  <Flex mr={2}>
                    <MenuIconButton onClick={handleOpenMenu} />
                  </Flex>
                )}

            <Flex flex={["1", "1", "0"]}>
              <RouterLink to="/">
                <Flex alignItems="center">
                  {/* <Icon w={5} h={5} as={ImAirplane} /> */}
                  <Heading
                    minW="150px"
                    ml={3}
                    size="md"
                    color={theme.appColors.secondary.color}
                  >
                    MyCheckPilot
                  </Heading>
                  {/* <Icon ml={1} w={5} h={5} as={FcCheckmark} /> */}
                </Flex>
              </RouterLink>
            </Flex>

            {largerThanPhone && userInfo ? (
              <Flex flex={1} ml={[0, 0, 10]}>
                <Breadcrumbs />
              </Flex>
            ) : null}

            <Flex ml={"auto"}>{body}</Flex>
          </Flex>

          {/* {largerThanPhone && (
            <Button ml={8} onClick={() => handleAddMockData()}>
              Set mockData
            </Button>
          )} */}

          {userInfo && !largerThanPhone && (
            <Box mt={2}>
              <Breadcrumbs />
            </Box>
          )}
        </Flex>
      </Flex>

      <Collapse in={isSideNavOpen}>
        <Sidebar />
      </Collapse>
    </Flex>
  );
};

export const ThemeButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const handleClick = () => {
    toggleColorMode();
  };
  if (colorMode === "dark") {
    return <SunIconButton onClick={handleClick} />;
  } else {
    return <MoonIconButton onClick={handleClick} />;
  }
};
