import { Box, Flex, Text, useColorMode, useTheme } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Checklist } from "../classes";
import { useAppState } from "../providers/AppStateProvider";
import { useData } from "../providers/DataProvider";
import { getFolderFromSideNavPath, resetAll } from "../utils";
import { AddIconButton, BackIconButton, ResetIconButton } from "./IconButtons";
import { LoadingSpinner } from "./LoadingSpinner";
import { AddForm } from "./sidebar/AddForm";
import { Items } from "./sidebar/Items";

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const { loadingData, setRootFolder, rootFolder } = useData();

  const {
    sideNavPath,
    setSideNavPath,
    setGlobalEditMode,
    setActiveChecklist,
    setActiveMemoryItem,
    setEditingMemoryItem,
    addFolderMode,
    setAddFolderMode,
    activeChecklist,
  } = useAppState();
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const history = useHistory();

  const handleGoBack = () => {
    if (sideNavPath.length <= 2) {
      history.push("/");
    }
    setAddFolderMode(false);
    setGlobalEditMode(false);
    setActiveMemoryItem(null);
    setEditingMemoryItem(null);
    setSideNavPath(sideNavPath.splice(0, sideNavPath.length - 1));
  };

  const handleAddPress = () => {
    setAddFolderMode(!addFolderMode);
  };
  // const handleAddShared = () => {
  //   setPreviewMode(false);
  //   setItemEditMode("");
  //   history.push("/shared-folders");
  // };

  const handleResetAll = () => {
    const nextState = resetAll(rootFolder);

    // const nextState = CRUDAPI.checkCRUD.resetAllChecklists(rootFolder);

    setRootFolder(nextState);

    if (activeChecklist) {
      setActiveChecklist(
        getFolderFromSideNavPath(nextState, sideNavPath).contents[
          activeChecklist.id
        ] as Checklist
      );
    }
  };

  if (loadingData) {
    return (
      <Flex w="400px" bgColor="gray.700" h="100vh" direction="column">
        <Text>loading..</Text>
      </Flex>
    );
  }

  if (!rootFolder || Object.keys(rootFolder).length === 0) {
    return <LoadingSpinner loading={loadingData} />;
  }

  let folder = getFolderFromSideNavPath(rootFolder!, sideNavPath);

  let { title } = folder;

  return (
    <Flex
      w="400px"
      overflowX="hidden"
      minH={["auto", "auto", "100vh"]}
      h={["auto", "auto", "100%"]}
      direction="column"
      maxW="400px"
      border="1px"
      borderColor={
        colorMode === "dark"
          ? theme.appColors.dark.border
          : theme.appColors.light.border
      }
      backgroundColor={theme.appColors.tertiary.background}
      color={theme.appColors.secondary.color}
      p={1}
      // pt={3}
      pb={30}
    >
      <Flex mb={2} alignItems="center">
        {sideNavPath.length > 0 ? (
          <Box mr={1}>
            <BackIconButton onClick={handleGoBack} />
          </Box>
        ) : (
          <Box w={8}></Box>
        )}
        <Flex w="100%" m={1} alignItems="center">
          <Text fontWeight="bold" flex="1" fontSize="lg">
            {title}
          </Text>
          <Box mr={1}>
            <ResetIconButton
              tooltip="Reset All Checklists"
              onClick={handleResetAll}
            />
          </Box>
          <Box mr={1}>
            <AddIconButton
              tooltip={"Add File or Folder"}
              onClick={handleAddPress}
            />
          </Box>
        </Flex>

        {/* {(typeInfo === "checklistFolders" ||
          typeInfo === "checklistFolder") && (
          <Box mr={1}>
            <ResetIconButton
              tooltip={
                typeInfo === "checklistFolder"
                  ? "Reset Checklist Folder"
                  : "Reset All Checklists"
              }
              onClick={handleResetAll}
            />
          </Box>
        )} */}
        {/* {canAddChildren ? (
          <>
            {typeInfo === "rootFolder" && (
              <Box mr={1}>
                <CloudIconButton onClick={handleAddShared} />
              </Box>
            )}

            <Box mr={1}>
              <AddIconButton tooltip={tooltip} onClick={handleAddPress} />
            </Box>
          </>
        ) : null} */}
      </Flex>
      {addFolderMode ? <AddForm parentFolder={folder} /> : null}

      {/* {childFolders.length === 0 && childFiles.length === 0 ? (
        <Flex>
          <Text>This folder is empty</Text>
          <Text>Use buttons above to add a File or Folder</Text>
        </Flex>
      ) : ( */}

      <Items
        contents={folder.contents}
        parentFolderId={folder.id}
        contentIds={folder.contentIds}
      />
    </Flex>
  );
};
