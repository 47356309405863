import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import React from "react";
import { useAppState } from "../../providers/AppStateProvider";
import { ShareProjectForm } from "../account/ShareProjectForm";

interface ShareModalProps {}
export const ShareModal: React.FC<ShareModalProps> = () => {
  const { isShareModalOpen, onShareModalClose } = useAppState();
  return (
    <Modal onClose={onShareModalClose} size="lg" isOpen={isShareModalOpen}>
      <ModalOverlay />
      <ModalContent>
        <ShareProjectForm onClose={onShareModalClose} />
      </ModalContent>
    </Modal>
  );
};
