import { Flex } from "@chakra-ui/react";
import React from "react";
import { DownloadFolderModal } from "../components/modals/DownloadFolderModal";
import { ShareModal } from "../components/modals/ShareModal";

interface ModalsProps {}

export const Modals: React.FC<ModalsProps> = () => {
  return (
    <>
      <Flex w="100%">
        <ShareModal />
        <DownloadFolderModal />
      </Flex>
    </>
  );
};
