import React from "react";
import { Button, ButtonProps, useColorMode, useTheme } from "@chakra-ui/react";

interface IconProps extends ButtonProps {
  text: string;
  onClick?: () => void;
  to?: string;
  bgColor?: string;
}
export const AppButton: React.FC<IconProps> = ({ text, onClick, ...props }) => {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  let backgroundColor =
    colorMode === "dark"
      ? theme.appColors.dark.button
      : theme.appColors.light.button;

  if (props.bgColor) {
    backgroundColor = props.bgColor;
  }

  return (
    <Button
      {...props}
      backgroundColor={backgroundColor}
      onClick={onClick}
      color="black"
    >
      {text}
    </Button>
  );
};
