import React, { useContext, useEffect, useState } from "react";
import { UserInfo, Folder } from "../classes";
import { cleanObject } from "../utils";
import { useFirebase } from "./FirebaseProvider";
import { exampleFolder } from "../data/example/root";

interface IAuthContext {
  userInfo: UserInfo | null;

  loadingAuth: boolean;
  login: (email: string, password: string) => any;

  logout: () => void; // Promise<void>;
  register: (username: string, email: string, password: string) => any;
}

const AuthContext = React.createContext<IAuthContext>({
  userInfo: null,
  loadingAuth: false,
  login: () => {},
  logout: () => {},
  register: () => {},
});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const { auth, db } = useFirebase();

  const login = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const register = (username: string, email: string, password: string) => {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (user) => {
        await user.user?.updateProfile({ displayName: username });

        let userRef = db.collection("users").doc(user.user!.uid);

        userRef
          .collection("userInfo")
          .doc("userInfo")
          .set({ uid: user.user!.uid, email, username, pro: false });

        const newFolder = new Folder({
          title: "Root",
          userInfo: { email, username, uid: user.user!.uid },
          contentIds: [exampleFolder.id],
          contents: {
            [exampleFolder.id]: exampleFolder,
          },
        });

        userRef
          .collection("rootFolder")
          .doc("rootFolder")
          .set(cleanObject(newFolder));

        user.user?.sendEmailVerification();

        return { success: true, message: null };
      })
      .catch((err) => {
        return { success: false, message: err.message };
      });
  };

  const logout = () => {
    return auth.signOut();
  };

  const value = {
    currentUser: auth.currentUser,
    userInfo,

    loadingAuth,
    login,
    logout,
    register,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      //get user info

      if (firebaseUser) {
        let { email, uid, displayName } = cleanObject(firebaseUser);

        if (email && uid) {
          setUserInfo({ email, uid, username: displayName || null });
        }

        setLoadingAuth(false);
      } else {
        setUserInfo(null);
        setLoadingAuth(false);
      }
    });

    return unsubscribe;
  }, [auth, db]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
