import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FoldersDropdowns } from "../components/FoldersDropdowns";
import { HomeButton } from "../components/HomeButton";
import { useData } from "../providers/DataProvider";
import { findSharedFolders } from "../utils";

export const MySharedProjects = () => {
  const { rootFolder } = useData();

  if (
    !rootFolder ||
    Object.keys(rootFolder).length === 0 ||
    Object.keys(rootFolder.contents).length === 0
  ) {
    return (
      <Flex direction="column" alignItems="center">
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Share Your Projects
        </Text>
        <Text>
          You have no projects yet. Create a project and come back here.
        </Text>
        <Flex mt={2}>
          <HomeButton />
        </Flex>
      </Flex>
    );
  }

  let sharedFoldersArray = findSharedFolders(rootFolder);

  return (
    <Flex direction="column" w="100%" alignItems="center">
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Manage Shared Projects:
      </Text>
      <FoldersDropdowns folders={sharedFoldersArray} />

      <Flex mt={2}>
        <HomeButton />
      </Flex>
    </Flex>
  );
};
