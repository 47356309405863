import React from "react";
import { IconButton, Tooltip, useColorMode, useTheme } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { FiCheck } from "react-icons/fi";

export const SubmitIcon = () => {
  const { submitForm } = useFormikContext();
  const theme = useTheme();
  const { colorMode } = useColorMode();

  return (
    <Tooltip label="Submit" fontSize="sm">
      <IconButton
        mr={1}
        colorScheme={
          colorMode === "dark"
            ? theme.appColors.dark.successScheme
            : theme.appColors.light.successScheme
        }
        size="sm"
        onClick={() => submitForm()}
        aria-label="Submit"
        icon={<FiCheck />}
      />
    </Tooltip>
  );
};
