import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let fplnInit = new Check({
  title: "Start in FPLN INIT",
  FFOD: false,
  action: "continue",
  fullRow: false,
});
let flow = new Check({
  title: "Finish flow",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});
let route = new Check({
  title: "Review route = ROUTE-VIA/TO",
  FFOD: false,
  action: "review",
  fullRow: false,
});
let latLong = new Check({
  title: "Verify lat long waypoints",
  FFOD: false,
  action: "review",
  info: `

  Double check this (might be wrong):

  On flightplan, waypoints from waypoint A to waypoint B
  On FMS, waypoingt show to waypoint B
  `,
  fullRow: false,
});
let distance = new Check({
  title: "check distance",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let confirm = new Check({
  title: "Confirm/Execute",
  FFOD: false,
  action: "execute",
  fullRow: false,
});
let copy = new Check({
  title: "Copy to secondary",
  FFOD: false,
  action: "copy",

  fullRow: false,
});
let fuel = new Check({
  title: "Fuel Crosscheck",
  FFOD: false,
  action: "check",
  fullRow: false,
});

let hdg = new Check({
  title: "HDG, ALT for SID",
  FFOD: false,
  action: "acomplish",
  fullRow: false,
});
let radio = new Check({
  title: "Radio and nav aids if required",
  FFOD: false,
  action: "RTO",
  fullRow: false,
});
let rudder = new Check({
  title: "Rudder test",
  FFOD: true,
  action: "acomplish",
  fullRow: false,
});

export const fms = new Checklist({
  title: "FMS Programming",
  userInfo,
  checkIds: [
    fplnInit.id,
    flow.id,
    route.id,
    latLong.id,
    distance.id,
    confirm.id,
    copy.id,
    fuel.id,
    hdg.id,
    radio.id,
    rudder.id,
  ],
  checks: {
    [fplnInit.id]: fplnInit,
    [flow.id]: flow,
    [route.id]: route,
    [latLong.id]: latLong,
    [distance.id]: distance,
    [confirm.id]: confirm,
    [copy.id]: copy,
    [fuel.id]: fuel,
    [hdg.id]: hdg,
    [radio.id]: radio,
    [rudder.id]: rudder,
  },
});
