import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const taws = new MemoryItem({
  title: "TAWS Warning",
  userInfo,
  data: [
    {
      data: ["TAWS Warning"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Call “FIREWALL”.
- Disengage Autopilot.
- Disconnect Autothrottle.
- Advance Thrust Levers to maximum thrust
- Immediately initiate a pull up and roll wings level Note: A momentary exceedance of EGT can occur
- Ensure spoilers are retracted
- Climb at best climb angle, respecting stick shaker Note: Maintaining sidestick at the aft soft stop establishes the best climb angle
- Maintain configuration until ground contact no longer a factor
      `,
        `
- Verify all actions have been completed and call out any omissions
- Monitor vertical speed and altitude (radio altitude for terrain clearance and barometric altitude for a minimum safe altitude).
- Call out any trend toward terrain contact
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`When Ground Contact No Longer a Factor`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- When clear of the terrain, slowly decrease pitch attitude and accelerate.
- Call for configuration changes and re-engage automation as required.
      `,
        `
- Call "CLEAR OF WARNING"
- Configure as requested by PF
      `,
      ],
    },
  ],
});
