import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { RouteProps, useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { AppButton } from "../components/basicComponents/AppButton";
import { MyTextField } from "../components/formComponents/MyTextField";
import { loginSchema } from "../components/formComponents/validation";
import { Wrapper } from "../components/Wrapper";
import checkPilot from "../images/checkPilot.png";
import { useAuth } from "../providers/AuthProvider";

export const Login: React.FC<RouteProps> = ({ location }) => {
  const { login } = useAuth();
  const [error, setError] = useState(null);

  const history = useHistory();
  return (
    <Wrapper variant="small">
      <Flex
        w="100%"
        maxW="500px"
        m="auto"
        direction="column"
        justifyContent="center"
      >
        <Flex w="70%" margin="auto">
          <Image src={checkPilot} />
        </Flex>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            try {
              setError(null);
              await login(values.email, values.password);

              history.push("/");
            } catch (error) {
              if (error) {
                //@ts-ignore
                setError(error.message);
              }
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex direction="column" w="100%">
                <Flex direction="column" w="90%" m="auto" mt={4}>
                  <Box mb={4}>
                    <MyTextField
                      name="email"
                      placeholder="email"
                      label="Email"
                    />
                  </Box>
                  <MyTextField
                    name="password"
                    placeholder="password"
                    label="password"
                    type="password"
                  />
                  {/* <Box mt={4}>
                  <MyTextField
                    name="password"
                    placeholder="password"
                    label="Password"
                    type="password"
                    data-testid="loginInputPassword"
                  />
                </Box> */}
                  <Flex
                    mt={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Link size="xs" as={RouterLink} to="/reset-password">
                      Reset Password
                    </Link>
                    <AppButton size="sm" type="submit" text="Login" />

                    {/* <CustomSubmitButton
                mt={4}
                isLoading={isSubmitting}
                text="Login"
              /> */}
                  </Flex>
                  {error ? <Text>{error}</Text> : null}
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>

      <Box h="200px" />
    </Wrapper>
  );
};
