import { useMediaQuery } from "@chakra-ui/react";
import React, { createContext, useContext } from "react";

//Values are to be read:
//
interface MediaState {
  largerThanPhone: boolean;
  largerThanIPad: boolean;
  largerThanIPadPro: boolean;
}
const MediaStateContext = createContext({} as MediaState);

export const useMediaState = () => useContext(MediaStateContext);

export const MediaStateProvider = ({ children }: { children: any }) => {
  const [largerThanIPadPro, largerThanIPad, largerThanPhone] = useMediaQuery([
    "(min-width: 80em)",
    "(min-width: 64em)",
    "(min-width: 48em)",
  ]);
  const value = { largerThanIPad, largerThanPhone, largerThanIPadPro };
  return (
    <MediaStateContext.Provider value={value}>
      {children}
    </MediaStateContext.Provider>
  );
};
