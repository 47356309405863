import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { AppButton } from "../basicComponents/AppButton";
import { SaveTo } from "../formComponents/SaveTo";

interface IDownloadFolderModalProps {}

export const DownloadFolderModal: React.FC<IDownloadFolderModalProps> = () => {
  const { firestoreDownloadFolder } = useData();
  const {
    selectedSavePathId,
    downloadFolder,
    onDownloadModalClose,
    isDownloadModalOpen,
  } = useAppState();

  if (!downloadFolder) {
    return null;
  }

  const handleDownload = () => {
    firestoreDownloadFolder(downloadFolder.id, selectedSavePathId);
    onDownloadModalClose();
  };
  return (
    <Modal isOpen={isDownloadModalOpen} onClose={onDownloadModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download {downloadFolder.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SaveTo />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onDownloadModalClose}>
            Close
          </Button>
          <AppButton onClick={handleDownload} text="Download" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
