import { Check, Checklist } from "../../classes";
import { userInfo } from "./user";

let hydPumps = new Check({
  title: "This is a check!",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let apu = new Check({
  title:
    "This is another check, but with extra info. Click on the 'i' to open.",
  FFOD: false,
  info: "A handy space for extra information about this check.",
  action: "open info",

  fullRow: false,
});
let flap = new Check({
  title:
    "This check can only be seen if FFOD (First Flight Of Day) is checked.",
  FFOD: true,
  action: "check",
  fullRow: false,
});

let noseSteering = new Check({
  title: "You can insert 'full row' checks as a line break",
  FFOD: false,
  action: "check",
  fullRow: true,
});
let eicas = new Check({
  title: "Another Check",
  FFOD: false,
  action: "check",

  fullRow: false,
});
export const afterStartFlow = new Checklist({
  title: "After Start Flow",
  userInfo,
  checkIds: [hydPumps.id, apu.id, flap.id, noseSteering.id, eicas.id],
  checks: {
    [hydPumps.id]: hydPumps,
    [apu.id]: apu,
    [flap.id]: flap,
    [noseSteering.id]: noseSteering,
    [eicas.id]: eicas,
  },
});
