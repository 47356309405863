import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";
let rwy = new Check({
  title: "RWY",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let v1 = new Check({
  title: "v1 , vr, v2",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let hdg = new Check({
  title: "hdg",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let alt = new Check({
  title: "Alt",
  FFOD: false,
  action: "ON",
  fullRow: false,
});
let first = new Check({
  title: "First Waypoint",
  FFOD: false,
  action: "check",
  fullRow: false,
});

export const miniBrief = new Checklist({
  title: "Mini Brief",
  userInfo,
  checkIds: [rwy.id, v1.id, hdg.id, alt.id, first.id],
  checks: {
    [rwy.id]: rwy,
    [v1.id]: v1,
    [hdg.id]: hdg,
    [alt.id]: alt,
    [first.id]: first,
  },
});
