import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useAppState } from "../providers/AppStateProvider";
import { MemoryItemDisplay } from "../components/memoryItem/MemoryItemDisplay";
import { AddEditMemoryItem } from "../components/memoryItem/memoryItemFormComponents/AddEditMemoryItem";

interface MemoryItemProps {}

export const MemoryItemComponent: React.FC<MemoryItemProps> = () => {
  const { activeMemoryItem, editingMemoryItem, globalEditMode, previewMode } =
    useAppState();
  // const [editMode, setEditMode] = useState(false);

  if (
    !activeMemoryItem ||
    !editingMemoryItem ||
    activeMemoryItem.typeInfo !== "memoryItem"
  ) {
    return <Text>Select Memory Item</Text>;
  }

  return (
    <Flex direction="column" w="100%" p={2}>
      {globalEditMode ? (
        previewMode ? (
          <MemoryItemDisplay />
        ) : (
          <AddEditMemoryItem />
        )
      ) : (
        <MemoryItemDisplay />
      )}
    </Flex>
  );
};
