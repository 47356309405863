import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let qrh = new Check({
  title: "QRH x2",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let qrhn = new Check({
  title: "QRH normal x2",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let enrouteCharts = new Check({
  title: "Enroute Charts",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let MEL = new Check({
  title: "MEL",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let asr = new Check({
  title: "ASR forms",
  FFOD: false,
  action: "check",
  info: "Service checks is every 72 hours",
  fullRow: false,
});
let reclearance = new Check({
  title: "Reclearance forms",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let permit = new Check({
  title: "Permit to proceed forms",
  FFOD: false,
  action: "check",
  fullRow: false,
});

let odor = new Check({
  title: "Odor reporting form",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: "get info on the tag colors",
});

export const onboardLibray = new Checklist({
  title: "On-Board Library",
  userInfo,
  checkIds: [
    qrh.id,
    qrhn.id,
    enrouteCharts.id,
    MEL.id,
    asr.id,
    reclearance.id,
    permit.id,
    odor.id,
  ],
  checks: {
    [qrh.id]: qrh,
    [qrhn.id]: qrhn,
    [enrouteCharts.id]: enrouteCharts,
    [MEL.id]: MEL,
    [asr.id]: asr,
    [reclearance.id]: reclearance,
    [permit.id]: permit,
    [odor.id]: odor,
  },
});
