import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { AppButton } from "../components/basicComponents/AppButton";
import { MyTextField } from "../components/formComponents/MyTextField";
import { passwordSchema } from "../components/formComponents/validation";
import { Wrapper } from "../components/Wrapper";
import { useFirebase } from "../providers/FirebaseProvider";

export const ChangePassword: React.FC<{}> = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);

  const { auth } = useFirebase();

  return (
    <Wrapper variant="small">
      <Flex direction="column" w="100%">
        <Formik
          initialValues={{ password: "", password2: "" }}
          validationSchema={passwordSchema}
          onSubmit={async (values) => {
            await auth.currentUser
              ?.updatePassword(values.password)
              .then(() => {
                setSuccess("Successfully updated password");
              })
              .catch((err) => setError(err.message));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex w="100%" direction="column">
                <Flex w="60%" direction="column" m="auto">
                  <Heading textAlign="center" size="sm">Change Password</Heading>
                  <Box w="100%" mt={4}>
                    <MyTextField
                      name="password"
                      placeholder="password"
                      label="Password"
                      type="password"
                    />
                  </Box>
                  <Box mt={4}>
                    <MyTextField
                      name="password2"
                      placeholder="re-enter password"
                      label="Re-enter Password"
                      type="password"
                    />
                  </Box>
                  <Flex mt={2} justifyContent="flex-end">
                    <AppButton size="xs" text="Set Password" type="submit" />
                  </Flex>
                </Flex>
              </Flex>

              {error ? <Text>{error}</Text> : null}
              {success ? <Text>{success}</Text> : null}
            </Form>
          )}
        </Formik>
      </Flex>
    </Wrapper>
  );
};
