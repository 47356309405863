import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const engineFailCruise = new MemoryItem({
  title: "Engine Failure in Cruise",
  userInfo,
  data: [
    {
      data: ["Engine Fail in Cruis"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Verify or move operating engine Thrust Lever to MCT
- Disconnect Autothrottle
- Set speed MAN and Green Dot
- Initiate turn if required
- Set single engine driftdown altitude
- When speed is at Green Dot, select FLC
- Adjust speed to match Green Dot in descent
      `,
        `
- Verify MCT set
- Obtain single engine driftdown altitude
- Advise ATC
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `- Call for the appropriate checklist(s)`,
        `- Accomplish the checklist(s)`,
      ],
    },
  ],
});
