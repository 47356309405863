import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const emer = new Note({
  note: `
    ATC - advise
    Brief FA's
    Company - advise
    Overweight
    
    FA Brief (TTTBAD):
    
      Type of emargency and landing class:
        - normal
        - abnormal
        - emergency
      Touchdown surface
      Time available
      Bracing signals: 
        - 2min prior to touchdown PA: "Emergency Stations x2"
        - 30 seconds prior PA: "Brace for landing x2"
        - on ground: "Evacuate x2"
      Advise Pax
      Displace Pax
    
    Rapid deplanement: "Deplane immediately and leave your personal belongings.
    
    P-EFB fire: "Backup to the flight deck. x2"
    
    `,
  title: "Emergencies",
  userInfo,
});
