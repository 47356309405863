import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const v1cut = new MemoryItem({
  title: "V1 cut",
  userInfo,
  data: [
    {
      data: ["V1 Cut"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      data: ["PF", "PM"],
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `In the event of an Engine Fire or Failure after V1, I will continue the take-off and rotate normally at VR.`,

        `I will call "ENGINE FIRE" or "POWER LOSS" and cancel any warnings. When established in a definite climb I will call "POSITIVE RATE"`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`I will call "GEAR UP".`, `I will select the Gear Up.`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `When stabilized in a climb above 400 ft AGL at a minimum of V2, I will verify or call for the appropriate lateral mode1 and select the Autopilot ON.`,
        `I will verify or select the appropriate lateral mode.
            `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `At engine out acceleration altitude, I will:
  - select Speed MAN,
  - set speed to VFE2,
  - select ALT and,
  - call for flap retraction on schedule.
  
  When the flaps are 0, I will:
  - select FLC and,
  - set speed to green dot.`,
        `I will complete the requested actions.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `I will call for the appropriate checklist(s).`,
        `I will accomplish the checklist(s).`,
      ],
    },
  ],
});
