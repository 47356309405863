import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { AppButton } from "../components/basicComponents/AppButton";
import { MyTextField } from "../components/formComponents/MyTextField";
import { emailSchema } from "../components/formComponents/validation";
import { Wrapper } from "../components/Wrapper";
import { useFirebase } from "../providers/FirebaseProvider";

export const UpdateEmail: React.FC<{}> = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);

  const { auth } = useFirebase();

  return (
    <Wrapper variant="small">
      <Flex w="100%" direction="column">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={emailSchema}
          onSubmit={async (values) => {
            await auth.currentUser
              ?.updateEmail(values.email)
              .then(() => {
                setSuccess("Successfully updated email");
                auth.currentUser?.sendEmailVerification();
              })
              .catch((err) => setError(err.message));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex w="100%" direction="column">
                <Flex w="60%" direction="column" m="auto">
                  <Heading textAlign="center" size="sm">
                    Update Email
                  </Heading>
                  <Box mt={4}>
                    <MyTextField
                      name="email"
                      placeholder="email"
                      label="Email"
                      type="text"
                    />
                  </Box>

                  <Flex mt={2} justifyContent="flex-end">
                    <AppButton size="xs" text="Update Email" type="submit" />
                  </Flex>

                  {error ? <Text>{error}</Text> : null}
                  {success ? <Text>{success}</Text> : null}
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Wrapper>
  );
};
