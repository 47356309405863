import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const memoryItems = new Note({
  note: `

    Go around:
    
    PF: “Go around flap”
    PM:  “Flap 2(4) selected, positive rate”
    PF: “Gear up, set speed VGA”	                   
    PM Gear up, speed man, speed vga 
    
    At 400 AGL
    PF: Verify lateral mode, HDG or FMS.             
    PM: Autopilot ON	Verify/Select lateral mode.
    
    At 1000 AGL
    PF: Leave speed in MAN, Bug up, select FLC. 
    PF: Call for flap retraction.
    PM: Retract flaps.
    PF:“After go-around checklist”	                    
    
    
    
    Unreliable Airspeed
    - AP,AT,FD off
    - 12.5/Max, 5/CLB, 2/80
    - checklist
    
    Engine Fail in Cruise
    - AT disc, set max (MCT)
    - set 20,000 or safe initial alt. Ask for Alt from QRH.
    - Speed MAN, set green dot
    - Speed at green dot, select FLC
    - checklist
    
    Nose High
    - AP/AT off
    - nose down
    - try reducing thrust
    - try rolling for nose down (<60 degrees)
    - recover, thrust then  pitch
    
    Nose Low
    - AP/AT off
    - break stall
    - roll level
    - recover, pitch then thrust, drag if req’d.
    
    Stall
    - “STALL”
    - AP/AT off
    - break stall/nose down
    - roll level
    - recover, thrust then pitch
    - check spoilers
    
    TAWS
    - “FIREWALL”
    - AP/AT off
    - max thrust
    - nose up/ best angle
    - check spoilers
    - maintain config
    
    Windshear
    - TOGA
    - verify or advance thrust levers
    - follow FD guidance
    - check spoilers
    - maintain config
    
    TCAS RA
    - AP/FD off
    - follow guidance
    - PM: lights on. FD off. “Air Canada 123, TCAS RA”, “Air Canada 123 returning to 3000ft.”
    
    Volcanic ash
    - AT off
    - Thrust IDLE
    - Turn 180
    - Mask
    - checklist
    
    Lithium battery
    - “Backup to th flight deck x2”
    - checklist
    
    Turb speed: 265 < 10000 > 285/0.75
    
    Unofficial Memory Items
    
    Rudder Fail
    - 200kts
    - no more than GA flap
    
    Oceanic Diversion – All Engine
    - Drills
    - Declare emergency
    - Clearance
    - If no clearance – offset 5nm, then climb or descend 500ft
    - Lights
    - Talk 121.5
    
    Oceanic Diversion – One Engine
    - Drills and offset 5nm
    - Lights
    - Talk 121.5
    - Declare emergency and clearance with ATC
    - Descend to usable FL below 290.
    
            
            `,
  title: "Memory Items - short version",
  userInfo,
});
