import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let observerSeatMask = new Check({
  title: "Observer seat mask",
  FFOD: true,
  action: "test",
  fullRow: false,
});

let pouches = new Check({
  title: "2 Pouches",
  FFOD: true,
  action: "sealed, green tag",
  fullRow: false,
});
let pins = new Check({
  title: "3 gear pins",
  FFOD: true,
  action: "check",
  fullRow: false,
});
let handMics = new Check({
  title: "2 hand mics",
  FFOD: true,
  action: "check",
  fullRow: false,
});
let doorTest = new Check({
  title: "Door Test",
  FFOD: true,
  action: "acomplish",
  fullRow: false,
});

export const prelimFlightComptCheck = new Checklist({
  title: "Preliminary Flight Compartment Check",
  userInfo,
  checkIds: [
    observerSeatMask.id,
    pouches.id,
    pins.id,
    handMics.id,
    doorTest.id,
  ],
  checks: {
    [observerSeatMask.id]: observerSeatMask,
    [pouches.id]: pouches,
    [pins.id]: pins,
    [handMics.id]: handMics,
    [doorTest.id]: doorTest,
  },
});
