import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const cat = new Note({
  userInfo,
  note: `
    CAT II/III
<2600 T/O (CA & FO)
CA 100hrs PIC
Completed training (600RVR CA, 1200RVR FO)

CATII/III (captains only)
Min 300 PIC turbojet
Min 100 PIC at AC
Min 50/100 on type from FO/RP

Airplane and Airport approach categories are independent of each other. You can (and should) use auto-land if you have LAND 2 or 3 on a CATII approach. But if you only have APPR2, you cannot auto-land but still do the CATII approach.

Airplane: The airplane is telling us what landing it can do based on what systems are working.
LAND 3 – no DH- 0ft. AH 100ft. 3 working systems. If one fails, we still have redundancy.
LAND 2 – DH 50ft. 2 working systems. If one fails, we don’t have redundancy so the airplane relies on the pilot and we set a DH.
*NO AUTOLAND* message.
APPR 2 – DH 100ft
APPR 1 – DH 200ft

Airport: These are all based on airport equipment, lighting, markings and emergency backups.
CAT 1 – 200ft, 1/2nm, RVR 2600
CAT 2 – 100ft, 1/4nm, RVR 1200/600. Need 2 RVRs. Reduced visibility procedures.
CAT 3a – 0ft, RVR 600/600/600. Need 3 RVRs. Low visibility procedures. This is the level of service we have in Canada.
CAT  3b – 0ft. 150RVR. Europe/Asia only. Need expensive green taxi lights all the way to your gate.
CAT 3c – 0/0. Doesn’t exist yet at any airport.

Items that won’t degrade LAND 3:
-	wipers
-	windshield heat
-	nosewheel steering
-	retard function
-	Rudder Fail

    `,
  title: "CATII/CATIII",
});
