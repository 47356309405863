import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const noseLow = new MemoryItem({
  title: "Upset Recovery - Nose Low",
  userInfo,
  data: [
    {
      data: ["Upset Recovery - Nose Low"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`Recognize and confirm the situation`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Disengage Autopilot
- Disconnect Autothrottle
- Recover from stall if required
- * Roll in the shortest direction to wings level (unload and roll if bank angle is more than 90°) -
- Recover to level flight:
-- Apply nose up elevator
-- Adjust thrust and drag as required
      `,
        `
- Call out attitude, airspeed, and altitude throughout the recovery.
- Verify all required actions have been completed and call out any omissions.

      `,
      ],
    },
  ],
});
