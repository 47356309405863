import { Button, Flex, Text, useTheme } from "@chakra-ui/react";
import React from "react";
interface CheckboxProps {
  text: string;
  check: boolean;
  setCheck: any;
  fontWeight?: "normal" | "bold";
  fontSize?: "small" | "medium" | "large";
}

export const RowStyleOptionButton: React.FC<CheckboxProps> = ({
  text,
  check,
  setCheck,
  fontWeight = "normal",
  fontSize = "small",
}) => {
  const handlePress = () => {
    setCheck(!check);
  };

  const theme = useTheme();
  //   const { colorMode } = useColorMode();
  return (
    <Button
      // size="xs"
      key="asdfwe"
      mr={2}
      w={["70px", "80px", "100px"]}
      onClick={handlePress}
      // marginX="auto"
      colorScheme={check ? theme.appColors.dark.successScheme : null}
    >
      <Flex justifyContent="flex-start" alignItems="flex-start">
        <Text flex="1" fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Flex>
    </Button>
  );
};
