import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const fuel = new Note({
  note: `
  Max imbalance: 363kg
  Tank:
    Left    3770
    Right   3770
    Center  13968
    Total   21508
  
  No unsupervised fuelling when either wing tank has more than 2830.
  Fuel may leak out of vents. Left engine must be started first. 
  Man transfer must be done if center tank is less than 10670.
  `,
  title: "Fuel",
  userInfo,
});
