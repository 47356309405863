import { produce } from "immer";
import { v4 as uuid } from "uuid";
import { Check, Checklist, Folder } from "../classes";
import { allDone, getFolderFromSideNavPath, removeElFromArray } from "../utils";

export const checkCRUD = {
  create: (
    values: any,
    activeChecklist: Checklist,
    rootFolder: Folder,
    sideNavPath: string[],
    FFOD: boolean
  ) => {
    let newCheckId = uuid();
    let newCheck: Check = {
      id: newCheckId,
      title: values.title,
      action: values.action,
      checkDone: false,
      FFOD: values.FFOD,
      info: values.info,
      fullRow: values.fullRow,
    };

    let nextActiveChecklist = produce(activeChecklist, (draftState) => {
      draftState!.checks[newCheck.id] = newCheck;
      draftState.checkIds.push(newCheckId);
      draftState.checklistDone = allDone(draftState, FFOD);
    });

    let nextState = produce(rootFolder, (draftState) => {
      let parentFolder = getFolderFromSideNavPath(draftState, sideNavPath);
      parentFolder.contents[nextActiveChecklist.id] = nextActiveChecklist;
    });

    return {
      nextState,
      nextActiveChecklist,
    };
  },
  update: (
    check: Check,
    values: any,
    rootFolder: Folder,
    sideNavPath: string[],
    activeChecklist: Checklist,
    FFOD: boolean
  ) => {
    let updatedCheck: Check = { ...check, ...values };

    //check updates properly

    let nextActiveChecklist = produce(activeChecklist, (draftState) => {
      draftState!.checks[updatedCheck.id] = updatedCheck;

      let checklistComplete = allDone(draftState, FFOD);
      draftState.checklistDone = checklistComplete;
    });

    let nextState = produce(rootFolder, (draftState) => {
      let parentFolder = getFolderFromSideNavPath(draftState, sideNavPath);

      parentFolder.contents[nextActiveChecklist.id] = nextActiveChecklist;
    });

    return { nextState, nextActiveChecklist };
  },
  delete: (
    item: any,
    rootFolder: Folder,
    sideNavPath: string[],
    activeChecklist: Checklist,
    FFOD: boolean
  ) => {
    let nextActiveChecklist = produce(activeChecklist, (draftState) => {
      delete draftState!.checks[item.id];
      let newCheckIds = removeElFromArray(draftState.checkIds, item.id);
      draftState.checkIds = newCheckIds;
      draftState.checklistDone = allDone(draftState, FFOD);
    });

    let nextState = produce(rootFolder, (draftState) => {
      let checklistFolder = getFolderFromSideNavPath(draftState, sideNavPath);
      checklistFolder.contents[nextActiveChecklist.id] = nextActiveChecklist;
    });

    return { nextState, nextActiveChecklist };
  },
  setAllChecks: (
    value: boolean,
    rootFolder: Folder,
    sideNavPath: string[],
    activeChecklist: Checklist,
    FFOD: boolean
  ) => {
    //reset the checklist
    let nextActiveChecklist = produce(activeChecklist, (draftState) => {
      Object.keys(draftState.checks).forEach((checkId) => {
        let check = draftState.checks[checkId];
        check.checkDone = value;
      });
      draftState.checklistDone = allDone(draftState, FFOD);
    });

    //update the rootFolder
    let nextState = produce(rootFolder, (draftState) => {
      let parentFolder = getFolderFromSideNavPath(draftState, sideNavPath);
      parentFolder.contents[activeChecklist.id] = nextActiveChecklist;
    });

    return { nextState, nextActiveChecklist };
  },
  resetAllChecklists: (rootFolder: Folder) => {
    let folders: Folder[] = Object.keys(rootFolder.contents)
      .map((itemId) => rootFolder.contents[itemId])
      .filter((item) => item.typeInfo === "folder") as Folder[];
    let checklists = Object.keys(rootFolder.contents)
      .map((itemId) => rootFolder.contents[itemId])
      .filter((item) => item.typeInfo === "checklist") as Checklist[];

    if (folders.length > 0) {
      folders.forEach((folder) => {
        checkCRUD.resetAllChecklists(folder as Folder);
      });
    }

    let nextState = produce(rootFolder, (draft) => {
      if (!checklists.length) {
        checklists.forEach((checklist) => {
          let checkIds = Object.keys(checklist.checks);
          checkIds.forEach((checkId) => {
            (draft.contents[checklist.id] as Checklist).checks[
              checkId
            ].checkDone = false;
          });
        });
      }
    });

    // const resetChecklistFiles = (folder: Folder) => {
    //   const files = folder.contents.files;
    //   let fileKeys = Object.keys(files);
    //   if (fileKeys.length > 0) {
    //     fileKeys.forEach((fileId) => {
    //       if (files[fileId].typeInfo === "checklist") {
    //         let checklist = files[fileId] as Checklist;
    //         checklist.checklistDone = false;

    //         Object.keys(checklist.checks).forEach((checkId) => {
    //           let check = checklist.checks[checkId];
    //           check.checkDone = false;
    //         });
    //       }
    //     });
    //   }
    // };

    return nextState;
  },
};

// function resetChecks(checks: { [key: string]: Check }) {
//   let nextState = produce(checks, (draftState) => {
//     Object.keys(draftState).forEach(
//       (checkId) => (draftState[checkId].checkDone = false)
//     );
//   });
//   return nextState;
// }
