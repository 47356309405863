import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const windshear = new MemoryItem({
  title: "Windshear Warning",
  userInfo,
  data: [
    {
      data: ["Windshear Escape Maneuver"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Select TOGA
- Verify or advance Thrust Levers to maximum thrust
- Follow FD guidance
- Ensure spoilers are retracted
- Maintain configuration until clear of warning
- Follow escape guidance until windshear condition is no longer indicated
      `,
        `
- Verify max thrust is set
- Confirm spoilers retracted
- Monitor flight path, airspeed, vertical speed, and altitude. Call out significant variations.

      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`When Clear of Warning`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `- Call for configuration changes and re-engage automation as required`,
        `
- Call "CLEAR OF WARNING"
- Configure as requested by PF
      `,
      ],
    },
  ],
});
