import React from "react";
import {
  Flex,
  Icon,
  Textarea,
  TextareaProps,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
interface CustomTextInputProps extends TextareaProps {
  icon?: IconType;
}

export const NonFormikTextarea: React.FC<CustomTextInputProps> = ({
  icon,

  ...props
}) => {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  return (
    <Flex
      borderRadius="5px"
      flexDirection="row"
      width="100%"
      padding="10px"
      my="5px"
      alignItems="center"
      borderColor={
        colorMode === "dark"
          ? theme.appColors.dark.border
          : theme.appColors.light.border
      }
    >
      {icon && <Icon as={icon} size={20} mr="10px" />}

      <Textarea fontSize="18px" w="100%" {...props} />
    </Flex>
  );
};
