import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let read = new Check({
  title: "CA reads, FO crosschecks",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let trans = new Check({
  title: "CA sets transponder",
  FFOD: false,
  action: "set",

  fullRow: false,
});
let readback = new Check({
  title: "FO reads back clearance to ATC",
  FFOD: false,
  action: "ARM",
  info: "This is some info about the check",
  fullRow: false,
});

export const atcClearance = new Checklist({
  title: "ATC Clearance",
  userInfo,
  checkIds: [read.id, trans.id, readback.id],
  checks: {
    [read.id]: read,
    [trans.id]: trans,
    [readback.id]: readback,
  },
});
