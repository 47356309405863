import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const battery = new MemoryItem({
  title: "Lithium Battery",
  userInfo,
  data: [
    {
      data: ["Lithium Battery"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- “BACK UP TO THE FLIGHT DECK” x2
- Checklist.

      `,
      ],
    },
  ],
});
