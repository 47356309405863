import { Checkbox, Flex, Text } from "@chakra-ui/react";
import { Field, FieldAttributes, useField } from "formik";
import React from "react";

type MyCheckboxFieldProps = {
  label: string;
  isDisabled?: boolean;
} & FieldAttributes<{}>;

export const MyCheckbox: React.FC<MyCheckboxFieldProps> = ({
  label,
  isDisabled = false,
  ...props
}) => {
  const [field] = useField<{}>(props);

  return (
    <Flex>
      <Text mr={2}>{label}</Text>
      <Field
        isDisabled={isDisabled}
        as={Checkbox}
        isChecked={field.value}
        {...field}
      />
    </Flex>
  );
};
