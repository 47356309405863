import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const crewIncap = new MemoryItem({
  title: "Crew Incapacitation",
  userInfo,
  data: [
    {
      data: ["Crew Incapacitation  - FOM 12.5.8"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
        1.) Non-incapacitated crew member takes control
        2.) Engage autopilot
        3.) Request assistance from FA's - "In Charge FA report to the flight deck"
        4.) when A/C is in a safe state - 
            i) tilt crewmember's seat back
            ii) move seat fully aft, rudder pedals fully forward
            iii) move crew member's head back
            iv) restrain with shoulder harness
            v) when practicable, remove from seat and replace with another crew member      `,
      ],
    },
  ],
});
