import { Flex, Input, Select, Text } from "@chakra-ui/react";
import React from "react";

interface SearchProjectsFormProps {
  search: {
    field: string;
    value: string;
  };
  setSearch: React.Dispatch<
    React.SetStateAction<{
      field: string;
      value: string;
    }>
  >;
  // handleSearch: () => void;
}

export const SearchProjectsForm: React.FC<SearchProjectsFormProps> = ({
  setSearch,
  search,
  // handleSearch,
}) => {
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  return (
    <Flex
      direction={["column", "column", "column", "row"]}
      p={[2, 4, 4, 0]}
      mt={4}
      mb={8}
      alignItems={["flex-start", "flex-start", "flex-start", "center"]}
    >
      <Flex direction="row" alignItems="center">
        <Text minW="50px">Search:</Text>
        <Input
          value={search.value}
          onChange={(e) => handleChange(e)}
          w="300px"
          name="value"
        />
      </Flex>
      <Flex direction="row" alignItems="center" mt={[2, 2, 2, 0]}>
        <Text ml={[0, 0, 0, 2]} minW="50px">
          Field:
        </Text>
        <Select
          onChange={(e) => handleChange(e)}
          value={search.field}
          w="150px"
          name="field"
          // selectProps={{ placeholder: "Select option" }}
        >
          <option value="title">Title</option>
          <option value="username">Creator</option>
          {/* <option value="email">Email</option> */}
        </Select>
      </Flex>
      {/* <AppButton
        ml={[0, 0, 0, 2]}
        mt={[2, 2, 2, 0]}
        text="Search"
        onClick={handleSearch}
      /> */}
    </Flex>
  );
};
