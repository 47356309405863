import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const stall = new MemoryItem({
  title: "Stall Recovery",
  userInfo,
  data: [
    {
      data: ["Stall Recovery"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Upon Recognition of Stall Condition, Stick Shaker Activation, Stall Buffeting `,
      ],
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Call “STALL”
- Disengage Autopilot
- Disconnect Autothrottle
- Apply nose down elevator
- Roll wings level
- Apply thrust as required
- Ensure spoilers are retracted
      `,
        `
- Confirm AP and AT disconnected
- Monitor / call altitude and speed trends
- Confirm spoilers retracted
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`When Out of Stall Condition`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Return to desired flight path
- Call for configuration changes and reengage automation as required
      `,
        `
- Call “CLEAR OF WARNING”
- Configure as requested by PF
      `,
      ],
    },
  ],
});
