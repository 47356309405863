import { Flex, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useAppState } from "../../providers/AppStateProvider";
import { RowStylesMap } from "../../types";
import { BackIconButton, EditIconButton } from "../IconButtons";

interface MemoryItemDisplayProps {}

export const MemoryItemDisplay: React.FC<MemoryItemDisplayProps> = () => {
  const {
    previewMode,
    setPreviewMode,
    globalEditMode,
    setGlobalEditMode,
    activeMemoryItem,
    editingMemoryItem,
    sideNavPath,
    setSavePath,
  } = useAppState();

  const theme = useTheme();
  // const { colorMode } = useColorMode();

  let memoryItem = previewMode ? editingMemoryItem : activeMemoryItem;

  if (!memoryItem) {
    return <Text>No memory item selected...</Text>;
  }

  const handleEdit = () => {
    setPreviewMode(false);
    setSavePath(sideNavPath);
    setGlobalEditMode(true);
  };

  return (
    <Flex direction="column" w="100%">
      <Flex alignItems="center">
        {globalEditMode && previewMode ? (
          <Flex alignItems="center" mb={2}>
            <BackIconButton onClick={() => setPreviewMode(false)} />
            <Text ml={2}>Back to edit</Text>
          </Flex>
        ) : (
          <Flex mb={4} alignItems="center">
            <EditIconButton onClick={handleEdit} />
            <Text fontSize="xl" ml={2}>
              {memoryItem.title}
            </Text>
          </Flex>
        )}
      </Flex>

      {memoryItem.data.length === 0 && (
        <Text>This memory item is empty! Click Edit.</Text>
      )}

      {globalEditMode && previewMode && (
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={theme.appColors.dark.cautionScheme}
          mb={4}
          w="100%"
          textAlign="center"
        >
          * PREVIEW MODE *
        </Text>
      )}

      <Flex
        direction="column"
        // justifyContent="center"
        margin="0 auto"
        // alignSelf="center"

        w={["95%", "95%", "80%"]}
      >
        {memoryItem.data &&
          memoryItem.data.map((row: any, i: number) => {
            if (row.data.length <= 1) {
              return (
                <SingleDataRow
                  key={i}
                  data={row.data}
                  index={i}
                  rowStyles={row.rowStyles}
                />
              );
            } else {
              return (
                <DoubleDataRow
                  key={i}
                  index={i}
                  data={row.data}
                  rowStyles={row.rowStyles}
                />
              );
            }
          })}
      </Flex>
    </Flex>
  );
};

export const SingleDataRow = ({
  data,
  index,
  rowStyles,
}: {
  data: string[];
  index: number;
  rowStyles: RowStylesMap;
}) => {
  // const color = useColorModeValue("black", "white");

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      // borderColor={color}
      borderStyle="solid"
      borderWidth="1px"
      borderTopWidth={index === 0 ? 1 : 0}
      p="10px"
    >
      <Text whiteSpace="pre-wrap" {...rowStyles}>
        {data[0]}
      </Text>
    </Flex>
  );
};

export const DoubleDataRow = ({
  data,
  rowStyles,
  index,
}: {
  data: string[];
  rowStyles: RowStylesMap;
  index: number;
}) => {
  // const color = useColorModeValue("black", "white");

  return (
    <Flex
      // borderBottomColor={color}
      // borderRightColor={color}
      // borderLeftColor={color}
      borderStyle="solid"
      borderWidth={1}
      borderTopWidth={index === 0 ? 1 : 0}
      flexDirection="row"
    >
      <Flex
        //   borderRightColor={color}
        borderWidth="1px"
        borderLeftWidth={0}
        borderTopWidth={0}
        borderBottomWidth={0}
        borderStyle="solid"
        width="50%"
        padding="10px"
      >
        <Text w="100%" {...rowStyles} whiteSpace="pre-wrap">
          {data[0]}
        </Text>
      </Flex>
      <Flex style={{ width: "50%", padding: 10 }}>
        <Text w="100%" {...rowStyles} whiteSpace="pre-wrap">
          {data[1]}
        </Text>
      </Flex>
    </Flex>
  );
};
