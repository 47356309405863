import { History } from "history";
import { produce } from "immer";
import {
  Checklist,
  Folder,
  ItemType,
  MemoryItem,
  Note,
  UserInfo,
} from "../classes";
import {
  cleanObject,
  getFolderFromSideNavPath,
  removeElFromArray,
} from "../utils";

export const folderCRUD = {
  // createNewProject: (
  //   values: InitialValues,
  //   userId?: string,
  //   userEmail?: string,
  //   username?: string
  // ) => {
  //   let newProject = createProject(
  //     values.title,
  //     parseInt(values.order),
  //     userId!,
  //     userEmail!,
  //     username!
  //   );

  //   return newProject;
  // },
  addChild: (
    values: { title: string; select: string },
    projects: Folder,
    sideNavPath: string[],
    userInfo: UserInfo,
    setActiveChecklist: React.Dispatch<React.SetStateAction<Checklist | null>>,
    setActiveMemoryItem: React.Dispatch<
      React.SetStateAction<MemoryItem | null>
    >,
    setEditingMemoryItem: React.Dispatch<
      React.SetStateAction<MemoryItem | null>
    >,
    setActiveNote: React.Dispatch<React.SetStateAction<Note | null>>,
    history: History<unknown>
  ) => {
    let nextState = produce(projects, (draftState) => {
      const parentFolder = getFolderFromSideNavPath(
        draftState as Folder,
        sideNavPath
      );

      switch (values.select) {
        case "folder": {
          const newFolder = new Folder({
            title: values.title,
            userInfo,
          });

          parentFolder.contents[newFolder.id] = newFolder;
          parentFolder.contentIds = [...parentFolder.contentIds, newFolder.id];

          break;
        }
        case "checklist": {
          const newChecklist = new Checklist({
            title: values.title,
            userInfo,
          });
          parentFolder.contents[newChecklist.id] = newChecklist;
          parentFolder.contentIds = [
            ...parentFolder.contentIds,
            newChecklist.id,
          ];
          setActiveChecklist(newChecklist);
          history.push("/checklist");
          break;
        }
        case "memoryItem": {
          const newMemoryItem = new MemoryItem({
            title: values.title,
            userInfo,
          });

          parentFolder.contents[newMemoryItem.id] = newMemoryItem;
          parentFolder.contentIds = [
            ...parentFolder.contentIds,
            newMemoryItem.id,
          ];
          setActiveMemoryItem(newMemoryItem);
          setEditingMemoryItem(newMemoryItem);
          history.push("/memoryItem");
          break;
        }
        case "note": {
          const newNote = new Note({
            title: values.title,
            userInfo,
          });
          parentFolder.contents[newNote.id] = newNote;
          parentFolder.contentIds = [...parentFolder.contentIds, newNote.id];

          setActiveNote(newNote);
          history.push("/note");
          break;
        }

        default:
          break;
      }
    });

    let cleanState = cleanObject(nextState);

    return { nextState: cleanState };
  },
  editParentFolder: (
    editedItem: ItemType,
    rootFolder: Folder,
    sideNavPath: string[]
  ) => {
    let nextState = produce(rootFolder, (draftState) => {
      const parentFolder = getFolderFromSideNavPath(
        draftState as Folder,
        sideNavPath
      );

      parentFolder.contents[editedItem.id] = editedItem;
      if (!parentFolder.contentIds.includes(editedItem.id)) {
        parentFolder.contentIds.push(editedItem.id);
      }
    });

    return nextState;
  },
  deleteChild: (id: string, projects: Folder, sideNavPath: string[]) => {
    let nextState = produce(projects, (draftState) => {
      const parentFolder = getFolderFromSideNavPath(
        draftState as Folder,
        sideNavPath
      );

      delete parentFolder.contents[id];
      parentFolder.contentIds = removeElFromArray(parentFolder.contentIds, id);
    });

    return nextState;
  },
};
