import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const gen = new Note({
  note: `
  Autopilot: 400ft on departure, 80ft on approach
  Autothrottle: if using add 5kts to Vapp
  No ops on RNP 0.1, VOR,NDB,GLS
  Min turn radius 77ft 11in
  Nacelle strike at 15 degrees of bank
  Tail strike at TO:11 degrees
                  Ldg: 10 degrees
  T/O alt 351nm at 310kts
  
  Oxygen: 2crew 900 (950 in mountains)
          3crew 1200 (1300 in mountains)
  `,
  title: "General",
  userInfo,
});
