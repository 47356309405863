import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let briefInChargeFA = new Check({
  title: "Brief in-Charge FA",
  FFOD: false,
  action: "brief",
  fullRow: false,
});
let waterWaste = new Check({
  title: "Water/Waste",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let cabinDefectLogbook = new Check({
  title: "CabinDefectLogbook",
  FFOD: false,
  action: "check",
  info: "This is some info about the check",
  fullRow: false,
});
let wingCheck = new Check({
  title: "Wing Inspection",
  FFOD: false,
  action: "check if < 10C",
  info: "Check wings for ice if below 10 degrees",
  fullRow: false,
});
let logbook = new Check({
  title: "Logbook - fin, FFOD, snags, SC's",
  FFOD: false,
  action: "confirm",
  info: "Must arrive at destination within 72hours of last service check",
  fullRow: false,
});
let CSCAndEEPChecks = new Check({
  title: "CSC and EEP checks",
  FFOD: false,
  action: "acomplish",
  fullRow: false,
});

export const enteringAircraft = new Checklist({
  title: "Entering Aircraft",
  userInfo,
  checkIds: [
    briefInChargeFA.id,
    waterWaste.id,
    cabinDefectLogbook.id,
    wingCheck.id,
    logbook.id,
    CSCAndEEPChecks.id,
  ],
  checks: {
    [briefInChargeFA.id]: briefInChargeFA,
    [waterWaste.id]: waterWaste,
    [cabinDefectLogbook.id]: cabinDefectLogbook,
    [wingCheck.id]: wingCheck,
    [logbook.id]: logbook,
    [CSCAndEEPChecks.id]: CSCAndEEPChecks,
  },
});
