import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const noseHigh = new MemoryItem({
  title: "Upset Recovery - Nose High",
  userInfo,
  data: [
    {
      data: ["Upset Recovery - Nose High"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`Recognize and confirm the situation`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Disengage Autopilot
- Disconnect Autothrottle
- Apply nose down elevator
- Reduce thrust if required
- Roll (adjust bank angle) to obtain a nose down pitch rate Complete the recovery:
- When approaching the horizon, roll to wings level
- Check airspeed and adjust thrust
- Establish pitch attitude
      `,
        `
- Call out attitude, airspeed, and altitude throughout the recovery.
- Verify all required actions have been completed and call out any omissions.

      `,
      ],
    },
  ],
});
