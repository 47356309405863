import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const winds = new Note({
  note: `
  Max tailwind: 15kts. if above 10kts use the high wind procedure.

  X-winds:
    Dry/Wet                32
    Compact Snow           27
    Dry/Wet Snow <.13in    20
    Water/Slush/Ice        10
  `,
  title: "Winds",
  userInfo,
});
