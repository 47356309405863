import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let logbook = new Check({
  title: "Logbook",
  FFOD: false,
  action: "check",
  info: "Check to make sure Ramp Check Completed box is checked.",
  fullRow: false,
});
let serviceCheck = new Check({
  title: "Service checks",
  FFOD: false,
  action: "confirm",
  info: "Make sure you will land within 72 hours of a service check",

  fullRow: false,
});
let emerg = new Check({
  title: "Emergency Equipment",
  FFOD: false,
  action: "check",

  fullRow: false,
});

let walk = new Check({
  title: "Walkaround",
  FFOD: false,
  action: "Accomplish",

  fullRow: false,
});

let cockpitCheck = new Check({
  title: "Cockpit Check",
  FFOD: false,
  action: "Accomplish",
  fullRow: false,
});

let scan = new Check({
  title: "Preflight Scan",
  FFOD: false,
  action: "Accomplish",
  fullRow: false,
});

export const enrouteStation = new Checklist({
  title: "Enroute Station Stop",
  userInfo,
  checkIds: [
    logbook.id,
    serviceCheck.id,
    emerg.id,
    walk.id,
    cockpitCheck.id,
    scan.id,
  ],
  checks: {
    [logbook.id]: logbook,
    [serviceCheck.id]: serviceCheck,
    [emerg.id]: emerg,
    [walk.id]: walk,
    [cockpitCheck.id]: cockpitCheck,
    [scan.id]: scan,
  },
});
