import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const rejectedTakeoff = new MemoryItem({
  title: "Rejected Take-off",
  userInfo,
  data: [
    {
      data: ["Rejected TO"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      data: ["CA", "FO"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "medium",
        textAlign: "center",
      },
    },
    {
      data: [
        `In the event of an abnormality prior to V1, I will call “CONTINUE” or “REJECT”. If the decision is to reject, I will:
    
    - Select the thrust levers to idle
    - Observe or apply maximum braking
    - Select maximum reverse thrust consistent with conditions`,

        `I will monitor your actions and call:
    
    - “REVERSE GREEN" or “NO REVERSE LEFT (OR RIGHT)”
    - “80” and advise ATC.`,
      ],
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
    },
    {
      data: [
        `I will:
    
    - Maintain maximum braking and maximum reverse until stopped if required.
    - Set the PARK BRAKE if required
    - Call for the appropriate checklist`,
        `I will accomplish the checklist`,
      ],
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
    },
    {
      data: [
        `If an immediate evacuation is not required I will PA, "REMAIN SEATED, REMAIN SEATED", and if required, "IN-CHARGE FLIGHT ATTENDANT CALL (OR REPORT TO) THE FLIGHT DECK". If an evacuation is required I will call, "CHECKLIST, PASSENGER EVACUATION".`,
        `I will accomplish the checklist`,
      ],
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
    },
  ],
});
