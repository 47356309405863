import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ChecklistDisplay } from "../components/checklist/ChecklistDisplay";
import { EditChecklistDisplay } from "../components/checklist/EditChecklistDisplay";
import { useAppState } from "../providers/AppStateProvider";

interface ChecklistProps {}

export const ChecklistComponent: React.FC<ChecklistProps> = () => {
  const { activeChecklist, globalEditMode } = useAppState();

  if (!activeChecklist || activeChecklist.typeInfo !== "checklist") {
    return <Text>No checklist selected</Text>;
    // history.push("/");
  }

  return (
    <Flex direction="column" p={2} w="100%">
      <Flex direction="column">
        {globalEditMode ? <EditChecklistDisplay /> : <ChecklistDisplay />}
      </Flex>
    </Flex>
  );
};
