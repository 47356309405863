import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let rope = new Check({
  title: "Escape rope",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let escapeHatch = new Check({
  title: "Escape Hatch",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let fireExt = new Check({
  title: "Fire Extinguiser",
  FFOD: false,
  action: "check",
  info: "This is some info about the check",
  fullRow: false,
});
let flashlight = new Check({
  title: "Flashlights x2",
  FFOD: false,
  action: "check if < 15C",
  info: "Check wings for ice if below 15 degrees",
  fullRow: false,
});
let lifeVests = new Check({
  title: "Life vests x3",
  FFOD: false,
  action: "action example",
  info: "Service checks is every 72 hours",
  fullRow: false,
});
let axe = new Check({
  title: "Crash axe",
  FFOD: false,
  action: "acomplish",
  fullRow: false,
});
let gloves = new Check({
  title: "Gloves",
  FFOD: false,
  action: "test",
  fullRow: false,
});

let hood = new Check({
  title: "Smoke hood",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: "get info on the tag colors",
});
export const emergencyEquipment = new Checklist({
  title: "Emergency Equipment",
  userInfo,
  checkIds: [
    rope.id,
    escapeHatch.id,
    fireExt.id,
    flashlight.id,
    lifeVests.id,
    axe.id,
    gloves.id,
    hood.id,
  ],
  checks: {
    [rope.id]: rope,
    [escapeHatch.id]: escapeHatch,
    [fireExt.id]: fireExt,
    [flashlight.id]: flashlight,
    [lifeVests.id]: lifeVests,
    [axe.id]: axe,
    [gloves.id]: gloves,
    [hood.id]: hood,
  },
});
