import React, { useContext } from "react";
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDdjkYcBKpb9UqaT1jMJn00am19GOK9fgU",
  authDomain: "pilotcheck-f9b24.firebaseapp.com",
  databaseURL: "https://pilotcheck-f9b24.firebaseio.com",
  projectId: "pilotcheck-f9b24",
  storageBucket: "pilotcheck-f9b24.appspot.com",
  messagingSenderId: "594824452143",
  appId: "1:594824452143:web:593fc2e3d5f6c1bda8ceda",
  measurementId: "G-8CH589KDL9",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // firebase
  //   .firestore()
  //   .clearPersistence()
  //   .catch((error) => {
  //     console.error("Could not enable persistence:", error.code);
  //   });
} else {
  // firebase
  //   .firestore()
  //   .clearPersistence()
  //   .catch((error) => {
  //     console.error("Could not enable persistence:", error.code);
  //   });
  firebase.app(); // if already initialized, use that one
}

const auth = firebase.auth();

const db = firebase.firestore();

db.enablePersistence();

interface IFirebaseContext {
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
}

export const FirebaseContext = React.createContext<IFirebaseContext>({
  auth,
  db,
});
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider: React.FC = ({ children }) => {
  const value = {
    auth,
    db,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
