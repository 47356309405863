import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const CYYZCYEG = new Note({
  note: `
    Norther route: CYAM CYQT CYWG CYQR CYYC 

    Southern route:  CYXU CYQR CYYC
            `,
  title: "CYYZ - CYEG",
  userInfo,
});
