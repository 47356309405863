import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";
let lights = new Check({
  title: "Lights",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});
let antiIce = new Check({
  title: "Wing anti-ice test",
  FFOD: false,
  action: "test",
  info: "On below 19,000 at < 15C TAT",
  fullRow: false,
});
let belts = new Check({
  title: "Seat belts",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});

export const throughTen = new Checklist({
  title: "Through 10,000ft",
  userInfo,
  checkIds: [lights.id, antiIce.id, belts.id],
  checks: {
    [lights.id]: lights,
    [antiIce.id]: antiIce,
    [belts.id]: belts,
  },
});
