import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { produce } from "immer";
import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { CRUDAPI } from "../../api/crud";
import { Check, Folder } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { MyTextField } from "../formComponents/MyTextField";
import { SaveTo } from "../formComponents/SaveTo";
import { validationSchemas } from "../formComponents/validation";
import {
  AddIconButton,
  CancelIconButton,
  TrashIconButton,
} from "../IconButtons";
import { FFODButton } from "../IconButtons/FFODButton";
import { SubmitIcon } from "../SubmitIcon";
import { CheckForm } from "./CheckForm";
import { EditCheckComponent } from "./EditCheckComponent";

export const EditChecklistDisplay = () => {
  const {
    showAddCheckForm,
    setShowAddCheckForm,
    activeChecklist,
    setGlobalEditMode,
    sideNavPath,
    setActiveChecklist,
    savePath,
    setSelectedSavePathId,
    setSavePath,

    setShowEditCheckForm,
  } = useAppState();
  const theme = useTheme();

  const { rootFolder, saveRootFolderToFirebase } = useData();

  if (!activeChecklist || activeChecklist.typeInfo !== "checklist") {
    return <Text>No Checklist selected</Text>;
  }

  const handleAddCheck = () => {
    setShowEditCheckForm("");
    setShowAddCheckForm(!showAddCheckForm);
  };

  const handleDelete = () => {
    let nextState = CRUDAPI.folderCRUD.deleteChild(
      activeChecklist.id,
      rootFolder!,
      sideNavPath
    );
    saveRootFolderToFirebase(nextState as Folder);
    setActiveChecklist(null);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let nextChecklistState = produce(activeChecklist, (draftState) => {
      draftState.checkIds.splice(source.index, 1);
      draftState.checkIds.splice(destination.index, 0, draggableId);
    });

    // let nextState = CRUDAPI.folderCRUD.editParentFolder(
    //   nextChecklistState,
    //   rootFolder,
    //   sideNavPath
    // );

    setActiveChecklist(nextChecklistState);
  };

  const defaultNewCheck: Check = {
    title: "",
    action: "",
    checkDone: false,
    FFOD: false,
    id: uuid(),
    fullRow: false,
    info: "",
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex direction="column" p={2} w="100%">
        <Formik
          initialValues={{
            title: activeChecklist.title,
          }}
          validationSchema={validationSchemas["editFolder"]}
          onSubmit={async (values) => {
            let editedItem = { ...activeChecklist, ...values };

            let nextState = {} as Folder;
            // if (selectedSavePathId !== "") {
            nextState = CRUDAPI.folderCRUD.editParentFolder(
              editedItem,
              rootFolder!,
              savePath
            );
            // } else {
            //   nextState = CRUDAPI.folderCRUD.editParentFolder(
            //     editedItem,
            //     rootFolder!,
            //     sideNavPath
            //   );
            // }

            saveRootFolderToFirebase(nextState);
            setSavePath([]);
            setSelectedSavePathId("");
            setActiveChecklist(editedItem);
            setGlobalEditMode(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex
                alignItems="center"
                position="fixed"
                opacity={1}
                zIndex={30}
                p={2}
                borderRadius="md"
                backgroundColor={theme.appColors.secondary.background}
              >
                <Box mr={8}>
                  <TrashIconButton onClick={() => handleDelete()} />
                </Box>
                <Box mr={2}>
                  <CancelIconButton onClick={() => setGlobalEditMode(false)} />
                </Box>
                <Box mr={8}>
                  <SubmitIcon />
                </Box>
                <Box mr={2}>
                  <AddIconButton tooltip="Add Check" onClick={handleAddCheck} />
                </Box>
                <Box>
                  <FFODButton />
                </Box>

                {/* <CustomSubmitButton isLoading={isSubmitting} text="Submit" /> */}
              </Flex>
              <Flex mt="70px" mb={4} direction="column">
                <MyTextField
                  name="title"
                  style={{ flex: 1 }}
                  placeholder={`Note title`}
                  label={`Note title`}
                />
                <SaveTo />
              </Flex>
            </Form>
          )}
        </Formik>

        {showAddCheckForm && <CheckForm check={defaultNewCheck} mode="add" />}
        <Droppable droppableId={activeChecklist.id}>
          {(provided) => {
            return (
              <Flex
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="column"
              >
                <EditChecksComponent
                  checkIds={activeChecklist.checkIds}
                  checks={activeChecklist.checks}
                />
                {provided.placeholder}
              </Flex>
            );
          }}
        </Droppable>
      </Flex>
    </DragDropContext>
  );
};

interface EditChecksProps {
  checkIds: string[];
  checks: { [key: string]: Check };
}

export const EditChecksComponent: React.FC<EditChecksProps> = ({
  checks,
  checkIds,
}) => {
  // const { FFOD } = useAppState();

  const checksArray = checkIds.map((checkId) => checks[checkId]);

  return checksArray.length > 0 ? (
    <Flex p={2} w="100%" direction="column">
      {checksArray.map((check, i) => {
        return (
          <EditCheckComponent
            key={check.id + i}
            check={check}
            index={i}
            // parentLength={checksArray.length}
          />
        );
      })}
    </Flex>
  ) : (
    <Text>No checks yet! Click on the + to add a check.</Text>
  );
};
