import { Box, Flex, useColorMode, useTheme } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router";
import { CRUDAPI } from "../../api/crud";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { Folder, Note } from "../../classes";
import // getChildrenFromSideNavPath,

// getProjectFromSideNavPath,
"../../utils";
import { MyTextareaField } from "../formComponents/MyTextarea";
import { MyTextField } from "../formComponents/MyTextField";
import { SaveTo } from "../formComponents/SaveTo";
import { validationSchemas } from "../formComponents/validation";
import { CancelIconButton, TrashIconButton } from "../IconButtons";
import { SubmitIcon } from "../SubmitIcon";

interface EditNoteFormProps {
  note: Note;
}

export const EditNoteForm: React.FC<EditNoteFormProps> = ({ note }) => {
  const {
    sideNavPath,
    setActiveNote,
    setGlobalEditMode,
    selectedSavePathId,
    savePath,
    setSavePath,
    setSelectedSavePathId,
  } = useAppState();
  const { rootFolder, saveRootFolderToFirebase } = useData();
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const history = useHistory();

  // let projectId = getProjectFromSideNavPath(projects, sideNavPath).id;

  const handleDelete = () => {
    let nextState = CRUDAPI.folderCRUD.deleteChild(
      note.id,
      rootFolder,
      sideNavPath
    );
    saveRootFolderToFirebase(nextState);
    setActiveNote(null);
    history.push("/");
  };
  return (
    <>
      <Formik
        initialValues={{
          title: note.title,
          note: note.note,
        }}
        validationSchema={validationSchemas["editNoteForm"]}
        onSubmit={async (values) => {
          let editedItem = { ...note, ...values };

          let nextState = {} as Folder;

          if (selectedSavePathId !== "") {
            nextState = CRUDAPI.folderCRUD.editParentFolder(
              editedItem,
              rootFolder,
              savePath
            );
          } else {
            nextState = CRUDAPI.folderCRUD.editParentFolder(
              editedItem,
              rootFolder,
              sideNavPath
            );
          }

          saveRootFolderToFirebase(nextState);
          setSavePath([]);
          setSelectedSavePathId("");
          setActiveNote(editedItem);
          setGlobalEditMode(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex
              alignItems="center"
              position="fixed"
              opacity={1}
              zIndex={30}
              p={2}
              borderRadius="md"
              backgroundColor={
                colorMode === "dark"
                  ? theme.appColors.dark.background
                  : theme.appColors.light.background
              }
            >
              <Box mr={8}>
                <TrashIconButton onClick={() => handleDelete()} />
              </Box>
              <Box mr={2}>
                <CancelIconButton onClick={() => setGlobalEditMode(false)} />
              </Box>
              <Box>
                <SubmitIcon />
              </Box>
            </Flex>
            <Flex mt="70px" mb={4} direction="column">
              <MyTextField
                name="title"
                style={{ flex: 1 }}
                placeholder={`Note title`}
                label={`Note title`}
              />
              <SaveTo />
            </Flex>

            <MyTextareaField
              height="300px"
              name="note"
              placeholder={`Note body`}
              label={`Note body`}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
