import {
  Box,
  Flex,
  Icon,
  Text,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { CRUDAPI } from "../../api/crud";
import { Check } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { InfoIconButton } from "../IconButtons";
interface CheckProps {
  check: Check;
}

export const CheckComponent: React.FC<CheckProps> = ({ check }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const { sideNavPath, activeChecklist, setActiveChecklist, FFOD } =
    useAppState();
  const { setRootFolder, rootFolder } = useData();

  const theme = useTheme();
  const { colorMode } = useColorMode();

  const handleClickDone = (check: Check) => {
    // let updatedCheck = {...check, checkDone: !check.checkDone}
    let { nextState, nextActiveChecklist } = CRUDAPI.checkCRUD.update(
      check,
      { checkDone: !check.checkDone },
      rootFolder!,
      sideNavPath,
      activeChecklist!,
      FFOD
    );

    setRootFolder(cloneDeep(nextState));
    setActiveChecklist(nextActiveChecklist);
  };

  const handleOpenInfo = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenInfo(!openInfo);
  };

  return (
    <Flex
      direction="column"
      w="100%"
      cursor={check.fullRow ? undefined : "pointer"}
    >
      {check.fullRow ? (
        <Flex
          w="100%"
          p={1}
          my={2}
          alignItems="center"
          justifyContent="center"
          borderRadius="sm"
          backgroundColor={
            colorMode === "dark"
              ? theme.appColors.dark.background
              : theme.appColors.light.background
          }
        >
          <Text w="100%" textAlign="center">
            {check.title}
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            my={1}
            p={2}
            borderRadius="sm"
            alignItems="center"
            w="100%"
            bgColor={check.checkDone ? "green.200" : "red.200"}
            onClick={() => handleClickDone(check)}
          >
            {check.checkDone ? (
              <Icon as={MdCheckBox} size={20} color={"green"} />
            ) : (
              <Icon as={MdCheckBoxOutlineBlank} size={20} color={"red"} />
            )}
            <Text
              mx={3}
              fontWeight="bold"
              color="black"
              textAlign={check.fullRow ? "center" : undefined}
            >
              {check.title}
            </Text>

            {check.info ? (
              <Box>
                <InfoIconButton onClick={(e: any) => handleOpenInfo(e)} />
              </Box>
            ) : null}

            {check.action ? (
              <Text flex="1" textAlign="right" fontSize="sm" color="black">
                {check.action}
              </Text>
            ) : null}
          </Flex>
          {openInfo ? (
            <Flex>
              <Text>{check.info}</Text>
            </Flex>
          ) : null}
        </>
      )}
    </Flex>
  );
};
