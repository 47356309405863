import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const rapidDepres = new MemoryItem({
  title: "Rapid Depressurization",
  userInfo,
  data: [
    {
      data: ["Rapid Depressurization"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["CA", "FO"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `I will assume control with Autopilot on and call: “RAPID DEPRESSURIZATION DRILL”. I will don my oxygen mask and call: “CAPTAIN ON OXYGEN”`,

        `I will don my oxygen mask and call: “FIRST OFFICER ON OXYGEN”.
        `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `I will:
- set current speed,
- select Speed MAN,
- select Seat Belts and Landing Lights ON and,
- if appropriate make a PA “ATTENTION, FLIGHT ATTENDANTS SECURE THE CABIN, PASSENGERS TAKE YOUR SEATS”
- then call for the appropriate checklist(s).`,
        `I will:
- advise ATC,
- verify or select 7700 on the transponder and,
- accomplish the checklist(s) silently, advising of any omitted or pertinent children.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `If Emergency Descent was required,
- at 2,000 ft above level off altitude, I will set an appropriate airspeed and,
- 1,000 ft above level off altitude, I will slowly retract the Spoilers.`,
        `Once leveled off, I will PA, “IN-CHARGE FLIGHT ATTENDANT CALL (OR REPORT TO) THE FLIGHT DECK” and “USING OXYGEN” required.
        `,
      ],
    },
  ],
});
