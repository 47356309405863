import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const goAroundLE = new MemoryItem({
  title: "Low Energy Go Around",
  userInfo,
  data: [
    {
      data: ["Low Energy Go Around"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: [
        `Low energy defined as: 
      1.) Aircraft is in descent
      2.) Thrust is at or near idle
      3.) Airspeed is decreasing
      4.) Aircraft height is 50ft or less

      * A speed of VREF should be achieved prior to commencing climb
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Select TOGA - this arms AT, syncs HGD bug and sets FD to give pitch guidance`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`Thrust levers - verify they advance to GA thrust. `],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Pitch - adjust to touchdown attitude. Main wheels may touch. When thrust is set at GA thrust, and above Vref, rotate smoothly`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `NOTE: if TOGA is pressed after main wheels touch, lateral mode will be come GA and HDG or NAV will need to be selected above 400ft`,
      ],
    },
  ],
});
