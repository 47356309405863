import { Flex } from "@chakra-ui/layout";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Sidebar } from "./components/Sidebar";
import { Modals } from "./pages/Modals";
import { useMediaState } from "./providers/MediaProvider";
import { Routes } from "./routes/Routes";

export const App = () => {
  const { largerThanPhone } = useMediaState();

  return (
    <Router>
      <NavBar />

      <Flex>
        {largerThanPhone ? <Sidebar /> : null}

        <Flex w="100%">
          <Routes />
        </Flex>
      </Flex>
      <Modals />
    </Router>
  );
};
