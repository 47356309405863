import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const unreliable = new MemoryItem({
  title: "Unreliable Airspeed",
  userInfo,
  data: [
    {
      data: ["Unreliable Airspeed"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["CA", "FO"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Disengage Autopilot
- Disconnect Autothrottle
- Deselect Flight Director
- < safe altitude:12.5 degrees, Max thrust.
- > safe altitude(climb): 5 degrees, Climb thrust
- in cruise, pitch 2 degrees, 80% N1.
- Call “CHECKLIST, UNRELIABLE AIRSPEED”
      `,
        "Accomplish the checklist.",
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Re-establish pitch and power based on the Unreliable Airspeed Tables`,
        ``,
      ],
    },
  ],
});
