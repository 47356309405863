import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const oceanicAll = new MemoryItem({
  title: "Oceanic Diversion - All Engine",
  userInfo,
  data: [
    {
      data: ["Oceanic Diversion - All Engine"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Drills
- Declare emergency
- Clearance
- If no clearance – offset 5nm, then climb or descend 500ft
- Lights
- Talk 121.5
      `,
      ],
    },
  ],
});
