import { Box, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { CRUDAPI } from "../../api/crud";
import { useMyTheme } from "../../hooks/useMyTheme";
import { useAppState } from "../../providers/AppStateProvider";
import { useAuth } from "../../providers/AuthProvider";
import { useData } from "../../providers/DataProvider";
import { Folder } from "../../classes";
import { TextInput } from "../formComponents/TextInput";
import {
  CancelIconButton,
  CloudUpIconButton,
  TrashIconButton,
} from "../IconButtons";
import { SubmitIcon } from "../SubmitIcon";

const validationSchema = Yup.object({
  title: Yup.string().required(),
});

interface EditFormProps {
  item: any;
}

export const EditForm: React.FC<EditFormProps> = ({ item }) => {
  const { saveRootFolderToFirebase, rootFolder } = useData();
  const { userInfo } = useAuth();
  const { sideNavPath, setEditFolderMode, onShareModalOpen, setShareId } =
    useAppState();
  const initialValues: { title: string } = {
    title: item.title,
  };

  // const handleShareProject = (folder: Folder) => {
  //   // setActiveProject(folder);

  //   setTimeout(() => onOpen(), 500);
  // };

  const handleDelete = (item: any) => {
    let nextState = CRUDAPI.folderCRUD.deleteChild(
      item.id,
      rootFolder,
      sideNavPath
    );

    saveRootFolderToFirebase(nextState);
  };

  const handleShare = () => {
    setShareId(item.id);
    setEditFolderMode("");
    setTimeout(() => onShareModalOpen(), 1000);
  };

  const backgroundColor = useMyTheme("background");

  return (
    <Flex
      p={2}
      direction="column"
      backgroundColor={backgroundColor}
      borderRadius="md"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          let newFolder: Folder = { ...item, title: values.title };
          let nextState = CRUDAPI.folderCRUD.editParentFolder(
            newFolder,
            rootFolder!,
            sideNavPath
          );

          saveRootFolderToFirebase(nextState);
          setEditFolderMode("");
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <TextInput name="title" placeholder="title..." />

            <Flex alignItems="center" mt={2}>
              <Box flex={1}>
                <TrashIconButton onClick={() => handleDelete(item)} />
              </Box>
              {item.userInfo.email === userInfo!.email && (
                <Box flex={1}>
                  <CloudUpIconButton onClick={() => handleShare()} />
                </Box>
              )}

              <Box mr={1}>
                <CancelIconButton onClick={() => setEditFolderMode("")} />
              </Box>

              <SubmitIcon />
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
