import { Text, useDisclosure } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
// import { Checklist, MemoryItem, Note } from "../classes";
import { Checklist, MemoryItem, Note, PublicFolder } from "../classes";
// import { mockData } from "../data/AirCanadaA220/data";
import { useData } from "./DataProvider";

interface AppState {
  FFOD: boolean;
  setFFOD: React.Dispatch<React.SetStateAction<boolean>>;
  sideNavPath: string[];
  setSideNavPath: React.Dispatch<React.SetStateAction<string[]>>;

  //This is the path to save to, like sideNavPath. string[] of ids.
  savePath: string[];
  setSavePath: React.Dispatch<React.SetStateAction<string[]>>;

  //Saving to this ID. This is the parent folder to save to.
  selectedSavePathId: string;
  setSelectedSavePathId: React.Dispatch<React.SetStateAction<string>>;

  checklistState: any;
  setChecklistState: any;
  showEditCheckForm: string;
  setShowEditCheckForm: React.Dispatch<React.SetStateAction<string>>;
  showAddCheckForm: boolean;
  setShowAddCheckForm: React.Dispatch<React.SetStateAction<boolean>>;

  parentFolderLength: number;
  setParentFolderLength: React.Dispatch<React.SetStateAction<number>>;
  addFolderMode: boolean;
  setAddFolderMode: React.Dispatch<React.SetStateAction<boolean>>;
  editFolderMode: string;
  setEditFolderMode: React.Dispatch<React.SetStateAction<string>>;
  completedChecklists: string[];
  setCompletedChecklists: React.Dispatch<React.SetStateAction<string[]>>;

  editingMemoryItem: MemoryItem | null;
  setEditingMemoryItem: React.Dispatch<React.SetStateAction<MemoryItem | null>>;
  activeMemoryItem: MemoryItem | null;
  setActiveMemoryItem: React.Dispatch<React.SetStateAction<MemoryItem | null>>;
  activeChecklist: Checklist | null;
  setActiveChecklist: React.Dispatch<React.SetStateAction<Checklist | null>>;
  activeNote: Note | null;
  setActiveNote: React.Dispatch<React.SetStateAction<Note | null>>;
  globalEditMode: boolean;
  setGlobalEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  previewMode: boolean;
  setPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
  sortBy: SortBy;
  setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
  desc: "asc" | "desc";
  setDesc: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
  isSideNavOpen: boolean;
  toggleSideNav: () => void;
  openSideNav: () => void;
  closeSideNav: () => void;
  isShareModalOpen: boolean;
  onShareModalOpen: () => void;
  onShareModalClose: () => void;
  isDownloadModalOpen: boolean;
  onDownloadModalOpen: () => void;
  onDownloadModalClose: () => void;
  shareId: string;
  setShareId: React.Dispatch<React.SetStateAction<string>>;

  downloadFolder: PublicFolder | null;
  setDownloadFolder: React.Dispatch<React.SetStateAction<PublicFolder | null>>;
}
const AppStateContext = createContext({} as AppState);

export const useAppState = () => useContext(AppStateContext);

export type SortBy =
  | "title"
  | "updatedAt"
  | "version"
  | "ownerUsername"
  | "downloads";

export type SortDirection = "asc" | "desc";

export const AppStateProvider = ({ children }: { children: any }) => {
  const { loadingData, rootFolder } = useData();
  const [sideNavPath, setSideNavPath] = useState<string[]>([]);
  const [savePath, setSavePath] = useState<string[]>([]);
  const [FFOD, setFFOD] = useState(true);
  const [addFolderMode, setAddFolderMode] = useState(false);
  const [editFolderMode, setEditFolderMode] = useState("");
  const [checklistState, setChecklistState] = useState();
  const [showAddCheckForm, setShowAddCheckForm] = useState(false);
  const [showEditCheckForm, setShowEditCheckForm] = useState("");
  // const [showProjectForm, setShowProjectForm] = useState("");
  // const [manageUsersForm, setManageUsersForm] = useState("");
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [parentFolderLength, setParentFolderLength] = useState(0);
  const [completedChecklists, setCompletedChecklists] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<SortBy>("title");
  const [desc, setDesc] = useState<"asc" | "desc">("asc");
  const [downloadFolder, setDownloadFolder] = useState<PublicFolder | null>(
    null
  );

  const [selectedSavePathId, setSelectedSavePathId] = useState(rootFolder.id);
  const {
    isOpen: isSideNavOpen,
    onToggle: toggleSideNav,
    onOpen: openSideNav,
    onClose: closeSideNav,
  } = useDisclosure();

  useEffect(() => {
    setSelectedSavePathId(rootFolder.id);
  }, [rootFolder]);

  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const {
    isOpen: isDownloadModalOpen,
    onOpen: onDownloadModalOpen,
    onClose: onDownloadModalClose,
  } = useDisclosure();

  const [shareId, setShareId] = useState("");

  const [editingMemoryItem, setEditingMemoryItem] = useState<MemoryItem | null>(
    null
  );
  const [activeMemoryItem, setActiveMemoryItem] = useState<MemoryItem | null>(
    null
  );

  const [activeChecklist, setActiveChecklist] = useState<Checklist | null>(
    null
  );
  const [activeNote, setActiveNote] = useState<Note | null>(null);

  if (loadingData) {
    return <Text>Loading Sidebar</Text>;
  }
  const value = {
    FFOD,
    setFFOD,
    sideNavPath,
    setSideNavPath,
    editingMemoryItem,
    setEditingMemoryItem,
    activeMemoryItem,
    setActiveMemoryItem,
    checklistState,
    setChecklistState,
    activeChecklist,
    setActiveChecklist,
    activeNote,
    setActiveNote,
    showAddCheckForm,
    setShowAddCheckForm,
    // showProjectForm,
    // setShowProjectForm,
    showEditCheckForm,
    setShowEditCheckForm,
    globalEditMode,
    setGlobalEditMode,
    previewMode,
    setPreviewMode,
    parentFolderLength,
    setParentFolderLength,
    // manageUsersForm,
    // setManageUsersForm,
    sortBy,
    setSortBy,
    desc,
    setDesc,
    isSideNavOpen,
    toggleSideNav,
    openSideNav,
    closeSideNav,
    addFolderMode,
    setAddFolderMode,
    editFolderMode,
    setEditFolderMode,
    completedChecklists,
    setCompletedChecklists,
    isShareModalOpen,
    onShareModalClose,
    onShareModalOpen,
    isDownloadModalOpen,
    onDownloadModalClose,
    onDownloadModalOpen,
    shareId,
    setShareId,
    savePath,
    setSavePath,
    selectedSavePathId,
    setSelectedSavePathId,
    downloadFolder,
    setDownloadFolder,
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
