import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let review = new Check({
  title: "Review: route, arrival, performace, minimums",
  action: "check",
  fullRow: false,
});

let status = new Check({
  title: "Aircraft Status",
  info: "MCNEIA - MELs, Constuction Codes, NOTAMS, Eicas, Info, ATIS",
  FFOD: false,
  fullRow: false,
});
let start = new Check({
  title: "Brief STAR",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let plate = new Check({
  title: "Brief Approach",
  FFOD: false,
  action: "ON",
  fullRow: false,
});
let taxi = new Check({
  title: "Brief taxi chart",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let auto = new Check({
  title: "Automation",
  FFOD: false,
  action: "brief",
  info: "Automation: flap, AP, landing, auto-brakes, reverse, XFER of controls",

  fullRow: false,
});
let fuel = new Check({
  title: "Diversion Fuel",
  FFOD: false,
  action: "on board",
  fullRow: false,
});

let threats = new Check({
  title: "Threats",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let Iwill = new Check({
  title: "Go-around I will",
  FFOD: false,
  action: "brief",
  fullRow: false,
});

export const approachBrief = new Checklist({
  title: "Approach Briefing",
  checkIds: [
    review.id,
    status.id,
    start.id,
    plate.id,
    taxi.id,
    auto.id,
    fuel.id,
    threats.id,
    Iwill.id,
  ],
  userInfo,
  checks: {
    [review.id]: review,
    [status.id]: status,
    [start.id]: start,
    [plate.id]: plate,
    [taxi.id]: taxi,
    [auto.id]: auto,
    [fuel.id]: fuel,
    [threats.id]: threats,
    [Iwill.id]: Iwill,
  },
});
