import { Box, Flex, Text, useColorMode, useTheme } from "@chakra-ui/react";
import { produce } from "immer";
import React, { useState } from "react";
import { useAppState } from "../../../providers/AppStateProvider";
import { MemoryItemRow } from "../../../types";
import { NonFormikTextarea } from "../../formComponents/NonFormikTextarea";
import { CancelIconButton, CheckMarkIconButton } from "../../IconButtons";
import { RowStyleOptionButton } from "./RowStyleOptionButton";

interface MemoryItemRowProps {
  onClose: () => void;
}

// const defaultRowStyles: RowStylesMap = {
//   fontWeight: "normal",
//   fontSize: "small",
//   textAlign: "left",
// };

// const defaultRow: MemoryItemRow = {
//   data: [""],
//   rowStyles: defaultRowStyles,
// };

export const AddMemoryItemRow: React.FC<MemoryItemRowProps> = ({ onClose }) => {
  const [row, setRow] = useState<MemoryItemRow>({
    data: [""],
    rowStyles: { fontSize: "small", fontWeight: "normal", textAlign: "left" },
  });
  const {
    rowStyles: { fontSize, fontWeight, textAlign },
  } = row;
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const { editingMemoryItem, setEditingMemoryItem } = useAppState();
  //   const { projects, saveProjects } = useData();

  const handleStylePress = (key: string, value: any) => {
    row.rowStyles = { ...row.rowStyles, [key]: value };
    setRow({ ...row });
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    column: number
  ) => {
    row.data[column] = e.target.value;

    setRow({ ...row });
  };

  const deleteColumn2 = () => {
    row.data = row.data.slice(-1);
    setRow({ ...row });
  };

  const addColumn2 = () => {
    row.data.push("");
    setRow({ ...row });
  };

  const handleSaveRow = () => {
    let nextState = produce(editingMemoryItem!, (draftState) => {
      draftState.data.push(row);
    });

    setEditingMemoryItem(nextState);
    let resetRow: MemoryItemRow = {
      data: [""],
      rowStyles: {
        fontWeight: "normal",
        fontSize: "small",
        textAlign: "left",
      },
    };

    setRow(resetRow);

    onClose();
    // setTimeout(() => {
    // }, 1000);

    //
    // console.log("AddMemoryItemRow.tsx 86 {...defaultRow}:", { ...defaultRow });
  };

  return (
    <Flex
      //   borderStyle="solid"
      //   borderWidth="1px"
      //   borderRadius="5px"
      width="100%"
      paddingLeft="5px"
      paddingBottom="20px"
      position="relative"
      direction="column"
      borderColor={
        colorMode === "dark"
          ? theme.appColors.dark.border
          : theme.appColors.light.border
      }
    >
      <Flex width="100%" direction="column" p={4}>
        {/* <Flex alignItems="center" mb={2}>
          <Text flex="1" fontSize="16px" fontWeight="bold" my="7px">
            {`Row Style Options - Row ${rowNumber}`}
          </Text>
        </Flex> */}
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Columns:
          </Text>

          <RowStyleOptionButton
            text="1"
            check={row.data.length === 1}
            setCheck={deleteColumn2}
          />
          <RowStyleOptionButton
            text="2"
            check={row.data.length === 2}
            setCheck={addColumn2}
          />
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Alignment:
          </Text>

          <RowStyleOptionButton
            text="Left"
            check={textAlign === "left"}
            setCheck={() => handleStylePress("textAlign", "left")}
          />
          <RowStyleOptionButton
            text="Center"
            check={textAlign === "center"}
            setCheck={() => handleStylePress("textAlign", "center")}
          />
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Text width="120px" fontSize="12px">
            Font weight:
          </Text>

          <RowStyleOptionButton
            text="Normal"
            check={fontWeight === "normal"}
            setCheck={() => handleStylePress("fontWeight", "normal")}
          />
          <RowStyleOptionButton
            text="Bold"
            check={fontWeight === "bold"}
            setCheck={() => handleStylePress("fontWeight", "bold")}
            fontWeight="bold"
          />
        </Flex>
        <Flex alignItems="center">
          <Text width="120px" fontSize="12px">
            Font Size:
          </Text>

          <RowStyleOptionButton
            text="Small"
            check={fontSize === "small"}
            setCheck={() => handleStylePress("fontSize", "small")}
          />
          <RowStyleOptionButton
            text="Medium"
            check={fontSize === "medium"}
            setCheck={() => handleStylePress("fontSize", "medium")}
            fontSize="medium"
          />
          <RowStyleOptionButton
            text="Large"
            check={fontSize === "large"}
            setCheck={() => handleStylePress("fontSize", "large")}
            fontSize="large"
          />
        </Flex>
      </Flex>
      <Flex alignItems="center" px={4}>
        <Text w="100px" fontSize="12px">
          Column 1:
        </Text>
        <NonFormikTextarea
          size="lg"
          // height= '50px'
          flex="1"
          marginLeft="20px"
          value={row.data[0] || ""}
          //LEFT OFF HERE passing value which is an event. check how nonFormikTextarea handles and maybe set event.target.value
          onChange={(e) => handleTextChange(e, 0)}
        />
      </Flex>
      {row.data.length > 1 && (
        <Flex alignItems="center" px={4}>
          <Text w="100px" fontSize="12px">
            Column 2:
          </Text>
          <NonFormikTextarea
            size="lg"
            value={row.data[1] || ""}
            // height= '50px'
            flex="1"
            marginLeft="20px"
            onChange={(e) => handleTextChange(e, 1)}
          />
        </Flex>
      )}
      <Flex justifyContent="flex-end">
        <Box mr={2}>
          <CancelIconButton onClick={onClose} />
        </Box>
        <Box mr={4}>
          <CheckMarkIconButton onClick={handleSaveRow} />
        </Box>
      </Flex>
    </Flex>
  );
};
