import { Flex, Heading, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { RouteProps, useHistory } from "react-router";
import { AppButton } from "../components/basicComponents/AppButton";
import { MyTextField } from "../components/formComponents/MyTextField";
import { emailSchema } from "../components/formComponents/validation";
import { Wrapper } from "../components/Wrapper";
import { useFirebase } from "../providers/FirebaseProvider";

export const ResetPassword: React.FC<RouteProps> = ({ location }) => {
  const { auth } = useFirebase();

  const [error, setError] = useState(null);
  const [emailMsg, setEmailMsg] = useState<string | null>(null);

  const history = useHistory();
  return (
    <Wrapper variant="small">
      <Flex direction="column" w="100%">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={emailSchema}
          onSubmit={async (values) => {
            try {
              setError(null);
              await auth.sendPasswordResetEmail(values.email).then(() => {
                setEmailMsg(`Email sent to ${values.email}`);
              });

              history.push("/");
            } catch (error) {
              if (error) {
                //@ts-ignore
                setError(error.message);
              }
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex w="100%" direction="column">
                <Flex w="60%" direction="column" m="auto">
                  <Heading mb={2} textAlign="center" size="sm">
                    Reset Password
                  </Heading>
                  <MyTextField
                    name="email"
                    placeholder="email"
                    label="Email"
                    data-testid="loginInputEmail"
                  />

                  <Flex mt={2} justifyContent="flex-end">
                    <AppButton size="xs" text="Reset Password" type="submit" />
                  </Flex>
                  {error ? <Text>{error}</Text> : null}
                  {emailMsg ? <Text>{emailMsg}</Text> : null}
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Wrapper>
  );
};
