import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { EditIconButton } from "../components/IconButtons";
import { EditNoteForm } from "../components/note/EditNoteForm";
import { useAppState } from "../providers/AppStateProvider";

interface NoteProps {}

export const NoteComponent: React.FC<NoteProps> = () => {
  // const { rootFolder } = useData();
  const {
    activeNote,
    globalEditMode,
    setGlobalEditMode,
    sideNavPath,
    setSavePath,
  } = useAppState();

  // const parent = getFolderFromSideNavPath(rootFolder!, sideNavPath);

  if (!activeNote || activeNote.typeInfo !== "note") {
    return <Text>Click on a Note</Text>;
  }

  const handleEdit = () => {
    setSavePath(sideNavPath);
    setGlobalEditMode(true);
  };
  return (
    <Flex direction="column" p={2} w="100%">
      {globalEditMode ? (
        <EditNoteForm note={activeNote} />
      ) : (
        <>
          <Flex alignItems="center" w="100%" mb={2}>
            <EditIconButton onClick={handleEdit} />
            <Heading ml={2}>{activeNote.title}</Heading>
          </Flex>
          <Text whiteSpace="pre-wrap">{activeNote.note}</Text>
        </>
      )}
    </Flex>
  );
};
