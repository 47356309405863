import React from "react";

// import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
interface loadingSpinnerProps {
  loading: boolean;
}

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

export const LoadingSpinner: React.FC<loadingSpinnerProps> = ({ loading }) => {
  return <RingLoader color={"white"} loading={loading} size={25} />;
};
