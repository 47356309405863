import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const tcasRa = new MemoryItem({
  title: "TCAS RA",
  userInfo,
  data: [
    {
      data: ["TCAS RA"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Disengage Autopilot
- Select Flight Director Off
- flight path vector to RA fly-to zone
      `,
        `
- Turn on exterior lights
- Select Flight Director Off
- Advise ATC “AIR CANADA ___ TCAS RA”
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`When clear of conflict`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Promptly and smoothly return aircraft to previously assigned flight path. If returning to an assigned altitude or FL, use a vertical rate no greater than 500 fpm
- Call for configuration or automation changes as required
      `,
        `
- Advise ATC “AIR CANADA ___ RETURNING TO ___(ASSIGNED ALTITUDE)”

- Configure as requested by PF
      `,
      ],
    },
  ],
});
