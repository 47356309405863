import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let fuel = new Check({
  title: "Fuel checks. TOC. Fuel used.",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let headings = new Check({
  title: "Headings and Altitudes",
  FFOD: false,
  action: "check",
  info: "get info about when this is required.",
  fullRow: false,
});
let geoScan = new Check({
  title: "Geographic Scan",
  FFOD: false,
  action: "accomplish",
  fullRow: false,
});
let DFM = new Check({
  title: "DFM Info",
  FFOD: false,
  action: "note",
  fullRow: false,
  info: "ETA local time, Arrival gate, SOB, Min fuel, live?, dgs?",
});
let wx = new Check({
  title: "Weather along route",
  FFOD: false,
  action: "check",
  fullRow: false,
});

export const cruiseCheck = new Checklist({
  title: "Cruise Check",
  userInfo,
  checkIds: [fuel.id, headings.id, geoScan.id, DFM.id, wx.id],
  checks: {
    [fuel.id]: fuel,
    [headings.id]: headings,
    [geoScan.id]: geoScan,
    [DFM.id]: DFM,
    [wx.id]: wx,
  },
});
