import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const oceanicSingle = new MemoryItem({
  title: "Oceanic Diversion - Single Engine",
  userInfo,
  data: [
    {
      data: ["Oceanic Diversion - Single Engine"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Drills and offset 5nm
- Lights
- Talk 121.5
- Declare emergency and clearance with ATC
- Descend to usable FL below 290.
      `,
      ],
    },
  ],
});
