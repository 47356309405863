import React from "react";
import {
  FormControl,
  FormErrorMessage,
  Input,
  Flex,
  useTheme,
  useColorMode,
} from "@chakra-ui/react";
import { Field, FieldAttributes, useField } from "formik";

type MyTextFieldProps = {
  label: string;
  isDisabled?: boolean;
  style?: { [key: string]: any };
} & FieldAttributes<{}>;
export const MyTextField: React.FC<MyTextFieldProps> = ({
  label,
  isDisabled = false,
  style,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const theme = useTheme();
  const { colorMode } = useColorMode();

  return (
    //@ts-ignore
    <Flex {...style}>
      <FormControl
        isInvalid={!!meta.error && meta.touched}
        isDisabled={isDisabled}
      >
        <Field
          borderColor={
            colorMode === "dark"
              ? theme.appColors.dark.border
              : theme.appColors.light.border
          }
          placeholder={label}
          {...field}
          type={props.type}
          as={Input}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
