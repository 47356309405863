import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let chrono = new Check({
  title: "Chrono",
  FFOD: false,
  action: "Start timer",
  fullRow: false,
  info: "3 min idle engine limit before shut down",
});
let lights = new Check({
  title: "Lights - taxi light remains on",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});
let dg = new Check({
  title: "Anti-Ice",
  FFOD: false,
  action: "as reqiured",
  info: "Nacelle ice if in icing conditions",
  fullRow: false,
});
let flaps = new Check({
  title: "Flaps",
  FFOD: false,
  action: "UP",
  fullRow: false,
});
let trim = new Check({
  title: "Trim",
  FFOD: false,
  action: "to 5.0",
  fullRow: false,
});
let apu = new Check({
  title: "APU",
  FFOD: false,
  action: "ON",

  fullRow: false,
});
let single = new Check({
  title: "Single engine taxi",
  FFOD: false,
  action: "on board",
  fullRow: false,
  info: "left engine: PTU ON.  right engine: STBY HYD PUMP ON",
});

export const afterLanding = new Checklist({
  title: "After Landing",
  userInfo,
  checkIds: [chrono.id, lights.id, dg.id, flaps.id, trim.id, apu.id, single.id],
  checks: {
    [chrono.id]: chrono,
    [lights.id]: lights,
    [dg.id]: dg,
    [flaps.id]: flaps,
    [trim.id]: trim,
    [apu.id]: apu,
    [single.id]: single,
  },
});
