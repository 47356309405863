import {
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle } from "react-icons/md";
import { LoadingSpinner } from "../components/LoadingSpinner";
import checklist from "../images/checklist.png";
import checkPilot from "../images/checkPilot.png";
import metacheckPilot from "../images/metacheckPilot.png";
import memoryItem from "../images/memoryItem.png";
import notes from "../images/notes.png";
import shareYourProjects from "../images/shareYourProjects.png";
import subscribe from "../images/subscribe.png";
import { useAuth } from "../providers/AuthProvider";
import { useMediaState } from "../providers/MediaProvider";

interface PublicHomeProps {}

export const PublicHome: React.FC<PublicHomeProps> = () => {
  const theme = useTheme();
  const { loadingAuth } = useAuth();

  // const { largerThanPhone } = useMediaState();
  return (
    <Flex direction="column" w="100%">
      <LoadingSpinner loading={loadingAuth} />
      <Flex w="100%" boxShadow="dark-lg" zIndex="20" overflow="hidden">
        <Flex
          h="70vh"
          alignItems="center"
          w="80%"
          margin="40px auto 0 auto"
          direction={["column", "column", "row"]}
        >
          <Image src={checkPilot} w={["100%", "75%", "60%", "40%"]} />
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Heading size="2xl" mb={4} textAlign="center">
              Welcome to MyCheckPilot
            </Heading>
            <Text ml={2} siz="xl" textAlign="center">
              An opinionated note-making application tailored for pilots.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex boxShadow="dark-lg" zIndex="10">
        <Feature heading="Create Checklists." image={checklist} secondary>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Organise your checklists inside folders.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Clearly show when checklists have been completed.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Add additional information for each check.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              "First flight of day" toggle button
            </ListItem>
          </List>
        </Feature>
      </Flex>

      <Flex boxShadow="dark-lg" zIndex="9">
        <Feature heading="Make Memory Items." right image={memoryItem}>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Quick and easy access to keep those memory items fresh in your
              head.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Familiar format. Just like your SOP's
            </ListItem>
          </List>
        </Feature>
      </Flex>

      <Flex boxShadow="dark-lg" zIndex="8">
        <Feature heading="Organise your notes." image={notes} secondary>
          <List color={theme.appColors.secondary.color} spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Organise your notes inside folders.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Quick and easy access to your notes for when you need them.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Copy and paste functionality. Especially useful for pasting into
              your Jeppeson flight plans.
            </ListItem>
          </List>
        </Feature>
      </Flex>
      <Flex boxShadow="dark-lg" zIndex="7">
        <Feature heading="Share your projects." right image={shareYourProjects}>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Share your projects with your friends.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Share publicly or privately or just keep your projects to
              yourself!
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Choose which parts of your projects you'd like to share.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Version control. Show your users when a new version is available.
            </ListItem>
          </List>
        </Feature>
      </Flex>

      <Flex boxShadow="dark-lg" zIndex="6">
        <Feature heading="Download projects." image={subscribe} secondary>
          <List color={theme.appColors.secondary.color} spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Save time and download other people's hard work.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              See when new versions have been updated and uploaded
            </ListItem>
          </List>
        </Feature>
      </Flex>
    </Flex>
  );
};

interface FeatureProps {
  heading: string;
  image: string;

  secondary?: boolean;
  right?: boolean;
}

const Feature: React.FC<FeatureProps> = ({
  heading,
  image,
  secondary = false,
  right = false,
  children,
}) => {
  const theme = useTheme();
  const { largerThanPhone } = useMediaState();

  return (
    <Flex
      w="100%"
      py={10}
      minHeight={["60vh", "60vh", "80vh"]}
      backgroundColor={secondary ? theme.appColors.secondary.background : null}
      boxShadow="dark-lg"
    >
      <Flex
        w={["95%", "80%"]}
        margin="auto"
        direction="column"
        justifyContent="space-around"
      >
        <Heading
          mb={8}
          w="100"
          textAlign="center"
          color={secondary ? theme.appColors.secondary.color : null}
        >
          {heading}
        </Heading>
        {largerThanPhone ? (
          right ? (
            <Flex
              boxShadow="dark-lg"
              borderRadius="lg"
              direction="row"
              overflow="hidden"
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                p={4}
                backgroundColor={theme.appColors.constants.featuresBackground}
                color={theme.appColors.constants.featuresColor}
              >
                {/* <Text fontWeight="bold" mb={2}>
                  Features:
                </Text> */}
                {children}
              </Flex>

              <Image w="60%" src={image} />
            </Flex>
          ) : (
            <Flex
              boxShadow="dark-lg"
              borderRadius="lg"
              direction="row"
              overflow="hidden"
            >
              <Image w="70%" src={image} />
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                p={4}
                backgroundColor={theme.appColors.constants.featuresBackground}
                color={theme.appColors.constants.featuresColor}
              >
                {/* <Text fontWeight="bold" mb={2}>
                  Features:
                </Text> */}
                {children}
              </Flex>
            </Flex>
          )
        ) : (
          <Flex
            boxShadow="dark-lg"
            borderRadius="md"
            direction="column"
            overflow="hidden"
          >
            <Image w="100%" src={image} />
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              p={4}
              backgroundColor={theme.appColors.constants.featuresBackground}
              color={theme.appColors.constants.featuresColor}
            >
              {/* <Text fontWeight="bold" mb={2}>
                Features:
              </Text> */}
              {children}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex display="none">
        <Image src={metacheckPilot} />
      </Flex>
    </Flex>
  );
};
