import { Folder } from "../../../classes";
import { userInfo } from "../user";

import { atcClearance } from "./checklists/flightCompChecks/atcClearance";
import { brief } from "./checklists/flightCompChecks/brief";
import { emergencyEquipment } from "./checklists/flightCompChecks/emergencyEquipment";
import { enrouteStation } from "./checklists/flightCompChecks/enrouteStation";
import { enteringAircraft } from "./checklists/flightCompChecks/enteringAircraft";
import { finals } from "./checklists/flightCompChecks/finals";
import { fms } from "./checklists/flightCompChecks/fms";
import { fmsInit } from "./checklists/flightCompChecks/fmsInit";
import { fuelLoad } from "./checklists/flightCompChecks/fuelLoad";
import { onboardLibray } from "./checklists/flightCompChecks/onboardLibray";
import { prelimFlightComptCheck } from "./checklists/flightCompChecks/prelimFlightComptCheck";
import { prelims } from "./checklists/flightCompChecks/prelims";
import { readyToClose } from "./checklists/flightCompChecks/readyToClose";
import { scan } from "./checklists/flightCompChecks/scan";
import { walkAround } from "./checklists/flightCompChecks/walkAround";
import { afterStartFlow } from "./checklists/pushbackAndTaxi/afterStart";
import { cleared } from "./checklists/pushbackAndTaxi/cleared";
import { miniBrief } from "./checklists/pushbackAndTaxi/miniBrief";
import { rampComm } from "./checklists/pushbackAndTaxi/rampComm";
import { throughTen } from "./checklists/takeoff/throughTen";
import { transition } from "./checklists/takeoff/transition";
import { approachBrief } from "./checklists/cruise/approachBrief";
import { approachPrep } from "./checklists/cruise/approachPrep";
import { cruiseCheck } from "./checklists/cruise/cruiseCheck";
import { afterLanding } from "./checklists/landing/afterLanding";
import { approachingGate } from "./checklists/landing/aproachingGate";
import { shutdown } from "./checklists/landing/shutdown";

///////////////////////////////////////////////////////////////////////////////

export const flightCompartmentChecksFolder = new Folder({
  userInfo,
  title: "Flight Compartment Checks",
  contentIds: [
    atcClearance.id,
    brief.id,
    emergencyEquipment.id,
    enrouteStation.id,
    enteringAircraft.id,
    finals.id,
    fms.id,
    fmsInit.id,
    fuelLoad.id,
    onboardLibray.id,
    prelimFlightComptCheck.id,
    prelims.id,
    readyToClose.id,
    scan.id,
    walkAround.id,
  ],

  contents: {
    [atcClearance.id]: atcClearance,
    [brief.id]: brief,
    [emergencyEquipment.id]: emergencyEquipment,
    [enrouteStation.id]: enrouteStation,
    [enteringAircraft.id]: enteringAircraft,
    [finals.id]: finals,
    [fms.id]: fms,
    [fmsInit.id]: fmsInit,
    [fuelLoad.id]: fuelLoad,
    [onboardLibray.id]: onboardLibray,
    [prelimFlightComptCheck.id]: prelimFlightComptCheck,
    [prelims.id]: prelims,
    [readyToClose.id]: readyToClose,
    [scan.id]: scan,
    [walkAround.id]: walkAround,
  },
});
export const pushBackAndTaxiFolder = new Folder({
  userInfo,
  title: "Push back and Taxi",
  contentIds: [afterStartFlow.id, cleared.id, miniBrief.id, rampComm.id],
  contents: {
    [afterStartFlow.id]: afterStartFlow,
    [cleared.id]: cleared,
    [miniBrief.id]: miniBrief,
    [rampComm.id]: rampComm,
  },
});

export const takeoffFolder = new Folder({
  userInfo,
  title: "Take off and climbout",
  contentIds: [throughTen.id, transition.id],
  contents: {
    [throughTen.id]: throughTen,
    [transition.id]: transition,
  },
});
export const cruiseFolder = new Folder({
  userInfo,
  title: "Cruise",
  contentIds: [approachBrief.id, approachPrep.id, cruiseCheck.id],
  contents: {
    [approachBrief.id]: approachBrief,
    [approachPrep.id]: approachPrep,
    [cruiseCheck.id]: cruiseCheck,
  },
});
export const landingFolder = new Folder({
  userInfo,
  title: "Appraoch and Landing",
  contentIds: [afterLanding.id, approachingGate.id, shutdown.id],
  contents: {
    [afterLanding.id]: afterLanding,
    [approachingGate.id]: approachingGate,
    [shutdown.id]: shutdown,
  },
});

///////////////////////////////////////////////////////////////////////////////

export const checklistFolders = new Folder({
  userInfo,
  title: "Checklist Folders",
  contentIds: [
    flightCompartmentChecksFolder.id,
    pushBackAndTaxiFolder.id,
    takeoffFolder.id,
    cruiseFolder.id,
    landingFolder.id,
  ],
  contents: {
    [flightCompartmentChecksFolder.id]: flightCompartmentChecksFolder,
    [pushBackAndTaxiFolder.id]: pushBackAndTaxiFolder,
    [takeoffFolder.id]: takeoffFolder,
    [cruiseFolder.id]: cruiseFolder,
    [landingFolder.id]: landingFolder,
  },
});
