import { produce } from "immer";
import { Checklist, Folder } from "./classes";

//using

export function getFolderFromSideNavPath(
  projects: Folder,
  sideNavPath: string[]
) {
  let folder = projects;
  let sideNavPathCopy = [...sideNavPath];

  if (sideNavPathCopy.length > 0) {
    do {
      let id = sideNavPathCopy.shift();

      if (folder.contents && folder.contents[id!]) {
        folder = folder!.contents[id!] as Folder;
      }
    } while (sideNavPathCopy.length > 0);
  }

  return folder;
}

let result: Folder | null = null;

export function findItemById(
  rootFolder: Folder,
  id: string,
  reset: boolean = true
) {
  if (reset) {
    result = null;
  }
  let folder = rootFolder;

  if (folder.id === id) {
    result = folder;
  } else if (folder.contentIds.length > 0) {
    folder.contentIds.forEach((itemId) => {
      if (folder.contents[itemId].typeInfo === "folder") {
        result = findItemById(folder.contents[itemId] as Folder, id, false);
      }
    });
  } else {
    result = null;
  }

  return result;
}

export function findSharedFolders(rootFolder: Folder, result: Folder[] = []) {
  let folder = rootFolder;

  if (folder.visibility !== "user") {
    result.push(folder);
  }

  if (folder.contentIds.length > 0) {
    folder.contentIds.map((itemId) => {
      if (folder.contents[itemId].typeInfo === "folder") {
        // console.log("utils.ts 81 folder has subFolders, better search them:");
        result = findSharedFolders(folder.contents[itemId] as Folder, result);
      }
      return null;
    });
  }

  return result;
}

export function buildBreadcrumbsFromSideNavPath(
  projects: Folder,
  sideNavPath: string[]
) {
  let folder: Folder = projects;
  let item: Folder = {} as Folder;
  let breadcrumbs = ["Root:"];
  let sideNavPathCopy = [...sideNavPath];
  if (sideNavPathCopy.length > 0) {
    do {
      let id = sideNavPathCopy.shift();
      item = folder!.contents[id!] as Folder;
      if (item) {
        breadcrumbs.push(item.title);
      }

      if (folder!.contents && folder!.contents[id!]) {
        folder = folder!.contents[id!] as Folder;
      }
    } while (sideNavPathCopy.length > 0);
  }

  return breadcrumbs;
}

export const getToolTip = (typeInfo: string, prefix: string) => {
  let tooltip = prefix;
  if (typeInfo === "projectFolder" || typeInfo === "rootFolder") {
    tooltip = tooltip + "Project";
  } else if (typeInfo === "noteFolders") {
    tooltip = tooltip + "Note Folder";
  } else if (typeInfo === "noteFolder") {
    tooltip = tooltip + "Note";
  } else if (typeInfo === "checklistFolder") {
    tooltip = tooltip + "Checklist";
  } else if (typeInfo === "memoryItemFolder") {
    tooltip = tooltip + "Memory Item";
  } else if (typeInfo === "checklistFolders") {
    tooltip = tooltip + "Checklist Folder";
  } else if (typeInfo === "sharedFolder") {
    tooltip = tooltip + "Shared Folder";
  }

  return tooltip;
};

export function updateOrders(oldOrder: number, newOrder: number, folder: any) {
  for (let item in folder) {
    let currentOrder = folder[item].order;

    if (currentOrder > oldOrder && currentOrder > newOrder) {
      //do nothing
    } else if (currentOrder < oldOrder && currentOrder < newOrder) {
      //do nothing
    } else if (currentOrder > oldOrder && currentOrder <= newOrder) {
      folder[item].order = folder[item].order - 1;
    } else if (currentOrder < oldOrder && currentOrder >= newOrder) {
      folder[item].order = folder[item].order + 1;
    }
  }
}

export const cleanObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const resetChecklist = (checklist: Checklist): Checklist => {
  let nextState = produce(checklist, (draftState) => {
    draftState.checklistDone = false;
    let checks = draftState.checks;
    for (let check in checks) {
      checks[check].checkDone = false;
    }
  });

  return nextState;
};

export const updateChecklist = (
  draftState: Checklist,
  appStateFFOD: boolean
): Checklist => {
  // console.log("utils.ts 254 :", allDone(draftState, appStateFFOD));
  draftState.checklistDone = allDone(draftState, appStateFFOD);

  return draftState;
};

export const resetAll = (rootFolder: Folder): Folder => {
  let nextState = produce(rootFolder, (draftState) => {
    let items = draftState.contents;
    let fileIds = Object.keys(items);
    if (fileIds.length > 0) {
      fileIds.forEach((itemId) => {
        if (items[itemId].typeInfo === "checklist") {
          let checklist = items[itemId] as Checklist;
          items[itemId] = resetChecklist(checklist);
        }

        if (items[itemId].typeInfo === "folder") {
          let folder = items[itemId];
          items[itemId] = resetAll(folder as Folder);
        }
      });
    }
  });

  return nextState;
};

export const updateAll = (
  rootFolder: Folder,
  appStateFFOD: boolean
): Folder => {
  let nextState = produce(rootFolder, (draftState) => {
    let items = draftState.contents;
    let itemIds = Object.keys(items);
    if (itemIds.length > 0) {
      itemIds.forEach((itemId) => {
        if (items[itemId].typeInfo === "checklist") {
          let checklist = items[itemId] as Checklist;
          items[itemId] = updateChecklist(checklist, appStateFFOD);
        }

        if (items[itemId].typeInfo === "folder") {
          let folder = items[itemId];
          items[itemId] = updateAll(folder as Folder, appStateFFOD);
        }
      });
    }
  });

  return nextState;
};
export const versionUpdate = (currentVersion: number, major: boolean) => {
  if (major) {
    return Math.floor(currentVersion + 1);
  } else {
    return Math.round((currentVersion + 0.1) * 100) / 100;
  }
};

export const removeElFromArray = (array: any[], el: any) => {
  let copyArr = [...array];
  let index = copyArr.indexOf(el);
  copyArr.splice(index, 1);
  return copyArr;
};

export function allDone(checklist: Checklist, appStateFFOD: boolean) {
  let allDone = true;

  //loop through checks in checklist
  Object.keys(checklist!.checks).forEach((checkId) => {
    let check = checklist!.checks[checkId];

    //If FFOD, all checks in checklist count towards showing the checklist complete

    //If !FFOD, the checklist is still complete despite checks that are ffod being false. skip over checks that are ffod and full row.

    if (appStateFFOD) {
      //It is FFOD. if any check is false, it counts. A ffod check must be done for checklist to be completed. If any check is false, consider the checklist to be incomplete.
      if (!check.fullRow && !check.checkDone) {
        allDone = false;
      }
    } else {
      //FFOD is false, only check check non-ffod checks.

      //only check for !ffod checks.
      if (!check.FFOD && !check.fullRow) {
        if (!check.checkDone) {
          allDone = false;
        }
      }
    }
  });

  return allDone;
}
