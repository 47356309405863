import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let compare = new Check({
  title: "Compare QTY and OFP",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let acasrUplift = new Check({
  title: "ACARS fuel uplift",
  FFOD: false,
  action: "CHECK OK",

  fullRow: false,
});
let fuel = new Check({
  title: "Advise load if greater than 300kg than planned",
  FFOD: false,

  fullRow: true,
});

let weight = new Check({
  title: "Actual T/O weight must be within +0kg/-1200kg",
  FFOD: false,

  fullRow: true,
});

export const fuelLoad = new Checklist({
  title: "Fuel and Load",
  userInfo,
  checkIds: [compare.id, acasrUplift.id, fuel.id, weight.id],
  checks: {
    [compare.id]: compare,
    [acasrUplift.id]: acasrUplift,
    [fuel.id]: fuel,
    [weight.id]: weight,
  },
});
