import { v4 as uuid } from "uuid";
import { MemoryItemRow } from "./types";
import {
  ICheck,
  IChecklist,
  IFolder,
  IMemoryItem,
  IMetaData,
  INote,
  IPublicFolderProps,
  IUserInfo,
  TFolderContents,
  TSortByOptions,
  TVisibilityOptions,
} from "./typesv2";

export class UserInfo {
  email: string;
  uid: string;
  username: string;

  constructor(props: IUserInfo) {
    this.email = props.email;
    this.uid = props.uid;
    this.username = props.username;
  }
}

class MetaData {
  id: string;
  title: string;
  visibility: TVisibilityOptions;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  authUsers: string[];
  password: string | null;
  userInfo: UserInfo;

  constructor(x: IMetaData) {
    this.id = x.id || uuid();
    this.title = x.title;
    this.version = x.version || 0;
    this.visibility = x.visibility || "user";
    this.authUsers = x.authUsers || [];
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.password = x.password || null;
    this.userInfo = x.userInfo;
  }
}

///////////////////////////////////////////////////////////////////////////////

export class MemoryItem extends MetaData {
  data: MemoryItemRow[];
  typeInfo: "memoryItem";
  editable: boolean;

  constructor(props: IMemoryItem) {
    super(props);
    this.data = props.data || [];
    this.typeInfo = "memoryItem";
    this.editable = false;
  }
}

///////////////////////////////////////////////////////////////////////////////

export class Note extends MetaData {
  note: string;
  typeInfo: "note";
  editable: boolean;

  constructor(props: INote) {
    super(props);
    this.note = props.note || "";
    this.typeInfo = "note";
    this.editable = false;
  }
}

///////////////////////////////////////////////////////////////////////////////

export class Check {
  id: string;
  title: string;
  checkDone?: boolean;
  FFOD?: boolean;
  fullRow?: boolean;
  action?: string;
  info?: string;

  constructor(props: ICheck) {
    this.id = uuid();
    this.title = props.title;
    this.checkDone = props.checkDone || false;
    this.FFOD = props.FFOD || false;
    this.action = props.action || "";
    this.info = props.info || "";
    this.fullRow = props.fullRow || false;
  }
}

export class Checklist extends MetaData {
  checks: { [key: string]: Check };
  checkIds: string[];
  checklistDone: boolean;
  typeInfo: "checklist";

  constructor(props: IChecklist) {
    super(props);
    this.checkIds = props.checkIds || [];
    this.checks = props.checks || {};
    this.checklistDone = false;
    this.typeInfo = "checklist";
  }
}

export type FileType = Checklist | Note | MemoryItem;

///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////

export class Folder extends MetaData implements IFolder {
  contents: TFolderContents;
  contentIds: string[];
  sortBy: TSortByOptions;
  downloads: number;
  typeInfo: "folder";

  constructor(props: IFolder) {
    super(props);
    this.contents = props.contents || {};
    this.contentIds = props.contentIds || [];
    this.sortBy = props.sortBy || "alpha";
    this.downloads = props.downloads || 0;
    this.typeInfo = "folder";
  }
}

/////////////////////////////////////////////////////////////////////////////

export class PublicFolder {
  authUsers: string[];
  userInfo: UserInfo;
  downloads: number;
  id: string;
  title: string;
  typeInfo: "folder";
  updatedAt: Date;
  version: number;
  visibility: TVisibilityOptions;
  password: string | null;

  constructor(props: IPublicFolderProps) {
    this.authUsers = props.authUsers || [];
    this.downloads = 0;
    this.userInfo = props.userInfo;
    this.id = props.id || uuid();
    this.title = props.title;
    this.typeInfo = "folder";
    this.updatedAt = new Date();
    this.version = props.version || 0;
    this.visibility = props.visibility || "public";
    this.password = props.password || null;
  }
}

/////////////////////////////////////////////////////////////////////////////////

export type ItemType = Folder | FileType;

///////////////////////////////////////////////////////////////////////////////
let userInfo = new UserInfo({
  email: "test@test.com",
  uid: "GOoAmI6x7FVx9jDjukvFTtgtsO53",
  username: "test",
});

///////////////////////////////////////////////////////////////////////////////

const m1 = new MemoryItem({
  title: "Memory Item 1",
  userInfo,
});

///////////////////////////////////////////////////////////////////////////////

const n1 = new Note({
  title: "Note 1",
  userInfo,
});
///////////////////////////////////////////////////////////////////////////////
const c1 = new Checklist({
  title: "Checklist 1",
  userInfo,
});
///////////////////////////////////////////////////////////////////////////////

const cf1 = new Folder({
  title: "Checklist Folder 1",
  userInfo,
  contents: { [c1.id]: c1, [n1.id]: n1, [m1.id]: m1 },
  contentIds: [c1.id, n1.id, m1.id],
});
const cf2 = new Folder({
  title: "Checklist Folder 2",
  userInfo,
  contents: {},
});

///////////////////////////////////////////////////////////////////////////////

const checklistFolder = new Folder({
  title: "A220 Checklists",
  userInfo,
  contents: {
    [cf1.id]: cf1,
    [cf2.id]: cf2,
  },
  contentIds: [cf1.id, cf2.id],
});
const notesFolder = new Folder({
  title: "A220 Notes",
  userInfo,
});
const memoryItemsFolder = new Folder({
  title: "A220 MemoryItems",
  userInfo,
});

export const rootFolderClasses = new Folder({
  title: "Root Folder Classes",
  userInfo,
  contents: {
    [checklistFolder.id]: checklistFolder,
    [notesFolder.id]: notesFolder,
    [memoryItemsFolder.id]: memoryItemsFolder,
  },
  contentIds: [checklistFolder.id, notesFolder.id, memoryItemsFolder.id],
});
