import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let light = new Check({
  title: "Taxi light",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});
let antiIce = new Check({
  title: "Anti-Ice",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});

export const approachingGate = new Checklist({
  title: "Approaching Gate",
  userInfo,
  checkIds: [light.id, antiIce.id],
  checks: {
    [light.id]: light,
    [antiIce.id]: antiIce,
  },
});
