import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const weights = new Note({
  note: `
MLW             57833
MTOW            67585
MZFW            55111
MRW             68039
Min Flight W    36287
  `,
  title: "Weights",
  userInfo,
});
