import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const ash = new MemoryItem({
  title: "Volcanic Ash",
  userInfo,
  data: [
    {
      data: ["Volcanic Ash"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- AT – off
- Thrust – reduce to idle
- Turn – 180
- Crew oxy mask
- Checklist
      `,
      ],
    },
  ],
});
