import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";
let standard = new Check({
  title: "PF calls 'Standard'",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});
let crossCheck = new Check({
  title: "PM: 'Standard set and crosschecked'",
  FFOD: false,
  action: "test",
  info: "On below 19,000 at < 15C TAT",
  fullRow: false,
});

export const transition = new Checklist({
  title: "Transition",
  userInfo,
  checkIds: [standard.id, crossCheck.id],
  checks: {
    [standard.id]: standard,
    [crossCheck.id]: crossCheck,
  },
});
