import React from "react";
import { BsCloudDownload, BsPlusCircle, BsCloudUpload } from "react-icons/bs";
import { CgMenu, CgProfile } from "react-icons/cg";
import { FaMoon, FaSun, FaTrash } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { GoSearch, GoSignOut } from "react-icons/go";
import { GrPowerReset, GrStatusInfo } from "react-icons/gr";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { ImCancelCircle } from "react-icons/im";
import { RiFolderSharedLine } from "react-icons/ri";
import { VscChecklist } from "react-icons/vsc";
import { MyIconButton } from "./MyIconButton";
import { FiEye } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { BsArrowsMove } from "react-icons/bs";

export const AccountIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<CgProfile />} tooltip="Account" />
);
export const AddIconButton = ({
  onClick,
  tooltip,
}: {
  onClick: () => void;
  tooltip: string;
}) => (
  <MyIconButton onClick={onClick} icon={<BsPlusCircle />} tooltip={tooltip} />
);
export const BackIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<HiArrowNarrowLeft />}
    tooltip="Go Back"
  />
);
export const CancelIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<ImCancelCircle />}
    tooltip="Cancel"
    backgroundColor="yellow.300"
  />
);
export const CheckMarkIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<FiCheck />}
    tooltip="Submit"
    backgroundColor="green.500"
  />
);

export const CloudIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<BsCloudDownload />}
    tooltip="Download"
  />
);
export const CloudUpIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<BsCloudUpload />} tooltip="Share" />
);
export const CompleteIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<VscChecklist />} tooltip="Complete" />
);
export const EditIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<FaEdit />} tooltip="Edit" />
);
export const ForwardIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<HiArrowNarrowRight />}
    tooltip="Next Page"
  />
);
export const InfoIconButton = ({ onClick }: { onClick: any }) => (
  <MyIconButton onClick={onClick} icon={<GrStatusInfo />} tooltip="More Info" />
);
export const LogoutIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<GoSignOut />} tooltip="Log out" />
);
export const MenuIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<CgMenu />} tooltip="Menu" />
);
export const MoonIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<FaMoon />} tooltip="Dark Mode" />
);
export const PreviewIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<FiEye />} tooltip="Preview" />
);
export const ResetIconButton = ({
  onClick,
  tooltip,
}: {
  onClick: () => void;
  tooltip: string;
}) => (
  <MyIconButton onClick={onClick} icon={<GrPowerReset />} tooltip={tooltip} />
);
export const SearchIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<GoSearch />} tooltip="Search" />
);
export const ShareIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<RiFolderSharedLine />}
    tooltip="Share"
  />
);
export const SunIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<FaSun />} tooltip="Light Mode" />
);
export const TrashIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton
    onClick={onClick}
    icon={<FaTrash />}
    tooltip="Delete"
    backgroundColor="red.300"
  />
);

export const MoveIconButton = ({ onClick }: { onClick: () => void }) => (
  <MyIconButton onClick={onClick} icon={<BsArrowsMove />} tooltip="Move" />
);
