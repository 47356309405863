import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppButton } from "../components/basicComponents/AppButton";
import { LoadingSpinner } from "../components/LoadingSpinner";
import checkPilotSmile from "../images/checkPilotSmile.png";
import { useAuth } from "../providers/AuthProvider";

interface ProtectedHomeProps {}

// let path = {
//   title: "Step 1",
//   children: {
//     {title: "Step 2", active: true },
//     {title: "Step 2", active: false}
//   }
// }

export const ProtectedHome: React.FC<ProtectedHomeProps> = () => {
  // const theme = useTheme();
  // const { colorMode } = useColorMode();
  const { userInfo } = useAuth();
  // const { firestoreAddOrEditProject } = useData();
  // const { openSideNav } = useAppState();
  // const history = useHistory();
  // const [tutorialPath, setTutorialPath] = useState();

  // if (!uid) {
  //   history.push("/");
  //   return <Text>redirecting...</Text>;
  // }
  // const handleDownloadExampleProject = () => {
  //   firestoreAddOrEditProject(cleanObject(exampleProject));
  //   openSideNav();
  // };

  if (userInfo) {
    return (
      <Flex
        direction="column"
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          // h="60vh"
          alignItems="center"
          justifyContent="center"
          w="80%"
          margin="40px auto 0 auto"
          direction={["column", "column", "column", "column", "row"]}
        >
          <Image
            src={checkPilotSmile}
            w={["90%", "65%", "60%", "60%", "45%"]}
          />
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            w="100%"
            mt={[4, 4, 0]}
          >
            <Heading size="2xl" mb={4} textAlign="center">
              Welcome, {userInfo!.username || userInfo.email}.
            </Heading>
            <Text ml={2} fontSize="xl" textAlign="center">
              Let's see what this puppy will do.
            </Text>
            <AppButton
              size="sm"
              mt={4}
              as={RouterLink}
              to="/shared-folders"
              mr={2}
              text="Download Public Projects"
            />

            <AppButton
              size="sm"
              mt={4}
              as={RouterLink}
              to="/my-shared-projects"
              mr={2}
              text="Share Your Projects"
            />

            <AppButton
              size="sm"
              mt={4}
              as={RouterLink}
              to="/account"
              mr={2}
              text="Manage Your Account"
            />

            <Flex
              direction="column"
              w="100%"
              justifyContent="center"
              alignItems="center"
              mt={8}
            >
              <Text fontWeight="bold" fontSize="lg" textAlign="center">
                Don't know where to start?
              </Text>
              <Text fontWeight="bold" fontSize="lg" my={2} textAlign="center">
                Try out our example project:
              </Text>
              {/* <AppButton
                size="xs"
                onClick={handleDownloadExampleProject}
                text="Download example project"
              /> */}
            </Flex>
          </Flex>
        </Flex>

        <Box h="200px" />
      </Flex>
    );
  }

  return <LoadingSpinner loading={true} />;
};

// interface TutElProps {
//   step: number;
// }

// const TutorialElement: React.FC<TutElProps> = ({ step, children }) => {
//   return (
//     <Flex direction="column" border="1px" borderRadius="md" m={2} p={2}>
//       <Heading w="100%" m="auto" textAlign="center">
//         Step {step}
//       </Heading>
//       {children}
//     </Flex>
//   );
// };

// const Step1 = ({}) => {
//   const { largerThanPhone } = useMediaState();
//   if (largerThanPhone) {
//     return (
//       <TutorialElement step={1}>
//         <Flex justifyContent="space-around">
//           <Flex p={2} direction="column" alignItems="center">
//             <Text>Create a Project.</Text>
//             <Button my={2} onClick={() => {}}>
//               Create
//             </Button>
//             <Icon as={ImArrowDown} w={6} h={6} />
//           </Flex>
//           <Flex p={2} direction="column" alignItems="center">
//             <Text>Download a Project.</Text>
//             <Button my={2}>Download</Button>
//             <Icon as={ImArrowDown} w={6} h={6} />
//           </Flex>
//         </Flex>
//       </TutorialElement>
//     );
//   }
//   return (
//     <TutorialElement step={1}>
//       <Flex justifyContent="space-around">
//         <Flex p={2} direction="column" alignItems="center">
//           <Text>Create a Project.</Text>
//           <Button my={2} onClick={() => {}}>
//             Create
//           </Button>
//           <Icon as={ImArrowDown} w={6} h={6} />
//         </Flex>
//         <Flex p={2} direction="column" alignItems="center">
//           <Text>Download a Project.</Text>
//           <Button my={2}>Download</Button>
//           <Icon as={ImArrowDown} w={6} h={6} />
//         </Flex>
//       </Flex>
//     </TutorialElement>
//   );
// };

// const Step2 = ({}) => {
//   const { largerThanPhone } = useMediaState();
//   if (largerThanPhone) {
//   }
//   return (
//     <TutorialElement step={1}>
//       <Flex justifyContent="space-around">
//         <Flex p={2} direction="column" alignItems="center">
//           <Text>Create a Project.</Text>
//           <Button my={2} onClick={() => {}}>
//             Create
//           </Button>
//           <Icon as={ImArrowDown} w={6} h={6} />
//         </Flex>
//         <Flex p={2} direction="column" alignItems="center">
//           <Text>Download a Project.</Text>
//           <Button my={2}>Download</Button>
//           <Icon as={ImArrowDown} w={6} h={6} />
//         </Flex>
//       </Flex>
//     </TutorialElement>
//   );
// };
