import {
  Box,
  Flex,
  Radio,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
  CheckboxContainer,
  CheckboxControl,
  InputControl,
  RadioGroupControl,
} from "formik-chakra-ui";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { Folder } from "../../classes";
import { useMyTheme } from "../../hooks/useMyTheme";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { TVisibilityOptions } from "../../typesv2";
import { findItemById, versionUpdate } from "../../utils";
import { AppButton } from "../basicComponents/AppButton";
import { TextInput } from "../formComponents/TextInput";
import { CancelIconButton, CheckMarkIconButton } from "../IconButtons";
import { AuthUsers } from "./AuthUsers";

interface ShareFormProps {
  onClose: () => void;
}

export const ShareProjectForm: React.FC<ShareFormProps> = ({ onClose }) => {
  const {
    // firestoreAddOrEditProject,
    firestoreAddToPublicItems,
    firestoreDeleteFromPublicItems,
    saveRootFolderToFirebase,
    setRootFolder,
    rootFolder,
  } = useData();
  const { shareId } = useAppState();
  const [error, setError] = useState<string | null>(null);
  const [folder, setFolder] = useState<Folder | null>(null);
  const background = useMyTheme("background");
  const warningColor = useMyTheme("warning");

  let emptyFolder = true;

  if (folder) {
    emptyFolder = folder.contentIds.length === 0;
  }

  useEffect(() => {
    let folder = findItemById(rootFolder, shareId) as Folder;
    setFolder(folder);
  }, [shareId, rootFolder]);

  if (!folder) {
    return null;
  }

  const visibilityOptionsInfo = (visibility: TVisibilityOptions) => {
    if (visibility === "user") {
      return (
        <Text fontSize="sm">
          The file or folder is not shared. Only you will be able to see it.
        </Text>
      );
    }
    if (visibility === "public") {
      return <Text fontSize="sm">Share the file or folder publicly.</Text>;
    }
    if (visibility === "private") {
      return (
        <Text fontSize="sm">
          Share the file or folder privately. Choose who can view your project.
        </Text>
      );
    }
    if (visibility === "password") {
      return (
        <Text fontSize="sm">Access your file or folder with a password</Text>
      );
    }
  };

  const handleAddUser = (email: string) => {
    let emailRegex = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$", "gi");

    let validEmail = emailRegex.test(email);

    if (validEmail) {
      let nextState = produce(rootFolder!, (draftState) => {
        let item = findItemById(draftState, folder.id);
        item!.authUsers = [...item!.authUsers, email];
      });

      setRootFolder(nextState);
      setError(null);
    } else {
      setError("Invalid Email");
    }
  };

  return (
    <Flex
      borderRadius="lg"
      p={2}
      direction="column"
      backgroundColor={background}
    >
      <Formik
        initialValues={{
          visibility: folder.visibility,
          version: false,
          addUser: "",
          password: folder.password || "",
        }}
        enableReinitialize={true}
        onSubmit={async (values) => {
          if (values.visibility !== "user") {
            const nextState = produce(rootFolder, (draftState) => {
              let item = findItemById(draftState, folder.id) as Folder;

              let newVersion = values.version
                ? Math.floor(item.version + 1)
                : item.version + 0.1;

              item.visibility = values.visibility;
              item.version = Math.round(newVersion * 100) / 100;
              item.password = values.password;
            });

            let itemToUpload = findItemById(nextState, folder.id) as Folder;

            firestoreAddToPublicItems(itemToUpload, nextState);
            onClose();
          } else {
            const nextState = produce(rootFolder, (draftState) => {
              let item = findItemById(draftState, folder.id) as Folder;

              let newVersion = values.version
                ? Math.floor(item.version + 1)
                : item.version + 0.1;

              item.visibility = "user";
              item.version = Math.round(newVersion * 100) / 100;
              item.password = "";
            });

            saveRootFolderToFirebase(nextState);

            if (folder.visibility !== "user") {
              let itemToDelete = findItemById(nextState, folder.id) as Folder;

              firestoreDeleteFromPublicItems(itemToDelete);
            }
            onClose();
          }
        }}
      >
        {({ isSubmitting, values }) => {
          let formTitle = `Share ${
            folder.typeInfo === "folder" ? "Folder" : "File"
          }:  ${folder.title} v.${folder.version}`;
          let shareButtonText = "Upload";
          if (folder.visibility !== "user" && values.visibility === "user") {
            shareButtonText = "Delete from cloud";
          } else if (folder.visibility !== "user") {
            shareButtonText = "Upload new version";
          }
          return (
            <Form>
              <Flex alignItems="center" mb={2} pb={2}>
                <Flex direction="column" w="100%">
                  <Flex alignItems="center" justifyContent="flex-end">
                    {emptyFolder && (
                      <Text mr={2} color={warningColor}>
                        Cannot share empty folders
                      </Text>
                    )}
                    <AppButton
                      size="sm"
                      type="submit"
                      isDisabled={emptyFolder}
                      text={shareButtonText}
                    />
                    <Box ml={2} mr={1}>
                      <CancelIconButton
                        onClick={() => {
                          // setShowProjectForm("");
                          onClose();
                        }}
                      />
                    </Box>
                  </Flex>

                  <Flex mt={2} direction="column">
                    <Flex>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        flex="1"
                      >
                        {formTitle}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex pb={2} direction="column" justifyContent="center">
                <Text my={2} size="lg" fontWeight="bold" textAlign="center">
                  How do you want to share your
                  {folder.typeInfo === "folder" ? " folder" : " file"}?
                </Text>

                <Flex justifyContent="center" p={4}>
                  <RadioGroupControl
                    size="sm"
                    flexDirection="column"
                    name="visibility"
                  >
                    <Radio size="sm" ml={6} w="100px" value="user">
                      Just you
                    </Radio>

                    <Radio size="sm" ml={6} w="100px" value="public">
                      Everybody
                    </Radio>

                    <Radio size="sm" value="private">
                      People I choose
                    </Radio>
                    <Radio size="sm" value="password">
                      Password
                    </Radio>
                  </RadioGroupControl>
                </Flex>

                <Flex
                  mt={2}
                  border="2px"
                  borderRadius="md"
                  borderStyle="dashed"
                  borderColor="gray.500"
                  w="85%"
                  mx="auto"
                  p={4}
                  justifyContent="center"
                >
                  {visibilityOptionsInfo(values.visibility)}
                </Flex>
              </Flex>

              {values.visibility !== "user" && (
                <>
                  <Flex mt={2} justifyContent="center">
                    <Text my={2} size="lg" fontWeight="bold" textAlign="center">
                      Share Options:
                    </Text>
                  </Flex>

                  <Tabs size="sm" isFitted>
                    <TabList>
                      <Tab>Version</Tab>
                      {/* <Tab>Select Files to Share</Tab> */}

                      {values.visibility === "private" && (
                        <Tab _>Authorized Users</Tab>
                      )}
                      {values.visibility === "password" && (
                        <Tab _>Set Password</Tab>
                      )}
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Flex mb={2}>
                          <CheckboxContainer
                            my={4}
                            name="version"
                            label={`Version: ${
                              folder.version
                            }   -->   ${versionUpdate(
                              folder.version,
                              values.version
                            )}`}
                          >
                            <CheckboxControl name="version" value="version">
                              Major Version?
                            </CheckboxControl>
                          </CheckboxContainer>
                        </Flex>
                      </TabPanel>

                      {values.visibility === "private" && (
                        <TabPanel>
                          <Flex direction="column">
                            <Flex alignItems="center">
                              <Text mr={2}>Add User:</Text>

                              <InputControl
                                mr={2}
                                flex="1"
                                name="addUser"
                                placeholder="Add User:"
                              />
                              <CheckMarkIconButton
                                onClick={() => {
                                  handleAddUser(values.addUser);
                                  values.addUser = "";
                                }}
                              />
                            </Flex>
                            {error && <Text color="red.300">{error}</Text>}
                            <Text my={2} textAlign="center">
                              *When finished adding users, make sure you save
                              your project!
                            </Text>
                            <AuthUsers folder={folder} />
                          </Flex>
                        </TabPanel>
                      )}
                      {values.visibility === "password" && (
                        <TabPanel>
                          <TextInput
                            name="password"
                            placeholder="password"
                            label="Set password:"
                          />
                        </TabPanel>
                      )}
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};
