import {
  ChakraProvider as CP,
  ColorModeScript,
  extendTheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import React from "react";

// 2. Add your color mode config
const config = {
  initialColorMode: "light" as "light",
  useSystemColorMode: false,
};
// 3. extend the theme
const theme = extendTheme({
  config,
  styles: {
    global: (props) => {
      return {
        body: {
          color: mode("gray.800", "whiteAlpha.900")(props),
          bg: mode("white", "#1F1C1F")(props),
        },
      };
    },
  },

  appColors: {
    secondary: {
      background: "blue.900",
      color: "white",
    },
    tertiary: {
      background: "#102D55",
      color: "white",
    },
    constants: {
      featuresBackground: "gray.800",
      featuresColor: "white",
      button: "blue.200",
      buttonColor: "black",
    },
    dark: {
      primaryScheme: "blue",
      cautionScheme: "yellow",
      warningScheme: "red",
      successScheme: "green",
      secondary: "yellow.500",
      background: "#141214",
      fontColor: "white",
      backgroundSecondary: "gray.800",
      backgroundThird: "gray.600",
      border: "gray.600",
      warning: "red.600",
      button: "blue.200",
      placeholderColor: "gray.500",
      textColor: "white",
    },
    light: {
      primaryScheme: "gray",
      cautionScheme: "yellow",
      warningScheme: "red",
      successScheme: "green",
      secondary: "yellow.800",
      background: "white",
      backgroundSecondary: "gray.300",
      backgroundThird: "gray.400",
      border: "gray.700",
      warning: "red.400",
      button: "blue.200",
      placeholderColor: "gray.700",
      textColor: "black",
    },
  },

  // components: { IconButton: { defaultProps: { colorScheme: "blue" } } },
});

interface ChakraProviderProps {}
export const ChakraProvider: React.FC<ChakraProviderProps> = ({ children }) => {
  return (
    <CP resetCSS theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      {children}
    </CP>
  );
};

//ICONS
//import {FaMoon} from "react-icons/fa";
//import {FaSun} from "react-icons/fa";
//import {FaEdit} from "react-icons/fa";
//import {FaTrash} from "react-icons/fa";
// import { GoSignOut } from "react-icons/go";
// import { FiCheck } from "react-icons/fi";
// import {ImAirplane } from "react-icons/im";
// import {CgProfile } from "react-icons/cg";
// import {HiArrowNarrowLeft } from "react-icons/hi";
// import {BsPlusCircle } from "react-icons/bs";
// import {ImCancelCircle } from "react-icons/im";
// import {FaRegFolder } from "react-icons/fa";
// import {FaRegFolderOpen } from "react-icons/fa";
// import {FaRegFile } from "react-icons/fa";
// import {VscChecklist} from 'react-icons/vsc'
// import {GrPowerReset} from 'react-icons/gr'
// import {FaCaretDown} from 'react-icons/fa'
// import {FaCaretUp} from 'react-icons/fa'
// import {FaCaret} from 'react-icons/fa'
// import {GrPowerReset} from 'react-icons/gr'
// import {ImArrowDown} from 'react-icons/im'
// import {CgMenu} from 'react-icons/cg'
// import {BsCloudDownload} from 'react-icons/bs'
// import {GiBrain} from 'react-icons/gi'
// import {CgNotes} from 'react-icons/cg'
// import {VscChecklist} from 'react-icons/vsc'
// import {BsArrowsMove} from 'react-icons/bs'
// import {MdDriveFolderUpload} from 'react-icons/md'

// colorMode === "dark"
// ? theme.appColors.dark.border
// : theme.appColors.light.border
// const theme = useTheme();
// const { colorMode } = useColorMode();
