import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let brake = new Check({
  title: "Park Brake",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let nose = new Check({
  title: "Nose steering",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});
let hydpump = new Check({
  title: "Single engine - hyd pump ",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});
let apu = new Check({
  title: "APU running?",
  FFOD: false,
  action: "confirm",
  fullRow: false,
});
let levers = new Check({
  title: "Thrust levers",
  FFOD: false,
  action: "IDLE",
  fullRow: false,
});
let engines = new Check({
  title: "Engines",
  FFOD: false,
  action: "OFF",

  fullRow: false,
});
let belts = new Check({
  title: "Seat belts",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});

let beacon = new Check({
  title: "Beacon off below 10% n1",
  FFOD: false,
  action: "OFF",
  fullRow: false,
});
let hand = new Check({
  title: "Advise ramp with hand signals",
  FFOD: false,
  action: "brief",
  fullRow: false,
});

export const shutdown = new Checklist({
  title: "Shutdown",
  userInfo,
  checkIds: [
    brake.id,
    nose.id,
    hydpump.id,
    apu.id,
    levers.id,
    engines.id,
    belts.id,
    beacon.id,
    hand.id,
  ],
  checks: {
    [brake.id]: brake,
    [nose.id]: nose,
    [hydpump.id]: hydpump,
    [apu.id]: apu,
    [levers.id]: levers,
    [engines.id]: engines,
    [belts.id]: belts,
    [beacon.id]: beacon,
    [hand.id]: hand,
  },
});
