import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let read = new Check({
  title: "CA reads final load",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let pax = new Check({
  title: "confirm Pax #'s",
  FFOD: false,
  action: "confirm",
  info: "+/- 3 per zone, +/-6 total",

  fullRow: false,
});
let update = new Check({
  title: "Update ZFW",
  FFOD: false,
  action: "update",

  fullRow: false,
});

let mac = new Check({
  title: "Enter MAC",
  FFOD: false,
  action: "update",

  fullRow: false,
});

let vspeeds = new Check({
  title: "Reset VSpeeds",
  FFOD: false,
  action: "update",
  info: "you can just reset your v2 speed instead of all 3 if your WAT is good still.",
  fullRow: false,
});

export const finals = new Checklist({
  title: "Finals",
  userInfo,
  checkIds: [read.id, pax.id, update.id, mac.id, vspeeds.id],
  checks: {
    [read.id]: read,
    [pax.id]: pax,
    [update.id]: update,
    [mac.id]: mac,
    [vspeeds.id]: vspeeds,
  },
});
