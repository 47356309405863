import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const cold = new Note({
  note: `
  Cold soaked at -20 for > 8hrs ? cycle reversers twice

Engine runs: 3 degrees/ 60% N1 / every 30 minutes

In air and if Vibs, increase to CLB thrust

Icing conditions exist when temp is at 10 or below & visible moisture or FG < 1SM

Cowl A/I on in IC or ceiling < 1500 at 10 degrees
Wing A/I on in IC or ceiling < 1500 at 5 degrees

No op wing A/I above 35000 or above 15 degrees
No holding in IC with flaps/slats extended

Add 5kts to REF, AC, GA speeds in IC
  `,
  title: "Cold Wx Ops",
  userInfo,
});
