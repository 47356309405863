import React from "react";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { useAuth } from "../providers/AuthProvider";
import { ProtectedHome } from "./ProtectedHome";
import { PublicHome } from "./PublicHome";
interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  const { userInfo, loadingAuth } = useAuth();

  // if (loadingAuth) {
  //   return (
  //     <Box>
  //       <Text>Loading homepage..</Text>
  //     </Box>
  //   );
  // }

  if (!loadingAuth && userInfo) {
    return <ProtectedHome />;
  }

  if (!loadingAuth && !userInfo) {
    return <PublicHome />;
  }

  return <LoadingSpinner loading={loadingAuth} />;
};
