import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";
let commOk = new Check({
  title: "Communications OK, Brakes set.",
  FFOD: false,

  fullRow: false,
});
let standby = new Check({
  title: "Standby. *get push clearance*",
  FFOD: false,

  fullRow: false,
});
let brakes = new Check({
  title:
    "Brakes off. Ready for pushback. Tail north. Spot 9. Delaying engine start until advised.",
  FFOD: false,

  fullRow: false,
});
let engines = new Check({
  title: "Starting left and right engines.",
  FFOD: false,

  fullRow: false,
});
let brakesSet = new Check({
  title: "Brakes set. Confirm when pins removed.",
  FFOD: false,

  fullRow: false,
});
let revert = new Check({
  title: "Revert to hand signals",
  FFOD: false,
  fullRow: false,
});

export const rampComm = new Checklist({
  title: "Ramp Communications",
  userInfo,
  checkIds: [
    commOk.id,
    standby.id,
    brakes.id,
    engines.id,
    brakesSet.id,
    revert.id,
  ],
  checks: {
    [commOk.id]: commOk,
    [standby.id]: standby,
    [brakes.id]: brakes,
    [engines.id]: engines,
    [brakesSet.id]: brakesSet,
    [revert.id]: revert,
  },
});
