import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { HomeButton } from "../components/HomeButton";
interface TutorialProps {}

export const Tutorial: React.FC<TutorialProps> = () => {
  return (
    <Box>
      <Text>Tutorial is currently under construction</Text>
      <HomeButton />
    </Box>
  );
};
