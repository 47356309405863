import { MemoryItem } from "../../../src/classes";
import { userInfo } from "./user";

export const engineFailCruise = new MemoryItem({
  title: "Example Memory Item",
  userInfo,
  data: [
    {
      data: ["Example Memory Item"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["CA", "FO"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
You can adjust styles for each row
      `,
        `
You can change the font weight, size and alignment
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: ["Or just a single column."],
    },
  ],
});
