import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let info = new Check({
  title: "Get Info",
  info: "MCNEIA - MELs, Construction Codes, NOTAMS, Eicas & Info, ATIS ",
  FFOD: false,
  action: "get",
  fullRow: false,
});
let program = new Check({
  title: "Program: Arrivals page, Landing WAT, performace",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let mins = new Check({
  title: "Set Minimums",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let PA = new Check({
  title: "PA - 30 minutes to landing",
  FFOD: false,
  action: "accomplish",
  fullRow: false,
});

export const approachPrep = new Checklist({
  title: "Approach Prep",
  userInfo,
  checkIds: [info.id, program.id, mins.id, PA.id],
  checks: {
    [info.id]: info,
    [program.id]: program,
    [mins.id]: mins,
    [PA.id]: PA,
  },
});
