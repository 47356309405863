import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const goAround = new MemoryItem({
  title: "Go Around",
  userInfo,
  data: [
    {
      data: ["Go Around"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `In the event of a Go-Around I will press the TOGA button and call “GO-AROUND, FLAP”`,
        `I will select Flap 2(4).

When established in a definite climb I will call: “FLAP 2(4) SELECTED, POSITIVE RATE”
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `I will call "GEAR UP, SET SPEED VGA".`,
        `I will select the Gear Up, verify or select Speed MAN, set speed to VGA.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Above 400 ft AGL I will verify or call for the appropriate lateral mode and if required, select the Autopilot ON.`,
        `I will verify or select the appropriate lateral mode.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `At acceleration altitude, I will:
- select Speed FMS or as required(1),
- FLC
- and call for flap retraction on schedule. `,
        `I will retract the flaps on schedule`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `When flaps are 0, I will call “AFTER GO_AROUND CHECKLIST”`,
        `I will complete the after-go-around checklist.`,
      ],
    },
  ],
});
