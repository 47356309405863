import { Flex } from "@chakra-ui/layout";
import { produce } from "immer";
import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { Folder, ItemType } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { TFolderContents } from "../../typesv2";
import { getFolderFromSideNavPath } from "../../utils";
import { Item } from "./Item";

interface ItemsProps {
  contentIds: string[];
  contents: TFolderContents;
  parentFolderId: string;
}

export const Items: React.FC<ItemsProps> = ({
  contents,
  parentFolderId,
  contentIds,
}) => {
  let { sideNavPath } = useAppState();
  let itemsArray = contentIds.map((key) => contents[key]);

  let { rootFolder, saveRootFolderToFirebase, setRootFolder } = useData();

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let nextState = produce(rootFolder, (draftState) => {
      let parentFolder = getFolderFromSideNavPath(
        draftState,
        sideNavPath
      ) as Folder;

      parentFolder.contentIds.splice(source.index, 1);
      parentFolder.contentIds.splice(destination.index, 0, draggableId);
    });

    setRootFolder(nextState);
    saveRootFolderToFirebase(nextState);
  };

  return (
    <DragDropContext key={parentFolderId} onDragEnd={onDragEnd}>
      <Droppable droppableId={parentFolderId}>
        {(provided) => {
          return (
            <Flex
              {...provided.droppableProps}
              ref={provided.innerRef}
              direction="column"
            >
              {itemsArray.map((item, i) => (
                <Item key={item.id} item={item as ItemType} index={i} />
              ))}
              {provided.placeholder}
            </Flex>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
