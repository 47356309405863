import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PublicFolder } from "../classes";
import { PublicFolders } from "../components/publicFolders/PublicFolders";
import { SearchProjectsForm } from "../components/publicFolders/SearchProjectsForm";
import { useAuth } from "../providers/AuthProvider";
import { useFirebase } from "../providers/FirebaseProvider";

interface SharedFoldersProps {}

export const PublicFoldersPage: React.FC<SharedFoldersProps> = () => {
  const { userInfo } = useAuth();
  const history = useHistory();

  if (!userInfo) {
    history.push("/");
  }

  return (
    <Flex direction="column">
      <Flex ml={2} direction="column">
        <Projects />
      </Flex>
    </Flex>
  );
};

const Projects = () => {
  const { db } = useFirebase();

  const [search, setSearch] = useState({ field: "title", value: "" });
  const [publicFolders, setPublicFolders] = useState<{
    [key: string]: PublicFolder;
  } | null>();
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    //build query
    setLoadingData(true);
    db.collection("publicItems")
      .doc("publicItemsListObject")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPublicFolders(doc.data());
        }
        setLoadingData(false);
      });
  }, [db]);

  let folders = [] as PublicFolder[];

  if (publicFolders) {
    const projectsArrayAll = Object.keys(publicFolders!).map(
      (key) => publicFolders![key] as PublicFolder
    );

    let projectsArrayFound = projectsArrayAll

      .filter((folder) => {
        if (search.value !== "") {
          if (search.field === "username") {
            return folder.userInfo.username.includes(search.value);
          } else if (search.field === "title") {
            return folder.title.includes(search.value);
          } else if (search.field === "email") {
            return folder.userInfo.email.includes(search.value);
          }
        }
        return true;
      })
      .sort((a: any, b: any) => {
        if (a.title > b.title) return 1;
        if (b.title > a.title) return -1;
        return 0;
      });
    folders = projectsArrayFound.slice(0, 10);
  }

  return (
    <>
      <SearchProjectsForm search={search} setSearch={setSearch} />
      <PublicFolders folders={folders} loadingData={loadingData} />
    </>
  );
};
