import { Box, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { SelectControl } from "formik-chakra-ui";
import React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { CRUDAPI } from "../../api/crud";
import { useMyTheme } from "../../hooks/useMyTheme";
import { useAppState } from "../../providers/AppStateProvider";
import { useAuth } from "../../providers/AuthProvider";
import { useData } from "../../providers/DataProvider";
import { InitialValues } from "../../typesv2";
import { Folder } from "../../classes";
import { TextInput } from "../formComponents/TextInput";
import { CancelIconButton } from "../IconButtons";
import { SubmitIcon } from "../SubmitIcon";

const validationSchema = Yup.object({
  title: Yup.string().required(),
  select: Yup.string().required(),
});

interface AddFormProps {
  parentFolder: Folder;
}
export const AddForm: React.FC<AddFormProps> = ({ parentFolder }) => {
  const { rootFolder } = useData();
  const {
    setActiveChecklist,
    setActiveMemoryItem,
    setEditingMemoryItem,
    setActiveNote,
    sideNavPath,
    setAddFolderMode,
  } = useAppState();
  const { userInfo } = useAuth();
  const { saveRootFolderToFirebase } = useData();
  const initialValues: InitialValues = {
    title: "",
    select: "",
  };

  const history = useHistory();

  const inputColor = useMyTheme("textColor");
  const placeholderColor = useMyTheme("placeholderColor");
  const backgroundColor = useMyTheme("background");

  return (
    <Flex
      p={2}
      direction="column"
      backgroundColor={backgroundColor}
      borderRadius="md"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          let { nextState } = CRUDAPI.folderCRUD.addChild(
            values,
            rootFolder,
            sideNavPath,
            userInfo!,
            setActiveChecklist,
            setActiveMemoryItem,
            setEditingMemoryItem,
            setActiveNote,
            history
          );

          saveRootFolderToFirebase(nextState);

          resetForm();

          setAddFolderMode(false);
        }}
      >
        {() => (
          <Form>
            <SelectControl
              name="select"
              selectProps={{
                placeholder: "Create new...",
                _placeholder: { color: placeholderColor },
                color: inputColor,
              }}
            >
              {sideNavPath.length < 4 && <option value="folder">Folder</option>}

              <option value="checklist">Checklist</option>
              <option value="memoryItem">Memory Item</option>
              <option value="note">Note</option>
            </SelectControl>

            <TextInput name="title" placeholder="title..." />

            <Flex alignItems="center" justifyContent="flex-end" my={2}>
              <Box mr={1}>
                <CancelIconButton onClick={() => setAddFolderMode(false)} />
              </Box>
              <SubmitIcon />
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
