import { MemoryItem } from "../../../../classes";

export const rudder = new MemoryItem({
  title: "Rudder Fail",
  order: 0,
  data: [
    {
      data: ["Passenger Evacuation"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`- Select ECL on DU2 and DU3`],
    },
  ],
});
