import React from "react";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
interface HomeButtonProps {}

export const HomeButton: React.FC<HomeButtonProps> = () => {
  return (
    <Button as={RouterLink} to="/">
      Back Home
    </Button>
  );
};
