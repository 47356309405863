import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { AppButton } from "../components/basicComponents/AppButton";
import { MyTextField } from "../components/formComponents/MyTextField";
import { registrationSchema } from "../components/formComponents/validation";
import { Wrapper } from "../components/Wrapper";
import checkPilot from "../images/checkPilot.png";
import { useAuth } from "../providers/AuthProvider";

export const Register: React.FC<{}> = () => {
  const { register } = useAuth();
  const [error, setError] = useState(null);

  const history = useHistory();

  return (
    <Wrapper variant="small">
      <Flex
        w="100%"
        maxW="500px"
        m="auto"
        direction="column"
        justifyContent="center"
        // alignItems="center"
      >
        <Flex w="70%" margin="auto">
          <Image src={checkPilot} />
        </Flex>

        <Formik
          initialValues={{
            username: "",
            email: "",
            password: "",
            password2: "",
          }}
          validationSchema={registrationSchema}
          onSubmit={async (values) => {
            let res = await register(
              values.username,
              values.email,
              values.password
            );
            if (res.success) {
              history.push("/");
            } else {
              setError(res.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex w="100%" direction="column" width="100%">
                <Flex direction="column" w="90%" m="auto" mt={4}>
                  <Box w="100%">
                    <MyTextField
                      name="username"
                      placeholder="username"
                      label="Username"
                    />
                  </Box>
                  <Box mt={4}>
                    <MyTextField
                      name="email"
                      placeholder="email"
                      label="Email"
                    />
                  </Box>
                  <Box mt={4}>
                    <MyTextField
                      name="password"
                      placeholder="password"
                      label="Password"
                      type="password"
                    />
                  </Box>
                  <Box mt={4}>
                    <MyTextField
                      name="password2"
                      placeholder="re-enter password"
                      label="Re-enter Password"
                      type="password"
                    />
                  </Box>

                  <Flex mt={4} justifyContent="flex-end" alignItems="center">
                    <AppButton size="sm" type="submit" text="Register" />
                  </Flex>
                  {error ? <Text>{error}</Text> : null}
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      <Box h="200px" />
    </Wrapper>
  );
};
