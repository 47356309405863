import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";

let route = new Check({
  title: "Route",
  FFOD: false,
  action: "review",
  fullRow: false,
});
let fcpAlt = new Check({
  title: "FCP Altitude",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let togaButton = new Check({
  title: "Toga button",
  FFOD: false,
  action: "Press",
  fullRow: false,
});
let fcpHeading = new Check({
  title: "FCP Heading",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let navVnav = new Check({
  title: "NAV/VNAV",
  FFOD: false,
  action: "press",
  fullRow: false,
  info: "if no coded sid, don't select a lateral mode. hdg only above 400ft.",
});
let status = new Check({
  title: "Aircraft status",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: "MCNEIR - MELs, Construction Codes, Notams, Eicas, Info, Runway condition",
});
let sic = new Check({
  title: "Review Sid",
  FFOD: false,
  action: "review",

  fullRow: false,
});
let noise = new Check({
  title: "Noise",
  FFOD: false,
  action: "review",

  fullRow: false,
});
let eng = new Check({
  title: "Engine out procedure",
  FFOD: false,
  action: "review",

  fullRow: false,
});
let perf = new Check({
  title: "Performace",
  FFOD: false,
  action: "review",
  fullRow: false,
});
let fcp = new Check({
  title: "FCP: spd, hdg, alt, src",
  FFOD: false,
  action: "set",
  fullRow: false,
});
let fma = new Check({
  title: "Read out FMA",
  FFOD: false,
  action: "read",
  fullRow: false,
  info: "TO, VTO; FMS1, VALTS, VFLC armed",
});

let auto = new Check({
  title: "Automation?",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: "get instrument crosscheck",
});
let threats = new Check({
  title: "Threats",
  FFOD: false,
  action: "review",
  fullRow: false,
});
let iwills = new Check({
  title: "I wills",
  FFOD: false,
  action: "recite",
  fullRow: false,
});

export const brief = new Checklist({
  title: "Preflight setup & Breifing",
  userInfo,
  checkIds: [
    route.id,
    fcpAlt.id,
    togaButton.id,
    fcpHeading.id,
    navVnav.id,
    status.id,
    sic.id,
    noise.id,
    eng.id,
    perf.id,
    fcp.id,
    fma.id,
    auto.id,
    threats.id,
    iwills.id,
  ],
  checks: {
    [route.id]: route,
    [fcpAlt.id]: fcpAlt,
    [togaButton.id]: togaButton,
    [fcpHeading.id]: fcpHeading,
    [navVnav.id]: navVnav,
    [status.id]: status,
    [sic.id]: sic,
    [noise.id]: noise,
    [eng.id]: eng,
    [perf.id]: perf,
    [fcp.id]: fcp,
    [fma.id]: fma,
    [auto.id]: auto,
    [threats.id]: threats,
    [iwills.id]: iwills,
  },
});
