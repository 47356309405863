import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Input,
  Text,
  useColorMode,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Folder, PublicFolder } from "../classes";
import { useMyTheme } from "../hooks/useMyTheme";
import { useAppState } from "../providers/AppStateProvider";
import { useAuth } from "../providers/AuthProvider";
import { AppButton } from "./basicComponents/AppButton";
interface FoldersDropdownsProps {
  folders: Folder[] | PublicFolder[];
}

export const FoldersDropdowns: React.FC<FoldersDropdownsProps> = ({
  folders,
}) => {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  return (
    <Flex w="100%">
      <Accordion
        w="100%"
        allowToggle
        backgroundColor={theme.appColors.secondary.background}
        color={theme.appColors.secondary.color}
      >
        {folders.map((folder) => {
          let displayDate = "";

          //@ts-ignore
          if (folder.updatedAt.toDate) {
            //@ts-ignore
            displayDate = folder.updatedAt.toDate().toLocaleDateString();
          } else {
            displayDate = new Date(folder.updatedAt).toLocaleDateString();
          }

          return (
            <AccordionItem key={folder.id} w="100%">
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {folder.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel
                pb={4}
                backgroundColor={
                  colorMode === "dark"
                    ? theme.appColors.dark.background
                    : theme.appColors.light.background
                }
              >
                <Flex w="100%">
                  <VStack
                    flex="1"
                    w="100%"
                    minW="200px"
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    <Text w="100%">Creator: {folder.userInfo.username}</Text>
                    <Text w="100%">Version: {folder.version}</Text>
                    <Text w="100%">Last update: {displayDate}</Text>
                    {/* @ts-ignore */}
                    {!folder.contents && (
                      <Text w="100%">Downloads: {folder.downloads}</Text>
                    )}
                  </VStack>

                  {/* right side */}
                  <RightSide folder={folder} />
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
};

interface RightSideProps {
  folder: Folder | PublicFolder;
}

const RightSide: React.FC<RightSideProps> = ({ folder }) => {
  const {
    setShareId,
    onShareModalOpen,
    setDownloadFolder,
    onDownloadModalOpen,
  } = useAppState();
  const { userInfo } = useAuth();
  const theme = useTheme();
  const [password, setPassword] = useState("");
  const red = useMyTheme("warning");

  const handleShareFolder = (folder: Folder) => {
    setShareId(folder.id);

    setTimeout(() => onShareModalOpen(), 500);
  };

  const handleDownloadClick = (folder: PublicFolder) => {
    setDownloadFolder(folder);
    onDownloadModalOpen();
  };

  const RightSideResult = (
    folder: PublicFolder,
    email: string,
    oldVersion: number | null
  ): JSX.Element => {
    let button = (
      text: string,
      callback: (folder: PublicFolder | Folder) => void
    ) => (
      <AppButton
        ml={4}
        size="xs"
        onClick={() => callback(folder)}
        text={text}
      />
    );

    let text = (text: string) => <Text>{text}</Text>;

    let result = button("Download", () => handleDownloadClick(folder));

    //Possibiliies

    //if it is user page or public page?
    if ((folder as Folder).contents) {
      //does the user own the project?
      if (folder.userInfo.email === userInfo!.email) {
        //user owns
        result = (
          <Flex
            direction={"column"}
            justifyContent="center"
            alignItems="center"
          >
            <Flex>
              <Text mb={2}>Share Status:</Text>
              <Text ml={2} color={theme.appColors.constants.button}>
                {folder.visibility}
              </Text>
            </Flex>
            {button("Share Options", () => handleShareFolder(folder as Folder))}
          </Flex>
        );
      } else {
        //user does not own
        result = text("You don't own this project.");
      }
    } else {
      //public folders page

      // console.log(
      //   "FoldersDropdowns.tsx 166 folder, userInfo:",
      //   folder,
      //   userInfo
      // );
      if (folder.userInfo.email === userInfo!.email) {
        result = (
          <Flex
            direction={"column"}
            justifyContent="center"
            alignItems="center"
          >
            <Flex>
              <Text mb={2}>Share Status:</Text>
              <Text ml={2} color={theme.appColors.constants.button}>
                {folder.visibility}
              </Text>
            </Flex>
            {button("Share Options", () => handleShareFolder(folder as Folder))}
          </Flex>
        );
      }
      //has ths user downloaded this folder before?
      // else
      // if (existingFolder) {
      //   //has downloaded before. Is there a new version?
      //   if (
      //     existingFolder.version !== folder.version &&
      //     existingFolder.id === folder.id
      //   ) {
      //     //there is a new version
      //     if (folder.visibility === "private") {
      //       if (folder.authUsers.includes(userInfo!.email)) {
      //         result = button("New Version", () => handleDownloadClick(folder));
      //       } else {
      //         result = text("Private: Permission Required");
      //       }
      //     } else if (folder.visibility === "password") {
      //       result = (
      //         <Flex direction="column" alignItems="center">
      //           <Input
      //             placeholder="Enter Password"
      //             value={password}
      //             onChange={(e) => setPassword(e.target.value)}
      //             size="sm"
      //           />

      //           {folder.password === password ? (
      //             <Flex w="100%" justifyContent="flex-end" m={2}>
      //               {button("New Version", () => handleDownloadClick(folder))}
      //             </Flex>
      //           ) : (
      //             <Flex w="100%" justifyContent="flex-end" m={2}>
      //               <Text color={red}>Incorrect password</Text>
      //             </Flex>
      //           )}
      //         </Flex>
      //       );
      //     } else {
      //       //folder is public
      //       result = button("New Version", () => handleDownloadClick(folder));
      //     }
      //   } else {
      //     //no new version. nothing new to download.
      //     result = text("Downloaded");
      //   }
      // }
      else {
        //downloading for the first timefolder.
        if (folder.visibility === "private") {
          if (folder.authUsers.includes(userInfo!.email)) {
            result = button("Download", () => handleDownloadClick(folder));
          } else {
            result = text("Private: Permission Required");
          }
        } else if (folder.visibility === "password") {
          result = (
            <Flex direction="column" alignItems="center">
              <Input
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="sm"
              />

              {folder.password === password ? (
                <Flex w="100%" justifyContent="flex-end" m={2}>
                  {button("Download", () => handleDownloadClick(folder))}
                </Flex>
              ) : (
                <Flex w="100%" justifyContent="flex-end" m={2}>
                  <Text color={red}>Incorrect password</Text>
                </Flex>
              )}
            </Flex>
          );
        } else {
          //folder is public
          result = button("Download", () => handleDownloadClick(folder));
        }
      }

      // if (folder.visibility === "public") {
      //   if (oldVersion && folder.version !== oldVersion) {
      //     result = button("New Version", () => handleDownloadClick(folder));
      //   } else if (!oldVersion) {
      //     let existingFolder;
      //     result = button("Download", () => handleDownloadClick(folder));
      //   } else {
      //     result = text("Downloaded");
      //   }
      // } else if (folder.visibility === "password") {
      //   result = (
      //     <Flex>
      //       <Text>password required</Text>
      //     </Flex>
      //   );
      // } else if (folder.visibility === "private") {
      //   if (folder.authUsers.includes(email)) {
      //     if (oldVersion && folder.version !== oldVersion) {
      //       result = button("New Version", () => handleDownloadClick(folder));
      //       // result = <DownloadButton text="New Version" />
      //     } else if (!oldVersion) {
      //       result = button("Download", () => handleDownloadClick(folder));
      //     } else {
      //       result = text("Downloaded");
      //     }
      //   } else {
      //     result = text("Private: Permission Required");
      //   }
      // }
    }

    // //Folder email = use email, no download
    // if (folder.userInfo.email === userInfo!.email) {
    //   result = button("Share Options", () =>
    //     handleShareFolder(folder as Folder)
    //   );
    // }
    // //@ts-ignore
    // if (folder.userInfo.email !== userInfo!.email && folder.contents) {
    //   result = text("You don't own this project.");
    // }

    //folder visibility is private:
    // if (folder.visibility === "private") {
    //   //user is listed in authusers
    //   if (folder.authUsers.includes(email)) {
    //     if (oldVersion && folder.version !== oldVersion) {
    //       result = button("New Version", () => handleDownloadClick(folder));
    //       // result = <DownloadButton text="New Version" />
    //     } else if (!oldVersion) {
    //       result = button("Download", () => handleDownloadClick(folder));
    //     } else {
    //       result = text("Downloaded");
    //     }
    //   } else {
    //     result = text("Private: Permission Required");
    //   }
    // } else {
    //   //public project
    //   if (oldVersion && folder.version !== oldVersion) {
    //     result = button("New Version", () => handleDownloadClick(folder));
    //   } else if (!oldVersion) {
    //     result = button("Download", () => handleDownloadClick(folder));
    //   } else {
    //     result = text("Downloaded");
    //   }
    // }

    return result;
  };
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      borderLeft="1px"
      pl={2}
    >
      <Flex justifyContent="flex-end" alignItems="center">
        {RightSideResult(folder, userInfo!.email, folder.version)}
      </Flex>
      {/* {folder.userInfo.email === userInfo!.email ? (
        <Button onClick={() => handleShareProject(folder.id)}>
          Share Options
        </Button>
      ) : (
        <Text textAlign="center">You do not own this project.</Text>
      )} */}
    </Flex>
  );
};
