import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const tcasTa = new MemoryItem({
  title: "TCAS TA",
  userInfo,
  data: [
    {
      data: ["TCAS TA"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Attempt to acquire target visually using TCAS traffic display as a guide and reduce range.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`- Maneuver if required`, `- Turn on all exterior lights`],
    },
  ],
});
