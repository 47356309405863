import { Box, Flex, Image, Link, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { AppButton } from "../components/basicComponents/AppButton";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import checkPilotBeer from "../images/checkPilotBeer.png";
interface AccountProps {}

export const Account: React.FC<AccountProps> = () => {
  const { currentUser } = useAuth();

  const theme = useTheme();
  return (
    <Flex w="100%" direction="column">
      <Text fontSize="xl" w="100%" p={2} textAlign="center">
        Account
      </Text>

      <Text w="100%" textAlign="center">
        {currentUser!.email}
      </Text>

      <Flex p={4} direction="column">
        <Flex w="100%" direction="column" borderBottom="1px" pb={4}>
          <AppButton
            my={4}
            as={RouterLink}
            to="/my-shared-projects"
            text="Manage shared folders"
          />
        </Flex>

        <AppButton
          my={4}
          as={RouterLink}
          to="/change-password"
          text="Change Password"
        />
        <AppButton as={RouterLink} to="/update-email" text="Update Email" />

        <Flex
          borderTop="1px"
          mt={8}
          pt={4}
          w="100%"
          direction={["column", "column", "column", "row"]}
          border="1px"
          borderRadius="md"
          p={4}
        >
          <Image src={checkPilotBeer} w={["100%", "100%", "100%", "30%"]} />
          <Flex alignItems="center" direction="column" justifyContent="center">
            <Text fontSize="xl" fontWeight="bold" textAlign="center">
              I'd like to hear from you!
            </Text>
            <Text my={2} textAlign="center">
              For support or if you have ideas for new features please contact:
              admin@mycheckpilot.com
            </Text>
            <Text textAlign="center">
              If you have found this application useful, please considering{" "}
              <Link
                fontSize="lg"
                textDecoration="underline"
                color={theme.appColors.constants.button}
                href="https://buy.stripe.com/bIYdUj0FC1yY6Va288"
                isExternal
              >
                buying your check pilot a beer!
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box h="200px" />
    </Flex>
  );
};
