import { Flex, Text, useTheme } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaCaretDown, FaCaretRight, FaCheckCircle } from "react-icons/fa";
import { Folder } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { buildBreadcrumbsFromSideNavPath } from "../../utils";
import { CancelIconButton } from "../IconButtons";

export const SaveTo: React.FC = () => {
  const { rootFolder } = useData();
  const {
    sideNavPath,
    selectedSavePathId,
    savePath,
    setSavePath,
    setSelectedSavePathId,
  } = useAppState();

  const [openPath, setOpenPath] = useState(false);

  const theme = useTheme();
  let breadcrumbs: any[] = [];

  if (rootFolder && selectedSavePathId === "") {
    breadcrumbs = buildBreadcrumbsFromSideNavPath(rootFolder, sideNavPath);
  } else {
    breadcrumbs = buildBreadcrumbsFromSideNavPath(rootFolder, savePath);
  }

  const handleCancel = () => {
    setOpenPath(false);
    setSelectedSavePathId("");
    setSavePath([]);
  };
  return (
    <Flex mt={2} alignItems="center" w="100%" direction="column">
      <Flex
        alignItems="center"
        w="100%"
        direction={["column", "column", "row"]}
      >
        <Text minW="120px">Save/Copy to: </Text>
        {!openPath ? (
          <Flex
            w="100%"
            p={2}
            borderRadius="md"
            border="1px"
            backgroundColor={theme.appColors.constants.button}
            color="black"
            borderColor="green.300"
            cursor="pointer"
            direction={["column", "row"]}
            onClick={() => setOpenPath(!openPath)}
          >
            {breadcrumbs.map((breadcrumb, i) => {
              return (
                <Flex
                  key={i + breadcrumb}
                  color={theme.appColors.secondary.color}
                >
                  <Text
                    color="black"
                    isTruncated
                    overflow="hidden"
                    maxW="100px"
                    ml={[i * 4, 0]}
                    // isTruncated
                    // maxW={["100px", "80px", "500px"]}
                  >
                    {breadcrumb}
                  </Text>
                  <Text color="black" mx={[0, 1, 1, 2]}>
                    /
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Flex
            w="100%"
            mt={2}
            p={2}
            borderRadius="md"
            border="1px"
            borderColor={theme.appColors.constants.button}
            direction="column"
          >
            <Flex>
              <Text flex="1">Select a folder to save/copy to:</Text>
              <CancelIconButton onClick={handleCancel} />
            </Flex>
            {/* <Path setOpenPath={setOpenPath} /> */}
            <SavePathItems folder={rootFolder} index={0} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface ISavePathItems {
  folder: Folder;
  index: number;
}

let SavePathItems: React.FC<ISavePathItems> = ({ folder, index }) => {
  let { savePath, setSavePath, setSelectedSavePathId, selectedSavePathId } =
    useAppState();
  let childFolders = Object.keys(folder.contents!)
    .map((key) => folder.contents![key])
    .filter((x) => x.typeInfo === "folder") as Folder[];
  let { title, id } = folder;

  const handleClick = (id: string) => {
    setSelectedSavePathId(id);
    if (savePath.includes(id)) {
      setSavePath(savePath.slice(0, index));
    } else {
      let slice = savePath.slice(0, index);
      setSavePath([...slice, id]);
    }
  };

  return (
    <Flex direction="column" ml={index * 3}>
      <Flex alignItems="center">
        {savePath.includes(id) ? <FaCaretDown /> : <FaCaretRight />}
        <Text ml={2} onClick={() => handleClick(id)}>
          {title}
        </Text>
        {selectedSavePathId === id && (
          <Flex ml={2} color="green.500">
            <FaCheckCircle />
          </Flex>
        )}
      </Flex>
      {savePath.includes(id) &&
        childFolders.map((folder, i) => (
          <Flex key={folder.id}>
            <SavePathItems folder={folder} index={index + 1} />
          </Flex>
        ))}
    </Flex>
  );
};
