import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const goAroundSE = new MemoryItem({
  title: "Go Around - Single Engine",
  userInfo,
  data: [
    {
      data: ["Go Around - Single Engine"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["PF", "PM"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Select Toga
        "Go Around, Flap"
        `,
        `
        
        Select Flap 2(4).
        
        “FLAP 2(4) SELECTED, POSITIVE RATE”
      `,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `"GEAR UP, SET SPEED Vac".`,
        `Select the Gear Up, verify or select Speed MAN, set speed to Vac.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `Above 400 ft, verify or call for the appropriate lateral mode and if required, select the Autopilot ON.`,
        `Verify or select the appropriate lateral mode.`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `At acceleration altitude, I will:
- select Speed FMS or as required(1),
- FLC
- and call for flap retraction on schedule. `,
        `I will retract the flaps on schedule`,
      ],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `When flaps are 0, I will call “AFTER GO_AROUND CHECKLIST”`,
        `I will complete the after-go-around checklist.`,
      ],
    },
  ],
});
