import { produce } from "immer";
import React, { useContext, useEffect, useState } from "react";
import { Folder, PublicFolder } from "../classes";
import { findItemById } from "../utils";
import { useAuth } from "./AuthProvider";
import { useFirebase } from "./FirebaseProvider";

interface IDataContext {
  loadingData: boolean;
  updateProjectDownloads: (projectId: string) => void;
  saveRootFolderToFirebase: (rootFolder: Folder) => void;
  firestoreAddToPublicItems: (
    item: Folder,
    nextLocalRootFolderState: Folder
  ) => void;
  firestoreDeleteFromPublicItems: (item: Folder) => void;
  firestoreDownloadFolder: (folderId: string, parentFolderId: string) => void;
  rootFolder: Folder;
  setRootFolder: React.Dispatch<React.SetStateAction<Folder>>;
}

const DataContext = React.createContext<IDataContext>({} as IDataContext);
export const useData = () => useContext(DataContext);

export const DataProvider: React.FC = ({ children }) => {
  const [rootFolder, setRootFolder] = useState<Folder>({} as Folder);
  // console.log("DataProvider.tsx 24 rootFolder:", rootFolder);

  const [loadingData, setLoadingData] = useState(false);
  const { userInfo } = useAuth();
  const { db } = useFirebase();

  // Get User's rootFolder
  useEffect(() => {
    setLoadingData(true);

    if (!userInfo) {
      setRootFolder({} as Folder);
      setLoadingData(false);
    } else {
      const userRef = db.collection("users").doc(userInfo.uid);
      userRef
        .collection("rootFolder")
        .doc("rootFolder")
        .onSnapshot((doc) => {
          if (doc.exists) {
            let res = doc.data() as Folder;

            setRootFolder(res);
          }
        });

      setLoadingData(false);
    }
  }, [userInfo, db]);

  const saveRootFolderToFirebase = (rootFolder: Folder) => {
    if (!userInfo) return;

    const { uid } = userInfo;
    const userRef = db.collection("users").doc(uid);
    const docRef = userRef.collection("rootFolder").doc("rootFolder");
    docRef
      .set(rootFolder)
      .catch((err) => console.log("DataProvider.tsx 57 err:", err));
  };

  const firestoreDownloadFolder = (
    folderId: string,
    parentFolderId: string
  ) => {
    if (!userInfo) return;
    const { uid } = userInfo;

    const userRef = db.collection("users").doc(uid);
    const docRef = db.collection("publicItems").doc(folderId);

    //get the real folder from public items
    docRef.get().then((doc) => {
      if (doc.exists) {
        //real folder to add
        let folderToAdd = doc.data() as Folder;

        //update the downloads
        updateProjectDownloads(folderId);

        const nextState = produce(rootFolder, (draftState) => {
          const parentFolder = findItemById(
            draftState,
            parentFolderId
          ) as Folder;

          parentFolder.contents[folderId] = folderToAdd;
          if (!parentFolder.contentIds.includes(folderToAdd.id)) {
            parentFolder.contentIds.push(folderToAdd.id);
          }
        });

        userRef.collection("rootFolder").doc("rootFolder").set(nextState);
      }
    });
  };

  const firestoreAddToPublicItems = (
    item: Folder,
    nextLocalRootFolderState: Folder
  ) => {
    if (!userInfo) return;

    const publicItemsRef = db.collection("publicItems");
    const publicItemsListRef = publicItemsRef.doc("publicItemsListObject");

    publicItemsListRef.get().then((doc) => {
      let downloads = 0;

      let newPublicFolder: PublicFolder = {
        id: item.id,
        title: item.title,
        version: item.version,
        userInfo: {
          uid: item.userInfo.uid,
          username: item.userInfo.username,
          email: item.userInfo.email,
        },
        updatedAt: new Date(),
        downloads,
        visibility: item.visibility,
        authUsers: item.authUsers,
        typeInfo: item.typeInfo,
        password: item.password,
      };

      //does the item already exist? if it does, get the downloads and update:
      if (doc.exists) {
        let publicItemsList = doc.data() as { [key: string]: PublicFolder };

        if (Object.keys(publicItemsList).includes(item.id)) {
          downloads = publicItemsList[item.id].downloads;
        }

        let nextItemState = produce(item, (draftState) => {
          draftState.downloads = downloads;
        });
        //add to public projects
        publicItemsRef.doc(item.id).set(nextItemState);

        //add to public projects list

        let newList: { [key: string]: PublicFolder } = {
          ...publicItemsList,
          [item.id]: newPublicFolder,
        };
        publicItemsListRef.set(newList);

        //update local folder with downloads
        let nextLocalRootFolderStateWithDownloads = produce(
          nextLocalRootFolderState,
          (draftState) => {
            let parentFolder = findItemById(draftState, item.id);

            parentFolder!.downloads = downloads;
          }
        );

        saveRootFolderToFirebase(nextLocalRootFolderStateWithDownloads);
        setRootFolder(nextLocalRootFolderStateWithDownloads);
      } else {
        let newList: { [key: string]: PublicFolder } = {
          [item.id]: newPublicFolder,
        };
        publicItemsListRef.set(newList);
        publicItemsRef.doc(item.id).set(item);
      }
    });
  };

  const firestoreDeleteFromPublicItems = (item: Folder) => {
    if (!userInfo) return;

    const colRef = db.collection("publicItems");

    colRef.doc(item.id).delete();

    const docRef = colRef.doc("publicItemsListObject");
    docRef.get().then((doc) => {
      let oldPublicProjectsListObject: any = {};
      if (doc.exists) {
        oldPublicProjectsListObject = doc.data();
      }

      delete oldPublicProjectsListObject[item.id];

      docRef.set(oldPublicProjectsListObject);
    });
  };

  const updateProjectDownloads = (folderId: string) => {
    const sharedFoldersRef = db.collection("publicItems");
    const publicItemsListRef = sharedFoldersRef.doc("publicItemsListObject");

    publicItemsListRef.get().then((doc) => {
      if (doc.exists) {
        let oldDoc = doc.data() as { [key: string]: PublicFolder };
        let nextState = produce(oldDoc, (draftState) => {
          draftState[folderId].downloads = draftState[folderId].downloads + 1;
        });
        publicItemsListRef.set(nextState);
      }
    });
  };

  const value: IDataContext = {
    rootFolder,
    setRootFolder,
    loadingData,
    saveRootFolderToFirebase,
    firestoreAddToPublicItems,
    firestoreDeleteFromPublicItems,
    firestoreDownloadFolder,
    updateProjectDownloads,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
