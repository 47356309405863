import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let logbook = new Check({
  title: "Logbook",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let fuel = new Check({
  title: "Fuel: ACARS, recorded, used, slip",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let dg = new Check({
  title: "Dangerous goods",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let apu = new Check({
  title: "APU",
  FFOD: false,
  action: "ON",
  fullRow: false,
});
let finals = new Check({
  title: "Finals",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let incharge = new Check({
  title: "In-charge briefed",
  FFOD: false,
  action: "brief",

  fullRow: false,
});
let gendecs = new Check({
  title: "Gen Decs",
  FFOD: false,
  action: "on board",
  fullRow: false,
});

let oil = new Check({
  title: "Oil temps > -40",
  FFOD: false,
  action: "check",
  fullRow: false,
  info: "get oil temp limits",
});
let emergencies = new Check({
  title: "Emergencies Briefed",
  FFOD: false,
  action: "brief",
  fullRow: false,
});
let visibility = new Check({
  title: "Check RVR's for push back during low vis",
  FFOD: false,
  action: "RTO",
  fullRow: false,
  info: "get required RVR's",
});
let rudder = new Check({
  title: "Rudder test",
  FFOD: true,
  action: "acomplish",
  fullRow: false,
});

export const readyToClose = new Checklist({
  title: "Ready to Close",
  userInfo,
  checkIds: [
    logbook.id,
    fuel.id,
    dg.id,
    apu.id,
    finals.id,
    incharge.id,
    gendecs.id,
    oil.id,
    emergencies.id,
    visibility.id,
    rudder.id,
  ],
  checks: {
    [logbook.id]: logbook,
    [fuel.id]: fuel,
    [dg.id]: dg,
    [apu.id]: apu,
    [finals.id]: finals,
    [incharge.id]: incharge,
    [gendecs.id]: gendecs,
    [oil.id]: oil,
    [emergencies.id]: emergencies,
    [visibility.id]: visibility,
    [rudder.id]: rudder,
  },
});
