import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { Folder } from "../../classes";
import { useAuth } from "../../providers/AuthProvider";
import { useData } from "../../providers/DataProvider";
import { findItemById, removeElFromArray } from "../../utils";
import {
  BackIconButton,
  ForwardIconButton,
  SearchIconButton,
  TrashIconButton,
} from "../IconButtons";

export const AuthUsers: React.FC<{ folder: Folder }> = ({ folder }) => {
  const { userInfo } = useAuth();
  const { rootFolder, setRootFolder } = useData();

  // const { rootFolder, fireStoreAddToPublicProjects, firestoreAddOrEditProject } =
  // useData();

  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [filterAuthUsers, setFilterAuthUsers] = useState(false);

  const authUsers = folder.authUsers;

  let limit = 10;
  let hasMore = false;

  let users = [...authUsers]
    .sort()
    .filter((userEmail) => userEmail.indexOf(filter) >= 0);

  const [cursor, setCursor] = useState<string | null>(
    users[(page - 1) * limit]
  );
  // setCursor(users[(page - 1) * limit]);

  let index = users.indexOf(cursor!);

  let displayUsers = users.slice((page - 1) * limit, page * limit);

  useEffect(() => {
    setCursor(displayUsers[(page - 1) * limit]);
  }, [displayUsers, limit, page]);

  if (users.length > page * index + limit) {
    hasMore = true;
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  let totalUsers = authUsers.length;
  let totalPages = Math.ceil(totalUsers / limit);

  const handleDelete = (user: string) => {
    let nextState = produce(rootFolder!, (draftState) => {
      let item = findItemById(draftState, folder.id);

      item!.authUsers = removeElFromArray(authUsers, user);
    });

    setRootFolder(nextState);
    setCursor(users[index + limit]);
  };

  const handlePageUp = () => {
    if (hasMore) {
      setPage(page + 1);
      setCursor(users[index + limit]);
    }
  };

  const handlePageDown = () => {
    if (page > 1) {
      setPage(page - 1);
      setCursor(users[index - limit]);
    }
  };

  return (
    <Flex direction="column">
      <Flex
        borderBottom="1px"
        pb={2}
        my={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        {!filterAuthUsers && <Text w="100%">Current Users:</Text>}
        {filterAuthUsers && (
          <Flex alignItems="center" flex={1}>
            <Text mr={2}> Email:</Text>
            <Input onChange={(e) => onChange(e)} />
          </Flex>
        )}

        <Box mx={2}>
          <SearchIconButton
            onClick={() => setFilterAuthUsers(!filterAuthUsers)}
          />
        </Box>

        <BackIconButton onClick={() => handlePageDown()} />
        <Text textAlign="center" minW="100px" mx={2}>
          Page {page} of {totalPages}
        </Text>
        <ForwardIconButton onClick={() => handlePageUp()} />
      </Flex>

      {displayUsers.length < 1 ? (
        <Text>
          No authorized users except for you! Add users or reset your filter.
        </Text>
      ) : (
        <Flex direction="column">
          {displayUsers.map((userEmail) => (
            <Flex mb={1} ml={2} key={userEmail} alignItems="center">
              <Text flex="1">{userEmail}</Text>
              {userInfo!.email !== userEmail && (
                <TrashIconButton onClick={() => handleDelete(userEmail)} />
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
