import * as Yup from "yup";

const order = Yup.number()
  .typeError("Must be a number.")
  .positive("Must be a positive number.")
  .required("Order must be a number between 1-99")
  .min(1)
  .max(99);

export const addEditCheckFormSchema = Yup.object().shape({
  checkTitle: Yup.string().required().max(50).label("Check Title"),
  order,
});

export const addEditFolderSchema = Yup.object().shape({
  newTitle: Yup.string().required().max(50).label("Folder Title"),
  order,
});

export const addEditNoteSchema = Yup.object().shape({
  noteTitle: Yup.string().required().max(50).label("Check Title"),
  order,
  note: Yup.string().required().label("Note Field"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

export const emailSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

export const registrationSchema = Yup.object().shape({
  username: Yup.string().required().min(2).label("Username"),
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(6).label("Password"),
  password2: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string().required().min(6).label("Password"),
  password2: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const newProjectFormSchema = Yup.object().shape({
  name: Yup.string().required(),
});
export const newChecklistFolderFormSchema = Yup.object().shape({
  checklistFolderTitle: Yup.string().required(),
});

const editNoteForm = Yup.object().shape({
  title: Yup.string().required(),
  note: Yup.string().required(),
});

const checkFormSchema = Yup.object().shape({
  title: Yup.string().required(),
});

const editFolder = Yup.object().shape({
  title: Yup.string().required(),
});

const addUserForm = Yup.object().shape({
  user: Yup.string().required(),
});

export const validationSchemas = {
  editFolder,
  projectFolderNavObject: newProjectFormSchema,
  editNoteForm,
  checkFormSchema,
  addUserForm,
  emailSchema,
  passwordSchema,
};
