import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { PublicFolder } from "../../classes";
import { FoldersDropdowns } from "../FoldersDropdowns";
import { HomeButton } from "../HomeButton";
import { LoadingSpinner } from "../LoadingSpinner";
interface PublicFoldersProps {
  folders: PublicFolder[];
  loadingData: boolean;
}

export const PublicFolders: React.FC<PublicFoldersProps> = ({
  folders,
  loadingData,
}) => {
  return (
    <Flex direction="column" w="100%">
      {folders.length === 0 && <Text>No public projects</Text>}

      {folders ? (
        <>
          <Text fontSize="lg" fontWeight="bold">
            Availble projects:
          </Text>
          <FoldersDropdowns folders={folders} />
        </>
      ) : (
        // )
        <LoadingSpinner loading={loadingData} />
      )}

      <Flex mt={4}>
        <HomeButton />
      </Flex>
    </Flex>
  );
};
