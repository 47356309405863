import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let acars = new Check({
  title: "ACARS Init",
  FFOD: false,
  action: "Initialize",
  fullRow: false,
});
let atis = new Check({
  title: "ATIS",
  FFOD: false,
  action: "Obtain",

  fullRow: false,
});
let flightNumber = new Check({
  title: "Flight Number",
  FFOD: false,
  action: "Enter",
  info: "To be entered on CTP (or through CNS page)",
  fullRow: false,
});
let db = new Check({
  title: "Databases",
  FFOD: false,
  action: "Cycle & check",
  fullRow: false,
});
let fpln = new Check({
  title: "Flight plan uplink",
  FFOD: false,
  action: "Request",
  info: `ACAXXX
         Note: don't change FMS source while flight plan is uploading
         could take 2-5 minutes
  `,
  fullRow: false,
});
let toWAT = new Check({
  title: "Take-off WAT",
  FFOD: false,
  action: "Obtain",
  fullRow: false,
  info: `
  Cowl anti-ice: On when <= 10C and visible moisture below 1500ft. Or contaminated taxiways.
  Wing anti-ice: On for take-off when <= 5C and visible moisture below 1500ft. Or contaminated taxiways.

  if WAT not functioning -> use PERF tool in flightbrief or contact dispatch

  Limitations:
  - flex can be used on wet runways.
  - flex must not be used in windshear, Anti-skid U/S, no autothrottle, anti-ice ON, 
  only in TO (no derate) and no contaminated ops.
  - Derated thrust not permitted on contam runways, APR DISARM. 
  `,
});
let verify = new Check({
  title: "Independent Verification",
  FFOD: true,
  action: "accomplish",
  fullRow: false,
});

export const fmsInit = new Checklist({
  title: "FMS setup",
  userInfo,
  checkIds: [
    acars.id,
    atis.id,
    flightNumber.id,
    db.id,
    fpln.id,
    toWAT.id,
    verify.id,
  ],
  checks: {
    [acars.id]: acars,
    [atis.id]: atis,
    [flightNumber.id]: flightNumber,
    [db.id]: db,
    [fpln.id]: fpln,
    [toWAT.id]: toWAT,
    [verify.id]: verify,
  },
});
