import { Box, Flex, Text, useColorMode, useTheme } from "@chakra-ui/react";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CRUDAPI } from "../../api/crud";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { Check } from "../../classes";
import {
  EditIconButton,
  InfoIconButton,
  MoveIconButton,
  TrashIconButton,
} from "../IconButtons";
import { CheckForm } from "./CheckForm";

interface EditCheckProps {
  check: Check;
  index: number;
  // parentLength: number;
}

export const EditCheckComponent: React.FC<EditCheckProps> = ({
  check,
  index,
  // parentLength,
}) => {
  const {
    showEditCheckForm,
    setShowEditCheckForm,
    setShowAddCheckForm,
    sideNavPath,
    activeChecklist,
    setActiveChecklist,
    FFOD,
  } = useAppState();
  const { rootFolder, saveRootFolderToFirebase } = useData();
  const theme = useTheme();
  const { colorMode } = useColorMode();

  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenInfo(!openInfo);
  };

  const handleEditClick = (checkId: string) => {
    if (showEditCheckForm) {
      setShowEditCheckForm("");
    } else {
      setShowEditCheckForm(checkId);
    }
    setShowAddCheckForm(false);
  };

  const handleDeleteClick = (check: Check) => {
    let { nextState, nextActiveChecklist } = CRUDAPI["checkCRUD"].delete(
      check,
      rootFolder!,
      sideNavPath,
      activeChecklist!,
      FFOD
    );
    saveRootFolderToFirebase(nextState);
    setActiveChecklist(nextActiveChecklist);
  };

  const handleAnchor = () => {};

  return (
    <Flex direction="column" w="100%">
      <Draggable draggableId={check.id} index={index}>
        {(provided) => {
          return (
            <>
              <Flex
                my={2}
                p={2}
                alignItems="center"
                w="100%"
                bgColor="gray.600"
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <Box {...provided.dragHandleProps} mr={2}>
                  <MoveIconButton onClick={handleAnchor} />
                </Box>
                <Box mr={2}>
                  <EditIconButton onClick={() => handleEditClick(check.id)} />
                </Box>
                <Box>
                  <TrashIconButton onClick={() => handleDeleteClick(check)} />
                </Box>

                {check.fullRow ? (
                  <Flex
                    w="100%"
                    p={1}
                    borderRadius="sm"
                    backgroundColor={
                      colorMode === "dark"
                        ? theme.appColors.dark.background
                        : theme.appColors.light.background
                    }
                  >
                    <Text w="100%" textAlign="center">
                      {check.title}
                    </Text>
                  </Flex>
                ) : (
                  <>
                    <Text
                      mx={3}
                      fontWeight="bold"
                      textAlign={check.fullRow ? "center" : undefined}
                    >
                      {check.title}
                    </Text>
                    {check.info ? (
                      <InfoIconButton onClick={(e: any) => handleOpenInfo(e)} />
                    ) : null}
                    {check.action ? (
                      <Text
                        flex="1"
                        textAlign="right"
                        fontSize="sm"
                        color="black"
                      >
                        {check.action}
                      </Text>
                    ) : null}
                  </>
                )}
              </Flex>
              {openInfo ? (
                <Flex>
                  <Text>{check.info}</Text>
                </Flex>
              ) : null}
            </>
          );
        }}
      </Draggable>

      {showEditCheckForm === check.id ? (
        <CheckForm check={check} mode="edit" />
      ) : null}
    </Flex>
  );
};
