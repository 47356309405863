import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const engine = new Note({
  note: `
  Engine warm up:

  Oil temperature             Max N1
  Below -40                   start prohibited
  Below -6                    Idle
  Below 21                    30%
  Below 49                    50%
  Above 49                    No Limit
  
  Max TO thrust 5min (10min single engine)

  3 starts or 4 min continuous cranking? 30 min cooldown.
  `,
  title: "Engine",
  userInfo,
});
