import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Account } from "../pages/Account";
import { ChangePassword } from "../pages/ChangePassword";
import { ChecklistComponent } from "../pages/Checklist";
import { HomePage } from "../pages/HomePage";
import { Login } from "../pages/login";
import { MemoryItemComponent } from "../pages/MemoryItem";
import { MySharedProjects } from "../pages/MySharedProjects";
import { NoteComponent } from "../pages/Note";
import { PublicFoldersPage } from "../pages/PublicFoldersPage";
import { Register } from "../pages/register";
import { Tutorial } from "../pages/Tutorial";
import { UpdateEmail } from "../pages/UpdateEmail";
import { useAppState } from "../providers/AppStateProvider";
import { useAuth } from "../providers/AuthProvider";
import { ResetPassword } from "../pages/ResetPassword";

export const Routes = () => {
  const { activeChecklist, activeMemoryItem, activeNote } = useAppState();
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <CustomRoute path="/login">
        <Login />
      </CustomRoute>
      <CustomRoute path="/register">
        <Register />
      </CustomRoute>
      <CustomRoute path="/reset-password">
        <ResetPassword />
      </CustomRoute>

      <PrivateRoute path="/account">
        <Account />
      </PrivateRoute>
      <PrivateRoute path="/my-shared-projects">
        <MySharedProjects />
      </PrivateRoute>
      <PrivateRoute path="/shared-folders">
        <PublicFoldersPage />
      </PrivateRoute>
      <PrivateRoute path="/checklist">
        {activeChecklist ? (
          <ChecklistComponent />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/note">
        {activeNote ? <NoteComponent /> : <Redirect to={{ pathname: "/" }} />}
      </PrivateRoute>
      <PrivateRoute path="/memoryItem">
        {activeMemoryItem ? (
          <MemoryItemComponent />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/tutorial">
        <Tutorial />
      </PrivateRoute>
      <PrivateRoute path="/change-password">
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute path="/update-email">
        <UpdateEmail />
      </PrivateRoute>

      <Route exact path="*">
        <HomePage />
      </Route>
    </Switch>
  );
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { userInfo, loadingAuth } = useAuth();

  if (!loadingAuth && userInfo) {
    return <Route {...rest}>{children}</Route>;
  }

  if (!loadingAuth && !userInfo) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return <LoadingSpinner loading={loadingAuth} />;
};

const CustomRoute: React.FC<RouteProps> = ({ children, location, ...rest }) => {
  const { userInfo, loadingAuth } = useAuth();

  if (!loadingAuth && userInfo) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (!loadingAuth && !userInfo) {
    return <Route {...rest}>{children}</Route>;
  }

  return <LoadingSpinner loading={true} />;
};
