import React from "react";
import { AuthProvider } from "./AuthProvider";
import { ChakraProvider } from "./ChakraProvider";
import { App } from "../App";
import { AppStateProvider } from "./AppStateProvider";
import { DataProvider } from "./DataProvider";
import { FirebaseProvider } from "./FirebaseProvider";
import { MediaStateProvider } from "./MediaProvider";

export const Providers: React.FC = () => {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <DataProvider>
          <AppStateProvider>
            <ChakraProvider>
              <MediaStateProvider>
                <App />
              </MediaStateProvider>
            </ChakraProvider>
          </AppStateProvider>
        </DataProvider>
      </AuthProvider>
    </FirebaseProvider>
  );
};
