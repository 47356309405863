import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const breakout = new MemoryItem({
  title: "Breakout Procedure",
  userInfo,
  data: [
    {
      data: ["Breakout Procedure"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "medium",
        fontWeight: "bold",
        textAlign: "center",
      },
      data: ["CA", "FO"],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`Set HDG 30 degrees left or right`, ``],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`BREAKOUT`],
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [
        `
- Autopilot off
- Turn to heading
- Initiate climb/descent

- When stable:
- Autopilot ON
- Speed MAN, VFE-5
- (Go-around)”FLAP _”
- Carry-out normal GA.
        `,
        `
- FD off
- Select assigned HDG, ALT
- Select FLC

- Select flap. “positive rate”
        `,
      ],
    },
  ],
});
