import React from "react";
import { IconButton, Tooltip, useTheme } from "@chakra-ui/react";

interface IconProps {
  onClick: any;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  tooltip?: string;
  backgroundColor?: string;
}
export const MyIconButton: React.FC<IconProps> = ({
  onClick,
  icon,
  tooltip,
  backgroundColor,
  ...props
}) => {
  const theme = useTheme();

  let bgc = backgroundColor
    ? backgroundColor
    : theme.appColors.constants.button;

  return (
    <Tooltip label={tooltip} fontSize="sm">
      <IconButton
        {...props}
        _active={{
          backgroundColor: bgc,
        }}
        _hover={{
          backgroundColor: bgc,
        }}
        _focus={{
          backgroundColor: bgc,
        }}
        backgroundColor={bgc}
        color={theme.appColors.constants.buttonColor}
        size="sm"
        onClick={onClick}
        aria-label="Save"
        icon={icon}
      />
    </Tooltip>
  );
};
