import { Box, Flex, Text, useColorMode, useTheme } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import React from "react";
import { CRUDAPI } from "../../api/crud";
import { Check } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
// import { getActiveChecklistFromSideNavPath } from "../../utils";
import { MyCheckbox } from "../formComponents/MyCheckbox";
import { MyTextareaField } from "../formComponents/MyTextarea";
import { MyTextField } from "../formComponents/MyTextField";
import { validationSchemas } from "../formComponents/validation";
import { CancelIconButton } from "../IconButtons";
import { SubmitIcon } from "../SubmitIcon";

interface CheckFormProps {
  check: Check;
  mode: "add" | "edit";
}

export const CheckForm: React.FC<CheckFormProps> = ({ check, mode }) => {
  const { saveRootFolderToFirebase, rootFolder } = useData();
  const {
    setShowAddCheckForm,
    setShowEditCheckForm,
    showAddCheckForm,
    activeChecklist,
    setActiveChecklist,
    FFOD,
  } = useAppState();
  const theme = useTheme();
  const { colorMode } = useColorMode();

  const handleCancel = () => {
    setShowAddCheckForm(false);
    setShowEditCheckForm("");
  };

  const { sideNavPath } = useAppState();

  return (
    <Flex
      p={2}
      w="100%"
      direction="column"
      bgColor={
        colorMode === "dark"
          ? theme.appColors.dark.background
          : theme.appColors.light.background
      }
      border="1px"
      borderRadius="md"
      borderColor={
        colorMode === "dark"
          ? theme.appColors.dark.border
          : theme.appColors.light.border
      }
    >
      <Text fontSize="xl" fontWeight="bold" mb={2}>
        {showAddCheckForm ? "Add Check" : "Edit Check"}
      </Text>
      <Formik
        initialValues={{
          title: check.title,
          FFOD: check.FFOD,
          info: check.info,
          action: check.action,
          fullRow: check.fullRow,
        }}
        validationSchema={validationSchemas.checkFormSchema}
        onSubmit={async (values) => {
          if (mode === "add") {
            let { nextState, nextActiveChecklist } = CRUDAPI[
              "checkCRUD"
            ].create(values, activeChecklist!, rootFolder, sideNavPath, FFOD);
            saveRootFolderToFirebase(cloneDeep(nextState));
            setActiveChecklist(nextActiveChecklist);
          }

          if (mode === "edit") {
            let { nextState, nextActiveChecklist } = CRUDAPI[
              "checkCRUD"
            ].update(
              check,
              values,
              rootFolder,
              sideNavPath,
              activeChecklist!,
              FFOD
            );
            // console.log("CheckForm.tsx 110 nextState:", nextState.children);
            saveRootFolderToFirebase(nextState);
            setActiveChecklist(nextActiveChecklist);
          }

          setShowEditCheckForm("");
          setShowAddCheckForm(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Flex w="100%" direction="column" mb={2}>
              <Flex mb={2} w="100%" alignItems="center">
                <Box mr={4}>
                  <MyCheckbox
                    isDisabled={values.fullRow}
                    name="FFOD"
                    label="FFOD"
                  />
                </Box>
                <Box mr={4}>
                  <MyCheckbox name="fullRow" label="Full row?" />
                </Box>
              </Flex>

              <Flex direction={["column", "column", "column", "row"]} flex={1}>
                <Flex mb={[2, 2, 0]} mr={[0, 0, 0, 4]} direction={"column"}>
                  <Text>Check:</Text>
                  <MyTextField
                    name="title"
                    placeholder={`Check title`}
                    label={`Check title`}
                  />
                </Flex>
                <Flex mb={[2, 2, 0]} direction={"column"}>
                  <Text>Action:</Text>
                  <MyTextField
                    isDisabled={values.fullRow}
                    name="action"
                    placeholder={`Check action`}
                    label={`Check action`}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Text>Info:</Text>
              <MyTextareaField
                isDisabled={values.fullRow}
                name="info"
                label="Check info"
              />
            </Flex>

            <Flex justifyContent="flex-end" mt={2}>
              <Box mr={2}>
                <CancelIconButton onClick={() => handleCancel()} />
              </Box>
              <Box>
                <SubmitIcon />
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
