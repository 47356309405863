import React from "react";
import { InputControl, InputControlProps } from "formik-chakra-ui";
import { useMyTheme } from "../../hooks/useMyTheme";

export const TextInput: React.FC<InputControlProps> = ({
  inputProps,
  name,
  placeholder,
  ...props
}) => {
  const inputColor = useMyTheme("textColor");
  const placeholderColor = useMyTheme("placeholderColor");

  const extendedInputProps = {
    ...inputProps,
    placeholder,
    my: 2,
    color: inputColor,
    _placeholder: { color: placeholderColor },
  };

  return (
    <InputControl
      {...props}
      inputProps={extendedInputProps}
      name={name}
      placeholder={placeholder}
    />
  );
};
