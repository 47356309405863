import { Check, Checklist } from "../../../../../classes";
import { userInfo } from "../../../user";

let lights = new Check({
  title: "Lights - strobe",
  FFOD: false,
  action: "ON",
  fullRow: false,
});
let autothrust = new Check({
  title: "Auto Thrust",
  FFOD: false,
  action: "ARM",

  fullRow: false,
});
let radar = new Check({
  title: "Wx Radar",
  FFOD: false,
  action: "as required",
  fullRow: false,
  info: "how do i turn it on again on the ground?",
});

export const cleared = new Checklist({
  title: "Cleared to Position",
  userInfo,
  checkIds: [lights.id, autothrust.id, radar.id],
  checks: {
    [lights.id]: lights,
    [autothrust.id]: autothrust,
    [radar.id]: radar,
  },
});
