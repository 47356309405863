import { MemoryItem } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const evac = new MemoryItem({
  title: "Passenger Evacuation",
  userInfo,
  data: [
    {
      data: ["Passenger Evacuation"],
      rowStyles: {
        fontWeight: "bold",
        fontSize: "large",
        textAlign: "center",
      },
    },
    {
      rowStyles: {
        fontSize: "small",
        fontWeight: "normal",
        textAlign: "left",
      },
      data: [`- Select ECL from PROC on DU2 and DU3`],
    },
  ],
});
