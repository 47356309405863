import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let review = new Check({
  title: "Review LDP (+0/-1200kg)",
  FFOD: false,
  action: "check",
  fullRow: false,
});
let advise = new Check({
  title: "Advise load control of ADC's",
  FFOD: false,
  action: "advise",

  fullRow: false,
});
let setAir = new Check({
  title: "Set ventilation as indicated on load sheet",
  FFOD: false,
  action: "set",

  fullRow: false,
});

export const prelims = new Checklist({
  title: "Prelims",
  userInfo,
  checkIds: [review.id, advise.id, setAir.id],
  checks: {
    [review.id]: review,
    [advise.id]: advise,
    [setAir.id]: setAir,
  },
});
