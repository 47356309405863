import { Folder } from "../../../classes";
import { userInfo } from "../user";

import { alternateStringsInfo } from "./notes/alternateStringsInfo";
import { cat } from "./notes/cat";
import { cold } from "./notes/cold";
import { CYYZCYEG } from "./notes/CYYZCYEG";
import { emer } from "./notes/emer";
import { engine } from "./notes/engine";
import { fuel } from "./notes/fuel";
import { gen } from "./notes/gen";
import { icing } from "./notes/icing";
import { memoryItems } from "./notes/memoryItems";
import { operational } from "./notes/operational";
import { speeds } from "./notes/speeds";
import { weights } from "./notes/weights";
import { winds } from "./notes/winds";

///////////////////////////////////////////////////////////////////////////////

export const alternateStrings = new Folder({
  userInfo,
  title: "Alternate Strings",
  contentIds: [alternateStringsInfo.id, CYYZCYEG.id],
  contents: {
    [alternateStringsInfo.id]: alternateStringsInfo,
    [CYYZCYEG.id]: CYYZCYEG,
  },
});
export const a220Notes = new Folder({
  userInfo,
  title: "A220 Notes",
  contentIds: [cat.id, emer.id, icing.id, memoryItems.id],
  contents: {
    [cat.id]: cat,
    [emer.id]: emer,
    [icing.id]: icing,
    [memoryItems.id]: memoryItems,
  },
});

export const limitations = new Folder({
  userInfo,
  title: "Limitations",
  contentIds: [
    cold.id,
    engine.id,
    fuel.id,
    gen.id,
    operational.id,
    speeds.id,
    weights.id,
    winds.id,
  ],
  contents: {
    [cold.id]: cold,
    [engine.id]: engine,
    [fuel.id]: fuel,
    [gen.id]: gen,
    [operational.id]: operational,
    [speeds.id]: speeds,
    [weights.id]: weights,
    [winds.id]: winds,
  },
});

///////////////////////////////////////////////////////////////////////////////

export const noteFolders = new Folder({
  userInfo,
  title: "Note Folders",
  contentIds: [alternateStrings.id, a220Notes.id, limitations.id],
  contents: {
    [alternateStrings.id]: alternateStrings,
    [a220Notes.id]: a220Notes,
    [limitations.id]: limitations,
  },
});
