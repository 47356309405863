import { Folder } from "../../classes";
import { userInfo } from "./user";
import { afterStartFlow } from "./afterStart";
import { alternateStringsInfo } from "./alternateStringsInfo";
import { engineFailCruise } from "./engineFailCruise";

const extraFolder = new Folder({
  userInfo,
  title: "Example Sub-Folder!",
  contentIds: [alternateStringsInfo.id],
  contents: {
    [alternateStringsInfo.id]: alternateStringsInfo,
  },
});

const exampleFolder = new Folder({
  userInfo,
  title: "Example Folder",
  contentIds: [
    afterStartFlow.id,
    alternateStringsInfo.id,
    engineFailCruise.id,
    extraFolder.id,
  ],
  contents: {
    [afterStartFlow.id]: afterStartFlow,
    [alternateStringsInfo.id]: alternateStringsInfo,
    [engineFailCruise.id]: engineFailCruise,
    [extraFolder.id]: extraFolder,
  },
});

export { exampleFolder };
