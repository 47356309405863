import { Folder } from "../../classes";
import { userInfo } from "./user";
import { checklistFolders } from "./checklistFolders/checklistFolders";
import { memoryItems } from "./memoryItems/memoryItems";
import { noteFolders } from "./noteFolders/noteFolders";

const ACProjectFolder = new Folder({
  userInfo,
  id: "airCanadaA220id",
  title: "Air Canada A220",
  contentIds: [checklistFolders.id, noteFolders.id, memoryItems.id],
  contents: {
    [checklistFolders.id]: checklistFolders,
    [noteFolders.id]: noteFolders,
    [memoryItems.id]: memoryItems,
  },
});

export { ACProjectFolder };
