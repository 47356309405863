import { Note } from "../../../../../src/classes";
import { userInfo } from "../../user";

export const operational = new Note({
  note: `
  Maximum take-off alternate distance is 351, single engine cruise speed of 310kts

  Fuel burns:

  Engine:
  Taxi (2 eng): 9.1kg/min -> ~500kg/hour
  Cruise: 30kg/min -> 1800kg/hour

  APU:
  Electrics: 90kg/hr
  Electrics and bleed: 115kg/hr
  `,
  title: "Operational",
  userInfo,
});
