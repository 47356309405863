import { Check, Checklist } from "../../../../../../src/classes";
import { userInfo } from "../../../user";
let parkBreak = new Check({
  title: "Parkbreak",
  FFOD: false,
  action: "ON",

  fullRow: false,
});
let exteriorLights = new Check({
  title: "Exterior Lights",
  FFOD: false,
  action: "ON",
  info: "Nav, Logo and wing inspection if req'd",
  fullRow: false,
});
let eicasInfo = new Check({
  title: "Eicas & Info",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let circuitBreakers = new Check({
  title: "Circuit Breakers",
  FFOD: false,
  action: "check",

  fullRow: false,
});
let walkAroundCheck = new Check({
  title: "Walk-around",
  FFOD: false,
  action: "action example",
  info: "Service checks is every 72 hours",
  fullRow: false,
});

export const walkAround = new Checklist({
  title: "Walk Around Check",
  userInfo,
  checkIds: [
    parkBreak.id,
    exteriorLights.id,
    eicasInfo.id,
    circuitBreakers.id,
    walkAroundCheck.id,
  ],
  checks: {
    [parkBreak.id]: parkBreak,
    [exteriorLights.id]: exteriorLights,
    [eicasInfo.id]: eicasInfo,
    [circuitBreakers.id]: circuitBreakers,
    [walkAroundCheck.id]: walkAroundCheck,
  },
});
