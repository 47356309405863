import { Button, Flex, Icon, Text, Tooltip, useTheme } from "@chakra-ui/react";
import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { updateAll } from "../../utils";

interface CheckboxProps {}

export const FFODButton: React.FC<CheckboxProps> = () => {
  const { FFOD, setFFOD } = useAppState();
  const { setRootFolder, rootFolder } = useData();

  const handleffodStateChange = () => {
    setRootFolder(updateAll(rootFolder, !FFOD));
    setFFOD(!FFOD);
  };

  const theme = useTheme();
  return (
    <Tooltip label="First Flight of Day" fontSize="sm">
      <Button
        key="asdfwe"
        size="sm"
        onClick={handleffodStateChange}
        backgroundColor={theme.appColors.constants.button}
        color={theme.appColors.constants.buttonColor}
      >
        <Flex justifyContent="space-around" alignItems="center">
          <Text width="40px" mr={2}>
            FFOD
          </Text>
          {FFOD ? (
            <Icon as={MdCheckBox} h={5} w={5} color={"green"} />
          ) : (
            <Icon as={MdCheckBoxOutlineBlank} h={5} w={5} color={"red"} />
          )}
        </Flex>
      </Button>
    </Tooltip>
  );
};
