import { Folder } from "../../../classes";
import { userInfo } from "../user";

import { ash } from "./memoryItems/ash";
import { battery } from "./memoryItems/battery";
import { breakout } from "./memoryItems/breakout";
import { crewIncap } from "./memoryItems/crewIncap";
import { engineFailCruise } from "./memoryItems/engineFailCruise";
import { evac } from "./memoryItems/evac";
import { goAround } from "./memoryItems/goAround";
import { goAroundSE } from "./memoryItems/goAroundSE";
import { goAroundLE } from "./memoryItems/lowEnergy";
import { noseHigh } from "./memoryItems/noseHigh";
import { noseLow } from "./memoryItems/noseLow";
import { oceanicAll } from "./memoryItems/oceanicAll";
import { oceanicSingle } from "./memoryItems/oceanicSingle";
import { rapidDepres } from "./memoryItems/rapidDepres";
import { rejectedTakeoff } from "./memoryItems/rejectedTakeoff";
import { rudder } from "./memoryItems/rudder";
import { stall } from "./memoryItems/stall";
import { taws } from "./memoryItems/taws";
import { tcasTa } from "./memoryItems/tcasTa";
import { tcasRa } from "./memoryItems/tcasRa";
import { unreliable } from "./memoryItems/unreliable";
import { v1cut } from "./memoryItems/v1cut";
import { windshear } from "./memoryItems/windshear";

///////////////////////////////////////////////////////////////////////////////

export const memoryItems = new Folder({
  userInfo,
  title: "Memory Items",
  contentIds: [
    rejectedTakeoff.id,
    v1cut.id,
    rapidDepres.id,
    goAround.id,
    unreliable.id,
    engineFailCruise.id,
    tcasTa.id,
    tcasRa.id,
    stall.id,
    noseHigh.id,
    noseLow.id,
    taws.id,
    windshear.id,
    ash.id,
    battery.id,
    evac.id,
    breakout.id,
    oceanicAll.id,
    oceanicSingle.id,
    goAroundSE.id,
    goAroundLE.id,
    crewIncap.id,
  ],
  contents: {
    [ash.id]: ash,
    [battery.id]: battery,
    [breakout.id]: breakout,
    [crewIncap.id]: crewIncap,
    [engineFailCruise.id]: engineFailCruise,
    [evac.id]: evac,
    [goAround.id]: goAround,
    [goAroundLE.id]: goAroundLE,
    [goAroundSE.id]: goAroundSE,
    [noseHigh.id]: noseHigh,
    [noseLow.id]: noseLow,
    [oceanicSingle.id]: oceanicSingle,
    [oceanicAll.id]: oceanicAll,
    [rapidDepres.id]: rapidDepres,
    [rudder.id]: rudder,
    [stall.id]: stall,
    [taws.id]: taws,
    [tcasRa.id]: tcasRa,
    [tcasTa.id]: tcasTa,
    [unreliable.id]: unreliable,
    [v1cut.id]: v1cut,
    [windshear.id]: windshear,
    [rejectedTakeoff.id]: rejectedTakeoff,
  },
});
