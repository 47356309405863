import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CgNotes } from "react-icons/cg";
import { FaRegFolder } from "react-icons/fa";
import { MdFolderShared } from "react-icons/md";
import { GiBrain } from "react-icons/gi";
import { VscChecklist } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { Checklist, ItemType, MemoryItem, Note } from "../../classes";
import { useAppState } from "../../providers/AppStateProvider";
import { useMediaState } from "../../providers/MediaProvider";
import { EditIconButton } from "../IconButtons";
import { EditForm } from "./EditForm";

interface ItemProps {
  item: ItemType;
  index: number;
}

export const Item: React.FC<ItemProps> = ({ item, index }) => {
  const {
    setSideNavPath,
    sideNavPath,
    setAddFolderMode,
    editFolderMode,
    setEditFolderMode,
    setEditingMemoryItem,
    setActiveMemoryItem,
    setActiveChecklist,
    setActiveNote,
    // setGlobalEditMode,
    // globalEditMode,
    // setPreviewMode,
    // setParentFolderLength,
    toggleSideNav,
  } = useAppState();

  const { largerThanPhone } = useMediaState();

  const history = useHistory();

  // let memoryItemType = item.typeInfo === "memoryItem";
  // let checklistType = item.typeInfo === "checklist";
  // let noteType = item.typeInfo === "note";

  // let fileType = memoryItemType || checklistType || noteType;

  const handleEditMode = (item: ItemType) => {
    setAddFolderMode(false);

    switch (item.typeInfo) {
      case "folder": {
        setEditFolderMode(item.id!);
        break;
      }
      case "memoryItem": {
        setActiveMemoryItem(item as MemoryItem);
        setEditingMemoryItem(item as MemoryItem);
        break;
      }
      case "checklist": {
        setActiveChecklist(item as Checklist);
        break;
      }
      case "note": {
        setActiveNote(item as Note);
        break;
      }
      default:
        break;
    }

    if (item.typeInfo === "folder") {
    }
  };

  let iconType = FaRegFolder;
  if (item.typeInfo === "folder" && item.visibility !== "user") {
    iconType = MdFolderShared;
  }
  if (item.typeInfo === "checklist") {
    iconType = VscChecklist;
  } else if (item.typeInfo === "note") {
    iconType = CgNotes;
  } else if (item.typeInfo === "memoryItem") {
    iconType = GiBrain;
  }

  const handleClick = () => {
    switch (item.typeInfo) {
      case "folder": {
        setSideNavPath([...sideNavPath, item.id!]);
        // history.push("/");
        break;
      }
      case "memoryItem": {
        setActiveMemoryItem(item as MemoryItem);
        setEditingMemoryItem(item as MemoryItem);
        if (!largerThanPhone) toggleSideNav();
        history.push("/memoryItem");
        break;
      }
      case "checklist": {
        setActiveChecklist(item as Checklist);
        if (!largerThanPhone) toggleSideNav();
        history.push("/checklist");
        break;
      }
      case "note": {
        setActiveNote(item as Note);
        if (!largerThanPhone) toggleSideNav();
        history.push("/note");
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      {editFolderMode === item.id ? (
        <EditForm item={item} />
      ) : (
        <Draggable draggableId={item.id} index={index}>
          {(provided) => {
            return (
              <Flex
                {...provided.draggableProps}
                ref={provided.innerRef}
                alignItems="center"
                cursor="pointer"
                borderRadius={3}
              >
                <Box p="1px" {...provided.dragHandleProps}>
                  <Icon
                    as={iconType}
                    h={10}
                    w={10}
                    backgroundColor={
                      item.typeInfo === "checklist"
                        ? (item as Checklist).checklistDone
                          ? "green.500"
                          : "red.500"
                        : ""
                    }
                    px={2}
                    borderRadius={4}
                  />
                </Box>

                <Text
                  fontSize="sm"
                  flex={1}
                  onClick={handleClick}
                  p={2}
                  mx={2}
                  borderRadius={4}
                  isTruncated
                >
                  {item.title}
                </Text>

                {item.typeInfo === "folder" && (
                  <EditIconButton onClick={() => handleEditMode(item)} />
                )}
              </Flex>
            );
          }}
        </Draggable>
      )}
    </>
  );
};
