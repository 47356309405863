import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React from "react";
import { CRUDAPI } from "../../api/crud";
import { useAppState } from "../../providers/AppStateProvider";
import { useData } from "../../providers/DataProvider";
import { Check } from "../../classes";
import {
  CompleteIconButton,
  EditIconButton,
  ResetIconButton,
} from "../IconButtons";
import { FFODButton } from "../IconButtons/FFODButton";
import { CheckComponent } from "./CheckComponent";

export const ChecklistDisplay = () => {
  const {
    activeChecklist,
    setGlobalEditMode,
    sideNavPath,
    setActiveChecklist,
    setSavePath,
    FFOD,
  } = useAppState();
  const { setRootFolder, rootFolder } = useData();

  if (!activeChecklist || activeChecklist.typeInfo !== "checklist") {
    return <Text>No Checklist selected</Text>;
  }

  let name = activeChecklist.title;

  const handleReset = () => {
    //Check if checklist is completed and update state

    let { nextState, nextActiveChecklist } = CRUDAPI.checkCRUD.setAllChecks(
      false,
      rootFolder,
      sideNavPath,
      activeChecklist,
      FFOD
    );

    setRootFolder(cloneDeep(nextState));
    setActiveChecklist(nextActiveChecklist);
  };

  const handleCompleteAll = () => {
    let { nextState, nextActiveChecklist } = CRUDAPI.checkCRUD.setAllChecks(
      true,
      rootFolder,
      sideNavPath,
      activeChecklist,
      FFOD
    );

    setRootFolder(cloneDeep(nextState));
    setActiveChecklist(nextActiveChecklist);
  };

  const handleEdit = () => {
    setSavePath(sideNavPath);
    setGlobalEditMode(true);
  };

  return (
    <Flex direction="column" p={2} w={["100%", "100%", "90%"]}>
      <Flex alignItems="center">
        <Heading size="md" flex="1" mx={2}>
          {name}
        </Heading>

        <Box mr={2}>
          <EditIconButton onClick={handleEdit} />
        </Box>
        <Box mr={2}>
          <ResetIconButton tooltip="Reset Checklist" onClick={handleReset} />
        </Box>
        <Box mr={2}>
          <CompleteIconButton onClick={handleCompleteAll} />
        </Box>
        <Box>
          <FFODButton />
        </Box>
      </Flex>

      <Flex direction="column" mt={2}>
        <ChecksComponent
          checks={activeChecklist.checks}
          checkIds={activeChecklist.checkIds}
        />
      </Flex>
    </Flex>
  );
};

interface ChecksProps {
  checkIds: string[];
  checks: { [key: string]: Check };
}

const ChecksComponent: React.FC<ChecksProps> = ({ checks, checkIds }) => {
  const { FFOD } = useAppState();

  const checksArray = checkIds.map((checkId) => checks[checkId]);

  return checksArray.length > 0 ? (
    <Flex w="100%" direction="column">
      {checksArray
        .filter((check) => {
          if (!FFOD && check.FFOD) {
            return false;
          } else return true;
        })
        .map((check, i) => {
          return <CheckComponent key={check.id + i} check={check} />;
        })}
    </Flex>
  ) : (
    <Text>No Items yet</Text>
  );
};
